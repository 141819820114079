import React from "react";
import { CheckCircle, Sparkle } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";

const basicFeatures = [
  "Dedicated IP address for each account",
  "Fully integrated Inbox",
  "Leads database and management",
  "Export leads data",
  "In-depth analytics dashboard",
  "3rd party Integrations support",
  "Multi-user support & permission control",
];
const planFeatures = [
  "Bypass LinkedIn connection request limits",
  "AI-optimized outreach automation",
  "Built-in campaign builder",
  "Follow-up direct message sequences",
  "Various performance optimization options",
  "Auto-translate campaigns and DMs",
];
export default function PlansOverview() {
  return (
    <div className="flex flex-col p-4 lg:px-9">
      <h2 className="mb-4 text-center text-headline-xl-bold text-white lg:text-headline-2xl-bold">
        Automate your LinkedIn
      </h2>
      <p className="mb-8 text-center text-body-14-regular text-white">
        Save dozens of hours every week with LinkedIn Outreach. By signing up,
        you get:
      </p>
      <div className="flex gap-3 max-lg:flex-col">
        <div className="flex flex-col rounded-2xl border border-black-100 bg-white p-3.5 shadow-2xl lg:w-5/12">
          <Tag className="mb-3.5 self-start">Free</Tag>
          <h3 className="mb-1.5 text-body-14-bold">LinkedIn CRM</h3>
          <p className="mb-6 text-caption-10-regular text-black-600">
            Unlimited access to all LinkedIn CRM features including the Leads
            CRM, Inbox with Auto translate and Multi-User support. Communicate
            with your leads at scale.
          </p>
          <div className="flex flex-col gap-y-0.5">
            {basicFeatures.map((feature) => (
              <div
                key={feature}
                className="flex gap-x-1.5 text-caption-10-regular"
              >
                <CheckCircle className="size-4 text-black-300" weight="fill" />
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col bg-[url('/images/onboarding-plan-background.png')] bg-[length:100%_100%] p-3.5 bg-blend-color-dodge shadow-2xl lg:w-7/12">
          <Tag className="mb-3.5 self-start" variant="secondary">
            14 Days Free Trial
          </Tag>
          <h3 className="mb-1.5 text-body-14-bold text-white">
            LinkedIn Outreach
          </h3>
          <p className="mb-2 text-caption-10-regular text-black-200">
            Save dozens of hours every week with Aimfox LinkedIn Outreach. Grow
            your LinkedIn network with fully automated, personalized campaigns
            that convert. And a sprinkle of AI.
          </p>
          <div className="mb-1.5 flex items-center gap-x-2 self-start rounded-lg bg-purple-500 px-2 py-1.5 text-caption-10-regular text-white">
            <Sparkle className="size-2" weight="fill" />
            Everything from the previous plan
            <Sparkle className="size-2" weight="fill" />
          </div>
          <div className="flex flex-col gap-y-2">
            {planFeatures.map((feature) => (
              <div
                key={feature}
                className="flex gap-x-1.5 text-caption-10-regular text-white"
              >
                <CheckCircle className="size-4 text-white" weight="fill" />
                {feature}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
