import React, { useRef, forwardRef } from "react";
import clsx from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ConditionalWrapper from "common/components/ConditionalWrapper";

import Variables from "./Variables";
import TextWithLabels from "./TextWithLabels";
import { getHtmlString, getSanitizedValue } from "./helpers";

interface VariablesEditorProps {
  value: string;
  onChange: (message: string) => void;
  maxLength: number;
  withScrollWrapper?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}

const VariableEditor = forwardRef<HTMLDivElement, VariablesEditorProps>(
  (
    {
      maxLength,
      value,
      onChange,
      onBlur = null,
      isDisabled = false,
      withScrollWrapper = false,
    },
    forwardedRef,
  ) => {
    const textWithLabelsRef = useRef<HTMLDivElement>();
    const isDesktop = useTwBreakpoint("lg");

    function getCurrentSelection() {
      const selection = window.getSelection();
      const selectionRange = selection.getRangeAt(0);
      const selectedNote = selection.anchorNode;

      const isValidSelection =
        selection.rangeCount &&
        textWithLabelsRef.current.contains(selectedNote);

      return {
        isValidSelection,
        selectionRange,
      };
    }
    function insertText(text: string) {
      const { isValidSelection, selectionRange } = getCurrentSelection();

      if (!isValidSelection) return;
      getSelection().deleteFromDocument();
      selectionRange.insertNode(document.createTextNode(text));
      getSelection().collapseToEnd();
    }
    function addLabel(variableValue: string) {
      const htmlString = getHtmlString(variableValue.slice(2, -2));
      const { isValidSelection, selectionRange } = getCurrentSelection();

      if (isValidSelection) {
        const fragment = selectionRange.createContextualFragment(htmlString);
        selectionRange.insertNode(fragment);
      } else {
        textWithLabelsRef.current.insertAdjacentHTML("beforeend", htmlString);
      }

      const sanitizedValue = getSanitizedValue(
        textWithLabelsRef.current.textContent,
      );
      onChange(sanitizedValue);
      getSelection().collapseToEnd();
    }

    const characterCountComponent = (
      <span
        className={clsx(
          "ml-auto text-nowrap text-caption-12-regular",
          value.length > maxLength ? "text-red-500" : "text-black-400",
        )}
      >
        {value.length || 0}/{maxLength}
      </span>
    );

    return (
      <div className="flex size-full flex-1 flex-col gap-4">
        <ConditionalWrapper
          condition={withScrollWrapper}
          renderWrapper={(children) => (
            <div className="flex max-h-40 flex-1 flex-col gap-4 overflow-y-auto scrollbar-thin lg:max-h-52">
              {children}
            </div>
          )}
        >
          <TextWithLabels
            ref={(_ref) => {
              textWithLabelsRef.current = _ref;
              if (forwardedRef) {
                if (typeof forwardedRef === "function") {
                  forwardedRef(_ref);
                } else {
                  forwardedRef.current = _ref;
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                document.execCommand("insertLineBreak");
              }
            }}
            text={value}
            onInput={(e) => {
              const sanitizedValue = getSanitizedValue(
                e.currentTarget.textContent,
              );
              onChange(sanitizedValue);
            }}
            onPaste={(e) => {
              e.preventDefault();
              // onPaste formatting - https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event#examples
              const text = e.clipboardData.getData("text");
              insertText(getSanitizedValue(text));
              const sanitizedValue = getSanitizedValue(
                e.currentTarget.textContent,
              );
              onChange(sanitizedValue);
            }}
            onBlur={onBlur}
            isEditable
            isDisabled={isDisabled}
          />

          {!isDesktop && characterCountComponent}
        </ConditionalWrapper>

        <div className="flex w-full items-end gap-4">
          <Variables addLabel={addLabel} isDisabled={isDisabled} />
          {isDesktop && characterCountComponent}
        </div>
      </div>
    );
  },
);

VariableEditor.displayName = "VariableEditor";

VariableEditor.defaultProps = {
  onBlur: null,
  isDisabled: false,
  withScrollWrapper: false,
};

export default VariableEditor;
