import React, { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import { Slot, SlotProps, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { CircleNotch } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

const defaultButtonClasses = [
  "transition-colors duration-300 ease-in-out cursor-pointer",
  "focus-visible:outline-none",
  "disabled:cursor-not-allowed disabled:bg-black-200 disabled:text-black-400",
];

// TODO: Implement negative margins for SVGs depending on size
const buttonVariants = cva(
  "inline-flex shrink-0 items-center justify-center rounded-full",
  {
    variants: {
      variant: {
        // Primary Button
        "primary-black": "bg-black-950 text-white hover:bg-black-700",
        "primary-purple": "bg-purple-500 text-white hover:bg-purple-400",
        "primary-orange": "bg-orange-600 text-white hover:bg-orange-400",

        // Secondary Button
        "secondary-black": "bg-black-100 text-black-950 hover:bg-black-200",
        "secondary-purple": "bg-purple-50 text-purple-600 hover:bg-purple-100",
        "secondary-orange": "bg-orange-100 text-orange-600 hover:bg-orange-200",

        // Tertiary Button
        "tertiary-black":
          "border-black-200 bg-white text-black-950 hover:bg-black-50 enabled:border",
        "tertiary-purple":
          "border-purple-200 bg-white text-purple-600 hover:bg-purple-50 enabled:border",
        "tertiary-orange":
          "border-orange-200 bg-white text-orange-600 hover:bg-orange-50 enabled:border",
        "tertiary-danger":
          "border-red-200 bg-white text-red-500 hover:bg-red-50 enabled:border",

        // Quaternary Button
        "quaternary-black": "text-black-950 hover:bg-black-100",
        "quaternary-purple": "text-purple-600 hover:bg-purple-100",
        "quaternary-orange": "text-orange-600 hover:bg-orange-100",
        "quaternary-danger": "text-red-500 hover:bg-red-200",

        // Gradient Button (AI)
        gradient:
          "text-white transition-opacity enabled:bg-blackPurple enabled:hover:opacity-80",
      },
      size: {
        // XS Variant is used for Icon Buttons only
        xs: "size-6 [&_svg]:size-4",
        sm: "h-8 min-w-8 gap-1 px-4 py-2 text-button-12 [&_svg]:size-4",
        md: "h-10 min-w-10 gap-2 px-6 py-2.5 text-button-14 [&_svg]:size-5",
        lg: "h-12 min-w-12 gap-2 px-6 py-3 text-button-16 [&_svg]:size-5",
      },
      intent: {
        default: defaultButtonClasses,
        iconOnly: [...defaultButtonClasses, "p-0"],
        labelIcon: "pointer-events-none p-0",
      },
    },
    defaultVariants: {
      variant: "primary-black",
      size: "md",
      intent: "default",
    },
  },
);

type ButtonVariantType = VariantProps<typeof buttonVariants>["variant"];

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      intent,
      children,
      isLoading,
      leftIcon,
      rightIcon,
      asChild,
      ...props
    },
    ref,
  ) => {
    const loadingComponent = <CircleNotch className="animate-spin" />;

    let Comp:
      | React.ForwardRefExoticComponent<
          SlotProps & React.RefAttributes<HTMLElement>
        >
      | "button"
      | "span" = "button";

    if (intent === "labelIcon") {
      Comp = "span";
    } else if (asChild) {
      Comp = Slot;
    }

    return (
      <Comp
        type="button"
        className={cn(
          buttonVariants({
            variant,
            size,
            intent,
            className,
          }),
        )}
        ref={ref}
        disabled={isLoading}
        {...props}
      >
        {isLoading && leftIcon ? loadingComponent : leftIcon}

        {intent === "iconOnly" && isLoading ? (
          loadingComponent
        ) : (
          <Slottable>{children}</Slottable>
        )}

        {isLoading &&
        (rightIcon || (!rightIcon && !leftIcon && intent !== "iconOnly"))
          ? loadingComponent
          : rightIcon}
      </Comp>
    );
  },
);

Button.displayName = "Button";

Button.defaultProps = {
  asChild: false,
  isLoading: false,
  rightIcon: null,
  leftIcon: null,
};

export { Button, buttonVariants, type ButtonVariantType };
