import React from "react";
import { Minus, Plus } from "@phosphor-icons/react";

import { ScheduleInterval } from "common/types";
import { Button } from "common/components/ui/Button";

import RangeSelect from "./RangeSelect";

interface Props {
  ranges: ScheduleInterval[];
  onChange: (index: number, startOrEnd: "start" | "end", value: number) => void;
  addRange: () => void;
  removeRange: (index: number) => void;
  isDisabled: boolean;
  isSecondRangeDisabled: boolean;
  startOptions: { value: number; label: string }[];
  endOptions: { value: number; label: string }[];
}

export default function DesktopLayout({
  ranges,
  onChange,
  addRange,
  removeRange,
  isDisabled,
  startOptions,
  endOptions,
  isSecondRangeDisabled,
}: Props) {
  return (
    <div className="flex grow flex-col items-center justify-center gap-3 xl:flex-row xl:gap-4">
      {ranges.map(({ start, end }, index) => {
        const filteredStartOptions = startOptions.filter(({ value }) =>
          index === 0 ? true : value > ranges[0].end,
        );
        const filteredEndOptions = endOptions.filter(
          ({ value }) => start !== null && value > start,
        );

        return (
          <div key={end} className="flex items-center gap-2">
            <span className="text-button-14 text-black-400">From</span>

            <RangeSelect
              options={filteredStartOptions}
              onSelect={(value) => onChange(index, "start", value)}
              isDisabled={isDisabled}
              selectedRangeLabel={
                startOptions.find(({ value }) => value === start).label
              }
            />

            <span className="text-button-14 text-black-400">to</span>

            <RangeSelect
              options={filteredEndOptions}
              onSelect={(value) => onChange(index, "end", value)}
              isDisabled={isDisabled}
              selectedRangeLabel={
                endOptions.find(({ value }) => value === end).label
              }
            />

            {ranges.length === 2 ? (
              <Button
                size="sm"
                intent="iconOnly"
                onClick={() => removeRange(index)}
                disabled={isDisabled}
              >
                <Minus weight="bold" />
              </Button>
            ) : (
              <Button
                size="sm"
                intent="iconOnly"
                onClick={addRange}
                disabled={isSecondRangeDisabled || isDisabled}
              >
                <Plus weight="bold" />
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
}
