import { useNavigate } from "react-router-dom";

import useSubscriptions from "../datahooks/useSubscriptions";
import routes from "../../pages/routes";

export default function useSubscriptionSelection() {
  const navigate = useNavigate();
  const { subscriptions } = useSubscriptions();

  function selectSubscription(newSubscriptionId: string) {
    navigate(`/${newSubscriptionId}/${routes[0].path}`, {
      replace: true,
    });
  }

  return {
    subscriptions,
    selectSubscription,
  };
}
