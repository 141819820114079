import { CaretRight } from "@phosphor-icons/react";
import React from "react";

import { Lead } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";

export default function LeadsList({
  leads,
  isLoading,
  openPreview,
}: {
  leads: Lead[];
  isLoading: boolean;
  openPreview: (lead: Lead) => void;
}) {
  return (
    <div className="mb-4 flex flex-col gap-y-2.5">
      <div className="mb-1.5 rounded-xl bg-black-100 px-4 py-2 text-caption-12-regular text-black-700">
        Accounts
      </div>
      {(isLoading ? new Array<undefined>(8).fill(undefined) : leads).map(
        (lead, index) => (
          <button
            key={lead ? lead.urn : index}
            type="button"
            className="flex items-center gap-x-2 rounded-20 border border-black-200 p-4 pr-2"
            onClick={() => openPreview(lead)}
          >
            {lead ? (
              <ProfileImage src={lead.picture_url} />
            ) : (
              <Skeleton className="size-10" />
            )}
            <div className="flex flex-col items-start gap-y-0.5 overflow-x-hidden">
              {lead ? (
                <span className="text-body-16-bold">{lead.full_name}</span>
              ) : (
                <Skeleton className="h-5 w-20" />
              )}
              {lead ? (
                <span className="w-full truncate text-left text-body-14-regular text-black-500">
                  {lead.occupation}
                </span>
              ) : (
                <Skeleton className="h-4 w-40" />
              )}
            </div>
            {lead ? (
              <Button
                intent="labelIcon"
                variant="quaternary-black"
                className="ml-auto"
                disabled={isLoading}
              >
                <CaretRight />
              </Button>
            ) : (
              <Skeleton className="ml-auto size-10 rounded-full" />
            )}
          </button>
        ),
      )}
    </div>
  );
}
