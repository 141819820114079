import React, { useState } from "react";
import { LinkedinLogo, Warning, CreditCard } from "@phosphor-icons/react";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import useSubscriptionSettings from "common/datahooks/useSubscriptionSettings";
import { isRocketHubUser } from "common/helpers/utils";
import AlertBanner from "common/components/Layout/AlertBanner";
import UpgradeDialog from "common/components/UpgradeDialog";
import UpdatePaymentMethod from "common/components/UpdatePaymentMethod";

import useAppStore from "../../hooks/useAppStore";

function FreemiumBanner() {
  const selectedSubscription = useSelectedSubscriptionContext();
  const [isVisible, setIsVisible] = useState(
    !document.cookie.includes("isFreemiumHidden"),
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { primaryPaymentSource, isLoading } = usePrimaryPaymentSource();

  function onClose() {
    const cookieAge = 60 * 60 * 24;
    document.cookie = `isFreemiumHidden=true; max-age=${cookieAge}; path=/;`;
    setIsVisible(false);
  }
  if (isVisible && !primaryPaymentSource && !isLoading) {
    const dateToday = new Date();
    const nextBillingDate = new Date(selectedSubscription.next_billing * 1000);

    const isToday = dateToday.toDateString() === nextBillingDate.toDateString();
    const isTomorrow =
      new Date(dateToday.valueOf() + 24 * 60 * 60 * 1000).toDateString() ===
      nextBillingDate.toDateString();

    let endingText;
    if (isToday) {
      endingText = "today";
    } else if (isTomorrow) {
      endingText = "tomorrow";
    } else {
      dateToday.setHours(0, 0, 0);
      nextBillingDate.setHours(0, 0, 0);

      const daysRemaining = Math.ceil(
        (nextBillingDate.valueOf() - dateToday.valueOf()) /
          (24 * 60 * 60 * 1000),
      );
      endingText = `in ${daysRemaining} days`;
    }

    return (
      <>
        <AlertBanner
          icon={<CreditCard className="fill-white" />}
          title="Add a payment method"
          subtitle={`Your trial ends ${endingText}. Add a payment method to avoid campaign downtime when your trial expires`}
          buttonText="Add billing"
          onButtonClick={() => setIsDialogOpen(true)}
          onClose={onClose}
          variant="trial"
        />
        <UpdatePaymentMethod
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
        />
      </>
    );
  }
}
function SubscriptionExpiredBanner() {
  const [isVisible, setIsVisible] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isVisible) {
    return (
      <>
        <AlertBanner
          title="Upgrade to get LinkedIn Outreach"
          subtitle="Start gaining new leads with LinkedIn Outreach. Unlock unlimited campaigns, and full Aimfox functionality"
          buttonText="Upgrade now"
          variant="trial"
          icon={<Warning className="fill-white" />}
          onClose={() => setIsVisible(false)}
          onButtonClick={() => setIsDialogOpen(true)}
        />
        <UpgradeDialog isOpen={isDialogOpen} onOpenChange={setIsDialogOpen} />
      </>
    );
  }
}
export default function Banners() {
  const selectedSubscription = useSelectedSubscriptionContext();
  const { subscriptionSettings } = useSubscriptionSettings();

  if (selectedSubscription.account.state === "NeverLoggedIn") {
    return (
      <AlertBanner
        title="Connect your LinkedIn account"
        subtitle="Securely log in your LinkedIn profile to start using Aimfox"
        buttonText="Connect LinkedIn"
        variant="warning"
        icon={<LinkedinLogo weight="fill" />}
        onButtonClick={() => useAppStore.getState().setIsShowingLogin(true)}
      />
    );
  }
  if (selectedSubscription.account.state === "LoggedOut") {
    return (
      <AlertBanner
        icon={<Warning className="fill-white" />}
        title="LinkedIn account disconnected"
        subtitle="Your LinkedIn profile is disconnected from Aimfox. Please reconnect your account to continue using Aimfox"
        buttonText="Connect LinkedIn"
        variant="danger"
        onButtonClick={() => useAppStore.getState().setIsShowingLogin(true)}
      />
    );
  }
  if (selectedSubscription.status === "cancelled") {
    return <SubscriptionExpiredBanner />;
  }
  if (
    selectedSubscription.status === "in_trial" &&
    !isRocketHubUser(subscriptionSettings)
  ) {
    return <FreemiumBanner />;
  }
}
