import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { TargetingStats } from "../types";

async function getTargetingStats(
  accountId: number,
  campaignId: string,
): Promise<TargetingStats> {
  return (
    await get(`accounts/${accountId}/campaigns/${campaignId}/targeting/stats`)
  ).stats;
}

export default function useTargetingStats({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const campaignId = useCampaignIdContext();
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);

  const { data: targetingStats, isLoading: isLoadingTargetingStats } = useQuery(
    {
      queryKey: campaignsKeys.targetingStats(campaignId),
      queryFn: () => getTargetingStats(account.id, campaignId),
      enabled: !disableQuery,
    },
  );

  return {
    targetingStats,
    isLoadingTargetingStats,
  };
}
