import React from "react";
import { VirtuosoGrid } from "react-virtuoso";
import clsx from "clsx";

import { getProfileSubtitle } from "common/helpers/utils";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";

import { Target } from "../../../types";
import { targetStates } from "../../../constants";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/profiles-not-found.png";

interface ProfileProps {
  profile: Target;
  isLoading: boolean;
}

function Profile({ profile, isLoading }: ProfileProps) {
  const commonClasses =
    "flex items-center gap-3.5 rounded-20 border border-black-200 p-4";

  if (isLoading)
    return (
      <div className={commonClasses}>
        <Skeleton className="size-10" />
        <div className="flex flex-col gap-0.5">
          <Skeleton className="h-[22px] w-32" />
          <Skeleton className="h-4 w-40 md:w-44" />
        </div>
        <Skeleton className="ml-auto h-6 w-20" />
      </div>
    );

  const { state, full_name: fullName, picture_url: pictureUrl } = profile;

  let tagComponent;
  if (state) {
    const { tagVariant, label } = targetStates.find(({ states }) =>
      states.includes(state),
    );

    tagComponent = (
      <Tag variant={tagVariant} className="ml-auto">
        {label}
      </Tag>
    );
  }
  return (
    <a
      href={`https://linkedin.com/in/${profile.public_identifier}`}
      target="_blank"
      aria-label="go to linkedin page"
      rel="noreferrer"
      className={clsx(
        commonClasses,
        "cursor-pointer bg-white transition-colors hover:bg-black-100",
      )}
    >
      <ProfileImage src={pictureUrl} />

      <div className="flex flex-col gap-0.5">
        <h5 className="line-clamp-1 text-button-16">{fullName}</h5>
        <p className="line-clamp-1 break-all text-caption-12-regular text-black-500">
          {getProfileSubtitle(profile)}
        </p>
      </div>

      {tagComponent}
    </a>
  );
}

interface AudienceListProps {
  targets: Target[];
  searchText: string;
  resetFilters: () => void;
  isLoading: boolean;
}

export default function AudienceList({
  targets,
  searchText,
  resetFilters,
  isLoading,
}: AudienceListProps) {
  if (!targets.length) {
    return (
      <EmptyPlaceholder
        imageSrc={EmptyPlaceholderImage}
        title="No Targets Found"
        subtitle={
          searchText
            ? `No targets found for “${searchText}”`
            : "No results found for the selected filters"
        }
        actionComponent={<Button onClick={resetFilters}>Reset filters</Button>}
      />
    );
  }

  return (
    <VirtuosoGrid
      useWindowScroll
      totalCount={targets.length}
      listClassName="grid md:grid-cols-2 xl:grid-cols-3 gap-3"
      /* eslint-disable-next-line react/no-unstable-nested-components */
      itemContent={(index) => {
        const profile = targets[index];

        return <Profile profile={profile} isLoading={isLoading} />;
      }}
    />
  );
}
