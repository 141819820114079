import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import useConversationMessages from "../../datahooks/useConversationMessages";
import Message from "./Message/Message";
import { Conversation, ConversationMessage } from "../../types";
import { formatConversationMessagesDate } from "../../utils";

export default function MessageThread({
  conversation,
  setEditMessage,
}: {
  conversation: Conversation;
  setEditMessage: (message: ConversationMessage) => void;
}) {
  const { messages, loadNextMessages, isLoadingMessages, hasMoreMessages } =
    useConversationMessages({
      conversationUrn: conversation.conversation_urn,
      unreadCount: conversation.unread_count,
      lastMessageCreatedAt: conversation.last_message?.created_at,
    });

  let messagesComponents = [];

  if (isLoadingMessages) {
    messagesComponents = new Array(6).fill({}).map((message, index) => (
      <Message
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        message={message}
        setEditMessage={setEditMessage}
        participants={conversation.participants}
        isLoading
      />
    ));
  } else if (messages) {
    for (let i = 0; i < messages.length; i += 1) {
      const message = messages[i];
      const date = new Date(message.created_at);

      messagesComponents.push(
        <Message
          key={message.urn}
          message={message}
          setEditMessage={setEditMessage}
          participants={conversation.participants}
          isLoading={false}
        />,
      );
      const nextDate = new Date(messages[i + 1]?.created_at);

      if (
        !nextDate ||
        date.getDate() !== nextDate.getDate() ||
        date.getMonth() !== nextDate.getMonth()
      ) {
        const formattedDate = formatConversationMessagesDate(date);

        messagesComponents.push(
          <div
            className="my-4 flex flex-row items-center justify-center gap-4"
            key={formattedDate}
          >
            <div className="h-px w-full bg-black-200" />

            <span className="text-nowrap rounded-full bg-purple-50 px-4 py-1 text-caption-12-bold text-purple-500 shadow-sm">
              {formattedDate}
            </span>

            <div className="h-px w-full bg-black-200" />
          </div>,
        );
      }
    }
  }

  return (
    <div
      id="thread-scroll-container"
      className="relative flex flex-1 flex-col-reverse overflow-y-auto scrollbar-thin lg:mt-28 lg:px-4 lg:py-2"
    >
      <InfiniteScroll
        className="flex flex-col-reverse overflow-hidden"
        inverse
        next={loadNextMessages}
        hasMore={hasMoreMessages}
        dataLength={messagesComponents.length}
        loader={
          <>
            {new Array(6).fill({}).map((message, index) => (
              <Message
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                message={message}
                setEditMessage={setEditMessage}
                participants={conversation.participants}
                isLoading
              />
            ))}
          </>
        }
        scrollableTarget="thread-scroll-container"
      >
        {messagesComponents}
      </InfiniteScroll>
    </div>
  );
}
