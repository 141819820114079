import React, { memo, useEffect, useState } from "react";

import InboxHeader from "./InboxHeader/InboxHeader";
import MessageContainer from "./MessageField/MessageContainer";
import { ConversationUrnProvider } from "./ConversationUrnContext";
import MessageThread from "./MessageThread";
import { Conversation } from "../../types";
import useConversationUpdates from "../../datahooks/useConversationUpdates";
import MessageFieldLoader from "./MessageField/MessageFieldLoader";
import NotificationArea from "./NotificationArea";

interface ConversationContainerProps {
  activeConversation: Conversation;
  isLoadingConversations: boolean;
  resetActiveConversation?: () => void;
}

function ConversationContainer({
  activeConversation,
  isLoadingConversations,
  resetActiveConversation = null,
}: ConversationContainerProps) {
  const { conversation_urn: conversationUrn, unread_count: unreadCount } =
    activeConversation;

  const [editMessage, setEditMessage] = useState(null);
  const { resetUnreadMessages } = useConversationUpdates();

  useEffect(() => {
    if (activeConversation && unreadCount) {
      resetUnreadMessages({ conversationUrn });
    }
    setEditMessage(null);
  }, [activeConversation]);

  return (
    <ConversationUrnProvider value={conversationUrn}>
      <div className="flex h-[calc(100dvh-5rem)] flex-col justify-between p-2 lg:h-full lg:flex-1 lg:p-0">
        <InboxHeader
          conversation={activeConversation}
          isLoading={isLoadingConversations}
          resetActiveConversation={resetActiveConversation}
        />
        <MessageThread
          conversation={activeConversation}
          setEditMessage={setEditMessage}
        />
        {isLoadingConversations ? (
          <MessageFieldLoader />
        ) : (
          <>
            <NotificationArea conversation={activeConversation} />
            <MessageContainer
              conversation={activeConversation}
              editMessage={editMessage}
              cancelEdit={() => setEditMessage(null)}
            />
          </>
        )}
      </div>
    </ConversationUrnProvider>
  );
}

export default memo(ConversationContainer);
