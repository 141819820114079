import React from "react";

import useNotifications from "common/datahooks/useNotifications";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import NotificationItem from "./NotificationItem";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/notifications.png";

export default function Notifications() {
  const { notifications } = useNotifications();

  return notifications.length ? (
    <div className="flex flex-col gap-2">
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
    </div>
  ) : (
    <EmptyPlaceholder
      title="No Notifications Here"
      subtitle="You do not have any Notifications yet"
      imageSrc={EmptyPlaceholderImage}
    />
  );
}
