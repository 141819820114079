import React from "react";
import { CaretDown, Translate } from "@phosphor-icons/react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import languages from "common/constants/languages";
import { Button } from "common/components/ui/Button";

import { getLanguageName } from "../../../../utils";

interface LanguageDropdownProps {
  languageCode: string;
  onChange: (languageCode: string) => void;
  isManualLanguage: boolean;
  isDisabled: boolean;
}

export default function LanguageDropdown({
  languageCode,
  onChange,
  isManualLanguage,
  isDisabled,
}: LanguageDropdownProps) {
  const label = languageCode ? getLanguageName(languageCode) : "Language";

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className="group mr-auto"
          variant="tertiary-black"
          disabled={isDisabled}
          size="sm"
          leftIcon={<Translate weight="fill" />}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          {languageCode && !isManualLanguage && (
            <span className="text-black-400">Detected:</span>
          )}
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="h-56 overflow-y-auto py-2 scrollbar-thin"
      >
        <div className="flex flex-col">
          {languages.map(({ code, name }) => (
            <button
              key={code}
              className="px-3 py-2 text-left transition-colors hover:bg-black-50"
              type="button"
              onClick={() => onChange(code)}
            >
              {name}
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
