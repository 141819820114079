import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Skeleton from "common/components/ui/Skeleton";

import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";
import useSubscriptionMembers from "../../datahooks/useSubscriptionMembers";
import InviteMember from "./InviteMemberDialog";

export default function Members() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { members, isLoadingMembers } = useSubscriptionMembers();

  const Component = isTabletOrDesktop ? DesktopTable : MobileList;
  return (
    <>
      <div className="my-2 flex w-full flex-row items-center justify-between py-4">
        <div className="flex flex-col">
          <span className="text-body-16-bold">Members list</span>

          {isLoadingMembers ? (
            <Skeleton className="h-[18px] w-24 rounded-lg" />
          ) : (
            <span className="text-black-500">
              {members.length} member{members.length > 1 && "s"}
            </span>
          )}
        </div>

        <InviteMember />
      </div>

      <Component members={members ?? []} isLoading={isLoadingMembers} />
    </>
  );
}
