import React, { useMemo, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Member } from "common/types";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import Avatar from "common/components/ui/Avatar";
import { userRoles } from "common/constants";

import MobileDrawerContent from "./MobileDrawerContent";

export default function MobileList({
  members,
  isLoading,
}: {
  members: Member[];
  isLoading: boolean;
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>(null);

  const handleDrawerState = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const handleRowClick = (member: Member) => {
    setSelectedMember(member);
    setIsDrawerOpen(true);
  };

  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : members),
    [members, isLoading],
  );

  return (
    <>
      <div className="rounded-xl bg-black-100 px-4 py-2">
        <span className="text-caption-12-regular">Account</span>
      </div>

      <div className="mt-2 flex flex-col gap-2">
        {tableData.map((member: Member, index) => {
          const {
            token,
            first_name: firstName,
            last_name: lastName,
            role,
            customer_id: customerId,
            email,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, variant: roleVariant } = roleData || {};

          const getFirstName = () =>
            customerId ? `${firstName} ${lastName}` : "New Member";

          return (
            <button
              key={token || index}
              type="button"
              className="flex items-center justify-between gap-4 rounded-2xl border border-black-200 px-4 py-5"
              onClick={() => handleRowClick(member)}
            >
              <div className="flex flex-row gap-2">
                {isLoading ? (
                  <Skeleton className="size-12 rounded-full" />
                ) : (
                  <Avatar
                    size="lg"
                    variant={customerId ? "primary" : "quaternary"}
                  >
                    {customerId ? `${firstName[0]}${lastName[0]}` : "N/A"}
                  </Avatar>
                )}

                <div className="flex flex-col justify-center gap-0.5">
                  <span className="flex flex-row items-center gap-2 text-body-14-bold">
                    {isLoading ? (
                      <Skeleton className="h-5 w-52" />
                    ) : (
                      <>
                        <span
                          className={clsx(
                            "text-body-14-bold",
                            !customerId && "text-black-300",
                          )}
                        >
                          {getFirstName()}
                        </span>

                        <Tag variant={roleVariant} className="capitalize">
                          {roleLabel}
                        </Tag>
                      </>
                    )}
                  </span>

                  {isLoading ? (
                    <Skeleton className="h-4 w-24" />
                  ) : (
                    <span className="line-clamp-1 max-w-[28ch] text-start text-caption-12-regular text-black-500">
                      {email}
                    </span>
                  )}
                </div>
              </div>

              <Button intent="iconOnly" asChild variant="quaternary-black">
                <span>
                  <CaretRight />
                </span>
              </Button>
            </button>
          );
        })}
      </div>

      <Drawer
        open={isDrawerOpen}
        onOpenChange={(isOpen) => {
          handleDrawerState(isOpen);
        }}
      >
        <DrawerContent>
          <MobileDrawerContent member={selectedMember} />
        </DrawerContent>
      </Drawer>
    </>
  );
}
