/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import {
  CaretRight,
  CaretUpDown,
  Check,
  EnvelopeOpen,
  Hourglass,
  User,
  type Icon,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { Button } from "common/components/ui/Button";
import { Campaign } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import DataTable from "common/components/DataTable";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";

import CampaignPreview from "./CampaignPreview";
import { CampaignActionsDropdown } from "../CampaignActions";
import { campaignStates, campaignSubtypeOptions } from "../../constants";

function MetricItem({
  icon: Icon,
  value,
  isLoading,
}: {
  icon: Icon;
  value: string | number;
  isLoading: boolean;
}) {
  return (
    <div className="flex items-center justify-center gap-1">
      {isLoading ? (
        <Skeleton className="h-5 w-12" />
      ) : (
        <>
          <Icon size={20} className="fill-black-400" />
          <span>{value}</span>
        </>
      )}
    </div>
  );
}

interface CampaignsTableProps {
  campaigns: Campaign[];
  isLoading: boolean;
}

export default function CampaignsTable({
  campaigns,
  isLoading,
}: CampaignsTableProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const navigate = useNavigate();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(null);

  function onRowClick(campaign: Campaign) {
    if (isTabletOrDesktop) {
      navigate(campaign.id);
    } else {
      setSelectedCampaign(campaign);
      setIsPreviewOpen(true);
    }
  }

  const columns: ColumnDef<Campaign>[] = useMemo(() => {
    const campaignColumns: ColumnDef<Campaign>[] = [
      {
        accessorKey: "name",
        header: ({ column }) => (
          <button
            type="button"
            className="flex items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Name <CaretUpDown size={14} />
          </button>
        ),
        cell: ({
          row: {
            original: { type, subtype, name },
          },
        }) => {
          const {
            icon: SubtypeIcon,
            tagVariant,
            label,
          } = campaignSubtypeOptions[subtype] ?? {};

          return (
            <div className="flex items-center gap-2">
              {isLoading ? (
                <Skeleton className="size-10" />
              ) : (
                <CampaignTypeIcon type={type} />
              )}
              <div>
                <div className="flex items-center gap-1">
                  {isLoading ? (
                    <>
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="h-6 w-20" />
                    </>
                  ) : (
                    <>
                      <span className="line-clamp-1 text-button-12 capitalize text-black-500">
                        {type} campaign
                      </span>
                      <Tag
                        variant={tagVariant}
                        leftIcon={isTabletOrDesktop && <SubtypeIcon />}
                        className="max-md:p-0"
                      >
                        {isTabletOrDesktop ? label : <SubtypeIcon />}
                      </Tag>
                    </>
                  )}
                </div>
                {isLoading ? (
                  <Skeleton className="mt-1 h-5 w-40" />
                ) : (
                  <h5 className="line-clamp-1 break-all text-body-16-bold">
                    {name}
                  </h5>
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "state",
        header: ({ column }) => (
          <button
            type="button"
            className="mx-auto flex items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            State <CaretUpDown size={14} />
          </button>
        ),
        cell: ({
          row: {
            original: { state },
          },
        }) => {
          if (isLoading) return <Skeleton className="mx-auto h-6 w-20" />;

          const { label, tagVariant } = campaignStates[state];

          return <Tag variant={tagVariant}>{label}</Tag>;
        },
      },
    ];

    if (isTabletOrDesktop) {
      campaignColumns.push(
        {
          accessorKey: "target_count",
          header: "Targets",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }

            return (
              <MetricItem
                icon={User}
                value={original.target_count}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          accessorKey: "completion",
          header: "Progress",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }

            return (
              <MetricItem
                icon={Hourglass}
                value={`${Math.floor(original.completion * 100)}%`}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          id: "invites",
          header: "Invites",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }

            const { metrics } = original;

            const totalInvites = metrics
              ? metrics.sent_connections + metrics.message_requests
              : 0;

            return (
              <MetricItem
                icon={EnvelopeOpen}
                value={totalInvites}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          id: "accepted",
          header: "Accepted",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }

            const { metrics } = original;

            const acceptancePercentage =
              metrics && metrics.sent_connections
                ? Math.round(
                    (metrics.accepted_connections / metrics.sent_connections) *
                      100,
                  )
                : 0;

            const acceptanceRate = `${metrics?.accepted_connections} (${acceptancePercentage}%)`;

            return (
              <MetricItem
                icon={Check}
                value={acceptanceRate}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          id: "actions",
          cell: ({ row: { original } }) => {
            if (isLoading)
              return <Skeleton className="inline-flex size-10 rounded-full" />;

            return <CampaignActionsDropdown campaign={original} />;
          },
        },
      );
    } else {
      campaignColumns.push({
        id: "actions",
        cell: () => (
          <Button intent="labelIcon" size="sm" variant="quaternary-black">
            <CaretRight />
          </Button>
        ),
      });
    }

    return campaignColumns;
  }, [isLoading, isTabletOrDesktop]);

  return (
    <>
      <DataTable
        columns={columns}
        data={campaigns}
        onRowClick={!isLoading && onRowClick}
      />

      <RenderIf condition={!isTabletOrDesktop}>
        <CampaignPreview
          campaign={selectedCampaign}
          isOpen={isPreviewOpen}
          setIsOpen={setIsPreviewOpen}
        />
      </RenderIf>
    </>
  );
}
