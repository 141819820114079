import React from "react";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { TemplateType } from "common/types";
import RenderIf from "common/components/RenderIf";

import useFlowActions from "../../hooks/useFlowActions";
import FlowTemplate from "./FlowTemplate";
import AutoTranslate from "./AutoTranslate";
import MessageTemplates from "./MessageTemplates";
import Placeholder from "./Placeholder";
import FlowSectionHeading from "./FlowSectionHeading";
import { CampaignFlow as CampaignFlowType } from "../../types";
import { CampaignFlowProvider } from "../../context/CampaignFlowContext";

type CampaignFlowProps = {
  flow: CampaignFlowType;
  isFlowError?: boolean;
  canEditFlow?: boolean;
  resetFlowError?: () => void;
};

export default function CampaignFlow({
  flow,
  isFlowError = false,
  canEditFlow = true,
  resetFlowError = null,
}: CampaignFlowProps) {
  const { account } = useSelectedSubscriptionContext();
  const { addEmptyTemplate, setTranslate, updateFlowTemplate } =
    useFlowActions(flow);
  const templateType: TemplateType =
    flow.type === "PRIMARY_CONNECT" ? "NOTE_TEMPLATE" : "INMAIL_TEMPLATE";

  const isPrimaryConnectAndNotPremium =
    flow.type === "PRIMARY_CONNECT" && !account.premium;

  return (
    <CampaignFlowProvider
      value={{ flow, canEditFlow, isFlowError, resetFlowError }}
    >
      <div className="flex w-full flex-col gap-4 lg:gap-6">
        <AutoTranslate
          translate={flow.translate}
          setTranslate={setTranslate}
          isDisabled={!canEditFlow}
        />

        {/* Template Section */}
        <RenderIf
          condition={
            flow.type === "PRIMARY_CONNECT" ||
            flow.type === "INMAIL_OPTIMIZATION"
          }
        >
          <FlowSectionHeading type={templateType} />
          {!isPrimaryConnectAndNotPremium && flow.template ? (
            <FlowTemplate
              flowTemplate={flow.template}
              setFlowTemplate={updateFlowTemplate}
              onRemove={() => updateFlowTemplate(null)}
              toggleAi={() =>
                updateFlowTemplate({ ...flow.template, ai: !flow.template.ai })
              }
            />
          ) : (
            <Placeholder
              templateType={templateType}
              shouldShowPremiumBanner={
                isPrimaryConnectAndNotPremium &&
                templateType === "NOTE_TEMPLATE"
              }
              onAdd={addEmptyTemplate}
            />
          )}
        </RenderIf>

        {flow.type !== "INMAIL_OPTIMIZATION" ? (
          <MessageTemplates messageTemplates={flow.flow_message_templates} />
        ) : (
          <RenderIf condition={isFlowError}>
            <p className="-mt-2 text-caption-12-regular text-red-500 lg:-mt-4">
              You must select a template for InMails if InMail Optimization is
              ON
            </p>
          </RenderIf>
        )}
      </div>
    </CampaignFlowProvider>
  );
}
