import React, { useState } from "react";
import { CaretDown, Info } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { typedObjectEntries } from "common/helpers/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { KeywordTypes } from "common/types";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";

import { keywordOptions } from "../../../../../../constants";

interface KeywordTypeSelectProps {
  selectedKeywordType: KeywordTypes;
  setSelectedKeywordType: (keyword: KeywordTypes) => void;
}

export default function KeywordTypeSelect({
  selectedKeywordType,
  setSelectedKeywordType,
}: KeywordTypeSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("campaigns", {
    keyPrefix: "targetingSetup",
  });

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <span className="text-caption-12-regular text-black-700">
          Keyword type
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Info size={16} weight="fill" className="fill-black-300" />
          </TooltipTrigger>
          <TooltipContent side="bottom">{t("keywordsTooltip")}</TooltipContent>
        </Tooltip>
      </div>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="group ml-auto flex h-10 min-w-[120px] items-center justify-between rounded-xl bg-whiteGray px-3 text-button-14 text-black-700 data-[state=open]:text-black-950 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 md:w-[280px]">
          {keywordOptions[selectedKeywordType].label}
          <CaretDown
            size={20}
            className="ml-2 transition-transform group-data-[state=open]:rotate-180"
          />
        </PopoverTrigger>
        <PopoverContent className="flex w-[--radix-popper-anchor-width] flex-col py-2">
          {typedObjectEntries(keywordOptions).map(([key, { label }]) => (
            <button
              type="button"
              key={key}
              onClick={() => {
                setSelectedKeywordType(key);
                setIsOpen(false);
              }}
              className="w-full px-4 py-2 text-left text-button-14 transition-colors hover:bg-black-50"
            >
              {label}
            </button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
}
