import React from "react";
import clsx from "clsx";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import RenderIf from "common/components/RenderIf";

import { ConversationMessage, MessageReaction } from "../../../types";
import { useConversationUrnContext } from "../ConversationUrnContext";
import useConversationMessagesUpdates from "../../../datahooks/useConversationMessagesUpdates";
import EmojiSelector from "../../EmojiSelector";

import SmileyAdd from "assets/icons/smiley-add.svg?react";

export default function Reactions({
  message,
  reactions,
}: {
  message: ConversationMessage;
  reactions: MessageReaction[];
}) {
  const { account } = useSelectedSubscriptionContext();
  const conversationUrn = useConversationUrnContext();
  const { sendMessageReaction } = useConversationMessagesUpdates({
    conversationUrn,
  });

  const isMine = Number(account?.id) === message.sender?.id;

  function onMessageReact(emoji: string) {
    // whether the user reacted or not
    const { reader_reacted: isReacted } =
      message.reactions?.find((reaction) => reaction.emoji === emoji) || {};

    sendMessageReaction({
      emoji,
      messageUrn: message.urn,
      unreact: isReacted,
    });
  }

  return (
    <div
      className={clsx([
        "flex flex-row items-center gap-0.5 pl-3.5",
        isMine ? "justify-end" : "justify-start",
      ])}
    >
      {reactions?.map(({ emoji, count, reader_reacted }) => (
        <RenderIf key={emoji} condition={!!count}>
          <button
            type="button"
            className={clsx([
              "flex h-fit flex-row items-center justify-center gap-2 rounded-full px-2 py-0.5",
              isMine ? "border border-black-200 bg-white" : "bg-purple-50",
            ])}
            onClick={() =>
              sendMessageReaction({
                emoji,
                messageUrn: message.urn,
                unreact: reader_reacted,
              })
            }
          >
            <span className="text-caption-12-regular">{emoji}</span>
            <span className="text-caption-12-regular">{count}</span>
          </button>
        </RenderIf>
      ))}

      <EmojiSelector icon={<SmileyAdd />} onEmojiSelection={onMessageReact} />
    </div>
  );
}
