import { useQuery, useMutation } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";

import { Invoice } from "../types";

async function getInvoices(): Promise<Invoice[]> {
  return (await get(`billing/invoices`)).invoices;
}
async function getInvoiceUrlRequest(invoiceId: string) {
  return (await get(`billing/invoices/${invoiceId}/download`)).link;
}

export default function useBilling() {
  const { data: invoices, isLoading: isLoadingInvoices } = useQuery({
    queryKey: ["invoices"],
    queryFn: getInvoices,
  });

  const { mutateAsync: getInvoiceUrl, isPending: isGettingInvoiceUrl } =
    useMutation({
      mutationFn: ({ invoiceId }: { invoiceId: string }) =>
        getInvoiceUrlRequest(invoiceId),
    });

  return { invoices, isLoadingInvoices, getInvoiceUrl, isGettingInvoiceUrl };
}
