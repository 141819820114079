import React, { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const inputVariants = cva(
  [
    "relative inline-flex items-center justify-center bg-whiteGray text-black-700",
    "placeholder:text-black-400",
    "has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-50",
    "has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-purple-200",
    "data-[error=true]:bg-red-50 data-[error=true]:text-red-500 data-[error=true]:has-[:focus-visible]:ring-red-300",
  ],
  {
    variants: {
      variant: {
        sm: "h-8 gap-1.5 rounded-lg p-2 text-button-12 [&_svg]:size-4",
        md: "h-10 gap-2 rounded-xl p-3 text-button-14 [&_svg]:size-5",
        lg: "h-12 gap-3 rounded-xl px-4 py-3 text-button-16 [&_svg]:size-5",
      },
    },
    defaultVariants: {
      variant: "md",
    },
  },
);

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  /** Error state, string renders an error message */
  error?: string | boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      error,
      variant,
      leftComponent,
      rightComponent,
      ...props
    },
    ref,
  ) => (
    <div className={cn("flex flex-col gap-1", className)}>
      <div
        className={inputVariants({ variant })}
        data-error={!!error || undefined}
      >
        {leftComponent}
        <input
          type={type}
          className="w-full bg-transparent focus-visible:outline-none disabled:cursor-not-allowed"
          ref={ref}
          {...props}
        />
        {rightComponent}
      </div>

      {/* Error message */}
      {typeof error === "string" && (
        <span className="text-caption-12-regular text-red-500">{error}</span>
      )}
    </div>
  ),
);

Input.displayName = "Input";

Input.defaultProps = {
  leftComponent: null,
  rightComponent: null,
  error: null,
};

export { Input, inputVariants, type InputProps };
