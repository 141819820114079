import React, { ReactNode, useEffect, useState } from "react";
import { clsx } from "clsx";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import PlansOverview from "common/components/Layout/Onboarding/PlansOverview";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import useCustomer from "common/datahooks/useCustomer";
import useLinkedInAccounts from "common/datahooks/useLinkedInAccounts";
import LinkedInAccountCard from "common/components/Layout/LinkedInAccountCard";
import AccountSearch from "common/components/Layout/Onboarding/AccountSearch";
import useGTM from "common/hooks/useGTM";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import useSubscriptionUpdates from "common/datahooks/useSubscriptionUpdates";
import LogoutPopup from "common/components/Layout/LogoutPopup";

import OnboardingHelpImage from "assets/images/linkedin-onboarding-help.webp";
import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";

function Onboarding({ isOpen }: { isOpen: boolean }) {
  const isDesktop = useTwBreakpoint("lg");
  const { customerInfo } = useCustomer();
  const { findAccount } = useLinkedInAccounts();
  const { sendEvent } = useGTM();
  const { selectSubscription } = useSubscriptionSelection();
  const { createSubscription, isCreatingSubscription } =
    useSubscriptionUpdates();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [currentStep, setCurrentStep] = useState<
    "welcome" | "recommended" | "manualsearch"
  >("welcome");

  useEffect(() => {
    findAccount({ emailOrUrl: "test@test.com" }).then((profile) => {
      setSelectedAccount(profile);
    });
  }, []);

  function startFreeTrial() {
    createSubscription({ account: selectedAccount }).then((subscription) => {
      sendEvent("free_trial_start");
      selectSubscription(`${subscription.id}`);
    });
  }

  let title = "Welcome to Aimfox";
  let subtitle: ReactNode =
    "Let’s set up your account and start growing your LinkedIn network";
  let mainComponent = (
    <Button
      size="lg"
      variant="primary-black"
      className="lg:mt-32"
      isLoading={isCreatingSubscription}
      onClick={() =>
        setCurrentStep(selectedAccount ? "recommended" : "manualsearch")
      }
    >
      Proceed
    </Button>
  );

  if (currentStep === "manualsearch" || currentStep === "recommended") {
    mainComponent = selectedAccount ? (
      <>
        <LinkedInAccountCard
          account={selectedAccount}
          changeAccount={() => {
            setCurrentStep("manualsearch");
            setSelectedAccount(null);
          }}
        />
        <Button
          size="lg"
          variant="primary-black"
          className="mt-8"
          isLoading={isCreatingSubscription}
          onClick={startFreeTrial}
        >
          Proceed
        </Button>
      </>
    ) : (
      <AccountSearch setAccount={setSelectedAccount} />
    );
  }

  if (currentStep === "recommended") {
    title = "Is this your LinkedIn account?";
    subtitle = (
      <>
        Because you signed up with{" "}
        <span className="underline">{customerInfo.email}</span> we found a
        LinkedIn associated with that email. Is this your account?
      </>
    );
  } else if (currentStep === "manualsearch") {
    title = selectedAccount
      ? "Is this your LinkedIn account?"
      : "Find your LinkedIn account";
    subtitle =
      !selectedAccount &&
      "Search the LinkedIn account you want to connect to Aimfox. You can use your e-mail or LinkedIn URL to do this";
  }
  const isShowingPlans = currentStep !== "manualsearch" || !!selectedAccount;

  const content = (
    <>
      <div className="flex flex-col px-4 pb-4 lg:w-1/2">
        <div className="flex items-center py-4 max-lg:hidden">
          <LogoutPopup />
        </div>
        <div className="flex grow flex-col justify-center lg:px-14">
          <div className="flex items-end max-lg:hidden lg:mb-4 xl:mb-6">
            <AimfoxLogoOrange className="w-12" />
            <span className="mb-0.5 text-headline-2xl-bold">Aimfox</span>
          </div>
          <h2
            className={clsx(
              subtitle ? "mb-2" : "lg:mb-4 xl:mb-8",
              "text-headline-4xl-bold",
            )}
          >
            {title}
          </h2>
          {subtitle && (
            <p className="text-black-600 lg:mb-4 xl:mb-8">{subtitle}</p>
          )}
          {mainComponent}
        </div>
      </div>
      <div
        className={clsx(
          isShowingPlans ? "h-auto" : "max-lg:h-80",
          "relative flex items-center bg-[url('/images/onboarding-bg.png')] bg-cover max-lg:m-4 max-lg:mt-8 max-lg:rounded-3xl lg:w-1/2",
        )}
      >
        {isShowingPlans ? (
          <PlansOverview />
        ) : (
          <img
            alt={`an arrow with the label "copy URL" pointing at a url "https://linkedin.com/in/YOUR PROFILE URL" inside a browser window`}
            src={OnboardingHelpImage}
            className="absolute right-0 w-11/12 lg:w-10/12"
          />
        )}
      </div>
    </>
  );

  return isDesktop ? (
      <Dialog open={isOpen}>
        <DialogContent className="flex h-[90%] w-4/5 flex-row overflow-hidden p-0 2xl:h-4/5">
          {content}
        </DialogContent>
      </Dialog>

  ) : (
    <div
      className={clsx(
        "fixed inset-0 top-20 overflow-y-auto bg-white pt-14",
        isOpen ? "opacity-100" : "pointer-events-none opacity-0",
      )}
    >
      {content}
    </div>
  );
}
Onboarding.displayName = "Onboarding";

export default Onboarding;
