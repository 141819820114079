import { createContext, useContext } from "react";
import { produce } from "immer";

import { Subscription } from "common/types";
import useAppStore from "common/hooks/useAppStore";

const SelectedSubscriptionContext = createContext<Subscription>(null);

export const SelectedSubscriptionProvider =
  SelectedSubscriptionContext.Provider;

export function useSelectedSubscriptionContext(): Subscription {
  const selectedSubscription = useContext(SelectedSubscriptionContext);
  // modify subscription according to debug options
  const { subscriptionStatus, accountState, subscriptionRole } = useAppStore(
    (state) => state.debugOptions,
  );

  return produce(selectedSubscription, (draftSubscription) => {
    if (subscriptionStatus) {
      draftSubscription.status = subscriptionStatus;
    }
    if (subscriptionRole) {
      draftSubscription.role = subscriptionRole;
    }
    if (accountState) {
      draftSubscription.account.state = accountState;
    }
  });
}
