import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, CreditCard } from "@phosphor-icons/react";

import useGTM from "common/hooks/useGTM";
import { HTTPError } from "common/helpers/HTTP";
import useBraintreeToken from "common/datahooks/useBraintreeToken";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { Button } from "common/components/ui/Button";

import CreditCardInput from "./CreditCardInput";
import usePaymentSources from "../../datahooks/usePaymentSources";
import PaypalCheckout from "./PaypalCheckout";

export default function AddPaymentMethod({ onBack }: { onBack: () => void }) {
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [braintreeClient, setBraintreeClient] = useState<BraintreeClient>(null);
  const [isParsingCard, setIsParsingCard] = useState(false);
  const { setPrimaryPaymentSource, isSettingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  const getTokenFunctionRef = useRef<() => Promise<string>>();
  const {
    addPaymentSource,
    isAddingPaymentSource,
    addPaymentSourceError,
    resetAddPaymentSourceError,
  } = usePaymentSources({ shouldIncludeSubscriptionId: false });

  const { braintreeToken } = useBraintreeToken();
  const { sendEvent } = useGTM();

  useEffect(() => {
    if (braintreeToken) {
      braintree.client
        .create({
          authorization: braintreeToken,
        })
        .then((clientInstance) => {
          setBraintreeClient(clientInstance);
        });
    }
  }, [braintreeToken]);

  useEffect(() => {
    if (window.Chargebee) {
      window.Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEBEE_KEY,
      });
    }
  }, []);

  function onAdd(token: string, source: "card" | "paypal" | "apple-pay") {
    addPaymentSource({ token, source })
      .then((paymentSource) => {
        sendEvent("add_payment_source_success", ({ email }) => ({
          email,
          payment_method: source,
        }));

        setPrimaryPaymentSource({ paymentSource }).then(() => onBack());
      })
      .catch(() => {
        sendEvent("add_payment_source_fail", ({ email }) => ({
          email,
          payment_method: source,
        }));
      });
  }

  async function onCreditCardSave() {
    let token;
    try {
      resetAddPaymentSourceError();
      setIsParsingCard(true);
      token = await getTokenFunctionRef.current();
    } finally {
      setIsParsingCard(false);
    }
    onAdd(token, "card");
  }

  let content = (
    <div className="flex flex-col gap-4">
      <button
        className="flex w-full items-center justify-center gap-2 rounded-2xl bg-purple-50 py-4 text-body-14-bold"
        onClick={() => setIsAddingCard(true)}
        type="button"
      >
        <CreditCard weight="fill" size={20} />
        Credit Card
      </button>

      {braintreeClient && (
        <PaypalCheckout
          onTokenReceived={(token) => onAdd(token, "paypal")}
          braintreeClient={braintreeClient}
        />
      )}
    </div>
  );
  if (isAddingCard) {
    content = (
      <>
        <CreditCardInput getTokenFunctionRef={getTokenFunctionRef} />

        {addPaymentSourceError && (
          <span className="mt-3 text-center text-red-500">
            {(addPaymentSourceError instanceof HTTPError &&
              addPaymentSourceError.serverMessage) ||
              "Failed to add card. Please try again or contact support."}
          </span>
        )}
      </>
    );
  }
  return (
    <>
      <h3 className="mb-2 text-headline-2xl-bold capitalize">
        Add payment method
      </h3>

      <p className="mb-4 text-black-500">
        Add a preferred payment method for your Aimfox subscription. You can
        change this at any time
      </p>

      {content}

      <div className="mt-4 flex flex-row justify-between gap-4">
        <Button
          className="w-fit"
          onClick={isAddingCard ? () => setIsAddingCard(false) : onBack}
        >
          <ArrowLeft size={20} />
          Back
        </Button>

        {isAddingCard && (
          <Button
            variant="primary-purple"
            isLoading={
              isAddingPaymentSource ||
              isParsingCard ||
              isSettingPrimaryPaymentSource
            }
            onClick={onCreditCardSave}
          >
            Add Credit Card
          </Button>
        )}
      </div>
    </>
  );
}
