import React from "react";
import { ClockCountdown } from "@phosphor-icons/react";

import { Invitation } from "common/types";
import { Tag } from "common/components/ui/Tag";
import ProfileImage from "common/components/ui/ProfileImage";

export default function InvitationItem({
  invitation,
  openInvitation,
}: {
  invitation: Invitation;
  openInvitation: () => void;
}) {
  return (
    <button
      type="button"
      className="flex w-full items-center rounded-20 px-4 pr-[3.25rem] hover:bg-whiteGray max-lg:h-[74px] lg:rounded-2xl lg:p-1"
      onClick={openInvitation}
    >
      <ProfileImage size="md" src={invitation.account.picture_url} />
      <span className="ml-2 mr-1 truncate text-body-14-bold lg:text-caption-12-bold">
        {invitation.account.full_name}
      </span>
      <Tag variant="info" className="ml-auto" leftIcon={<ClockCountdown />}>
        Pending
      </Tag>
    </button>
  );
}
