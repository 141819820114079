import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Button } from "common/components/ui/Button";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import ConversationItem from "./ConversationItem";
import { Conversation } from "../../types";
import ConversationLoader from "./ConversationLoader";

import NoResultsImage from "assets/images/empty-placeholders/conversation-not-found.png";

interface ConversationListProps {
  isLoadingConversations: boolean;
  conversations: Conversation[];
  activeConversation: Conversation;
  hasMoreConversations: boolean;
  keywords: string;
  setActiveConversationUrn: (conversationUrn: string) => void;
  loadMoreConversations: () => void;
  clearKeywords: () => void;
}

export default function ConversationsList({
  loadMoreConversations,
  isLoadingConversations,
  conversations,
  activeConversation,
  setActiveConversationUrn,
  hasMoreConversations,
  keywords,
  clearKeywords,
}: ConversationListProps) {
  if (isLoadingConversations) {
    return <ConversationLoader itemsCount={7} />;
  }

  if (!conversations.length) {
    if (keywords) {
      return (
        <EmptyPlaceholder
          imageSrc={NoResultsImage}
          title="No results found"
          subtitle={`No results found for "${keywords}"`}
          actionComponent={
            <Button className="mt-2" onClick={clearKeywords}>
              Clear Search
            </Button>
          }
        />
      );
    }

    return (
      <p className="py-6 text-center text-headline-xl-regular text-black-400">
        No conversations
      </p>
    );
  }

  return (
    <InfiniteScroll
      className="flex flex-col"
      next={loadMoreConversations}
      hasMore={hasMoreConversations}
      dataLength={conversations.length}
      loader={<ConversationLoader itemsCount={3} />}
      scrollableTarget="scroll-container"
    >
      {conversations?.map((conversation, index) => (
        <ConversationItem
          key={conversation.conversation_urn || index}
          isSelected={
            activeConversation?.conversation_urn &&
            activeConversation.conversation_urn ===
              conversation.conversation_urn
          }
          conversation={conversation}
          onSelect={() =>
            setActiveConversationUrn(conversation.conversation_urn)
          }
          isLoading={isLoadingConversations}
        />
      ))}
    </InfiniteScroll>
  );
}
