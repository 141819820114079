import React, { JSX, ReactNode } from "react";
import { clsx } from "clsx";

import useAppStore from "common/hooks/useAppStore";
import { NavItemType } from "common/components/Layout";

import MobileHeader from "./MobileHeader";

export default function MobileLayout({
  navItems,
  goBack,
  children,
  headerTitle,
  bannerComponent,
}: {
  navItems: NavItemType[];
  goBack: () => void;
  children: ReactNode;
  headerTitle: string;
  bannerComponent: JSX.Element;
}) {
  const isPageHeightLimited = useAppStore((state) => state.isPageHeightLimited);

  return (
    <div
      className={clsx(
        "flex min-h-screen flex-col",
        isPageHeightLimited && "100vh",
      )}
    >
      <MobileHeader goBack={goBack} navItems={navItems} />
      <div className="flex grow flex-col p-4">
        {bannerComponent}
        <h1 className="mb-4 text-headline-xl-bold capitalize text-black-950">
          {headerTitle}
        </h1>
        {children}
      </div>
    </div>
  );
}
