import {
  BooleanParam,
  JsonParam,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import React, { useEffect, useRef, useState } from "react";
import { FunnelSimple, Info, X } from "@phosphor-icons/react";

import { typedObjectEntries } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import Pagination from "common/components/ui/Pagination";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import LeadPreview from "common/components/LeadPreview";
import SearchInput from "common/components/SearchInput";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import useAppStore from "common/hooks/useAppStore";

import useLeads from "../../datahooks/useLeads";
import { defaultLeadsSearchParams } from "../../constants";
import { FacetParam, LeadsSearchParams, SelectedFacetParam } from "../../types";
import MobileFilters from "./MobileFilters";
import SearchFilters from "./SearchFilters";
import LeadsList from "./LeadsList";
import LeadsTable from "./LeadsTable";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import SyncingBadge from "./SyncingTooltip";
import ExportLeads from "./ExportLeads";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

const paramsConfig = typedObjectEntries(defaultLeadsSearchParams).reduce(
  (accumulator, [key, value]) => {
    if (typeof value === "boolean") {
      accumulator[key] = withDefault(BooleanParam, value);
    } else if (typeof value === "string") {
      accumulator[key] = withDefault(StringParam, value);
    } else {
      accumulator[key] = withDefault(JsonParam, value);
    }
    return accumulator;
  },
  {} as QueryParamConfigMap,
);

const pageSize = 8;
export default function AllLeads() {
  const isDesktop = useTwBreakpoint("lg");
  const timeoutRef = useRef(null);
  const [searchParams, setSearchParams] = useQueryParams(paramsConfig, {
    removeDefaultsFromUrl: true,
    updateType: "replaceIn",
  }) as [
    LeadsSearchParams<FacetParam>,
    (searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>) => void,
  ];
  const { toggleSearchParam, setSearchParam, resetAllParams } =
    useLeadsSearchParams(searchParams, setSearchParams);
  const [keywordInputValue, setKeywordInputValue] = useState(
    searchParams.keywords,
  );
  const [previewLeadId, setPreviewLeadId] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 0), {
    removeDefaultsFromUrl: true,
    updateType: "replaceIn",
  });
  const [orderSort, setOrderSort] = useQueryParam<"asc" | "desc">(
    "order",
    null,
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );
  const {
    leads,
    facets,
    totalLeads,
    isSyncing,
    isFetchingLeads,
    leadsError,
    refetchLeads,
  } = useLeads({
    searchParams,
    page,
    order: orderSort,
    pageSize,
  });

  useEffect(() => {
    if (isSyncing) {
      const originalHeaderContent = useAppStore.getState().headerContent;
      useAppStore.getState().setHeaderContent({
        component: <SyncingBadge />,
      });
      return () => {
        useAppStore.getState().setHeaderContent(originalHeaderContent);
      };
    }
  }, [isSyncing]);

  useEffect(() => {
    if (keywordInputValue) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => setSearchParam("keywords", keywordInputValue),
        800,
      );
      return () => clearTimeout(timeoutRef.current);
    }
    setSearchParam("keywords", keywordInputValue);
  }, [keywordInputValue]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [searchParams, page]);

  useEffect(() => {
    setPage(0);
  }, [searchParams]);

  const filtersComponent = (
    <SearchFilters
      facets={facets}
      isLoading={isFetchingLeads}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
    />
  );

  let mainComponent;

  if (leadsError) {
    mainComponent = (
      <ErrorPlaceholder
        errorMessage="Could not display leads data"
        onRetry={refetchLeads}
      />
    );
  } else if (leads && !leads.length) {
    mainComponent = (
      <EmptyPlaceholder
        title="No results found"
        subtitle=""
        imageSrc={NoResultsImage}
        actionComponent={
          <Button
            className="w-full"
            onClick={() => {
              setKeywordInputValue("");
              setSearchParam("keywords", "");
            }}
          >
            Clear search
          </Button>
        }
      />
    );
  } else {
    const Component = isDesktop ? LeadsTable : LeadsList;
    mainComponent = (
      <>
        <Component
          leads={leads}
          isLoading={isFetchingLeads}
          openPreview={({ id }) => {
            setPreviewLeadId(id);
            setIsPreviewOpen(true);
          }}
          orderSort={orderSort}
          setOrderSort={setOrderSort}
        />
        <LeadPreview
          lead={leads && leads.find(({ id }) => id === previewLeadId)}
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
        />
      </>
    );
  }

  const hasFilters = Object.values(searchParams).some(
    (value) => Array.isArray(value) && value.length,
  );

  return (
    <div className="flex flex-col lg:grow lg:flex-row">
      <div className="flex h-fit shrink-0 flex-col lg:mr-6 lg:w-[326px]">
        {isDesktop ? (
          <div className="rounded-20 border border-black-200">
            <div className="flex items-center gap-x-2 border-b border-b-black-200 p-4">
              <Button variant="secondary-black" intent="labelIcon">
                <FunnelSimple />
              </Button>
              <span className="text-headline-lg-bold">Filters</span>
              {hasFilters && (
                <Button
                  variant="quaternary-danger"
                  className="ml-auto"
                  onClick={resetAllParams}
                >
                  Clear all
                </Button>
              )}
            </div>
            <div className="p-4 pt-2">{filtersComponent}</div>
          </div>
        ) : (
          <MobileFilters
            filtersComponent={filtersComponent}
            resetAllParams={hasFilters && resetAllParams}
          />
        )}
      </div>
      <div className="flex flex-col lg:w-2/3 lg:grow">
        <div className="mb-2 flex w-full flex-col-reverse gap-y-2 lg:mb-4 lg:flex-row lg:items-center lg:justify-between lg:gap-x-4">
          <SearchInput
            placeholder="Search Leads"
            onChange={(e) => setKeywordInputValue(e.target.value)}
            value={keywordInputValue}
            onClear={() => setKeywordInputValue("")}
            className="md:min-w-80"
          />
          {totalLeads && (
            <div className="flex items-center">
              <span className="ml-auto mr-4 text-button-14 text-black-400">
                {totalLeads} Leads found
              </span>
              <ExportLeads
                searchParams={searchParams}
                totalLeads={totalLeads}
                isDisabled={isFetchingLeads}
              />
              <Tooltip>
                <TooltipTrigger>
                  <Info weight="fill" className="size-5 fill-black-300" />
                </TooltipTrigger>
                <TooltipContent side="left">
                  Download selected leads data in .CSV format
                </TooltipContent>
              </Tooltip>
            </div>
          )}
        </div>
        {!!searchParams.labels.length && (
          <div className="mb-4 flex gap-2.5">
            {searchParams.labels.map((label) => (
              <Tag
                key={label.name}
                variant="primary-black"
                size="lg"
                rightIcon={
                  <button
                    type="button"
                    aria-label={`remove label ${label}`}
                    onClick={() => toggleSearchParam("labels", label)}
                  >
                    <X />
                  </button>
                }
              >
                {label.name}
              </Tag>
            ))}
          </div>
        )}
        {mainComponent}
        {!isFetchingLeads && !leadsError && (
          <Pagination
            pagesCount={Math.ceil(totalLeads / pageSize)}
            setPage={setPage}
            selectedIndex={page}
          />
        )}
      </div>
    </div>
  );
}
