import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del, patch, HTTPError } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { CampaignUpdates, DetailedCampaign, Target } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

type NewCampaign = Pick<
  DetailedCampaign,
  | "name"
  | "type"
  | "subtype"
  | "connect"
  | "message"
  | "optimizations"
  | "audience_size"
>;

async function createCampaignRequest(
  accountId: number,
  newCampaign: NewCampaign,
) {
  return (await post(`accounts/${accountId}/campaigns`, newCampaign)).campaign;
}
async function updateCampaignRequest(
  accountId: number,
  campaignId: string,
  updates: CampaignUpdates,
) {
  await patch(`accounts/${accountId}/campaigns/${campaignId}`, updates);
  return { campaignId, updates };
}
async function deleteCampaignRequest(accountId: number, campaignId: string) {
  await del(`accounts/${accountId}/campaigns/${campaignId}`);
  return campaignId;
}

async function updateAudienceSizeRequest(
  accountId: number,
  campaignId: string,
  size: number,
) {
  const removedTargets: string[] = (
    await post(`accounts/${accountId}/campaigns/${campaignId}/audience`, {
      size,
    })
  ).removed;

  return { removedTargets, size };
}

export default function useCampaignMutations() {
  const queryClient = useQueryClient();
  const { account } = useSelectedSubscriptionContext();
  const {
    updateCachedCampaign,
    updateCachedCampaigns,
    updateCachedCampaignInList,
  } = useUpdateCampaign(account);

  const { campaignsKeys } = getQueryKeys(account);

  const { mutateAsync: createCampaign, isPending: isCreatingCampaign } =
    useMutation({
      mutationFn: (newCampaign: NewCampaign) =>
        createCampaignRequest(account?.id, newCampaign),
    });

  const { mutateAsync: updateCampaign, isPending: isUpdatingCampaign } =
    useMutation({
      mutationFn: ({
        campaignId,
        updates,
      }: {
        campaignId: string;
        updates: CampaignUpdates;
      }) => updateCampaignRequest(account?.id, campaignId, updates),
      onSuccess: ({ updates, campaignId }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => ({
          ...draftCampaign,
          ...updates,
        }));

        updateCachedCampaignInList(campaignId, updates);
      },
    });

  const { mutateAsync: deleteCampaign, isPending: isDeletingCampaign } =
    useMutation({
      mutationFn: ({ campaignId }: { campaignId: string }) =>
        deleteCampaignRequest(account?.id, campaignId),
      onSuccess: (campaignId) => {
        updateCachedCampaigns((campaigns) =>
          campaigns.filter(({ id }) => id !== campaignId),
        );
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  const { mutateAsync: updateAudienceSize, isPending: isUpdatingAudienceSize } =
    useMutation({
      mutationFn: ({
        campaignId,
        size,
      }: {
        campaignId: string;
        size: number;
      }) => updateAudienceSizeRequest(account?.id, campaignId, size),
      onSuccess: ({ removedTargets, size }, { campaignId }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.audience_size = size;
        });

        queryClient.setQueryData(
          campaignsKeys.targets(campaignId),
          (prevTargets: Target[]) => {
            const newTargets = prevTargets.filter(
              (prevTarget) => !removedTargets.includes(prevTarget.urn),
            );

            updateCachedCampaign(campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    createCampaign,
    isCreatingCampaign,
    updateCampaign,
    isUpdatingCampaign,
    deleteCampaign,
    isDeletingCampaign,
    updateAudienceSize,
    isUpdatingAudienceSize,
  };
}
