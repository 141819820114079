import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

async function getThisWasMeTokenRequest(
  subscriptionId: string,
  email: string,
): Promise<string> {
  return (
    await get(
      `subscriptions/${subscriptionId}/account/challenge/${email}/token`,
    )
  ).token;
}

export default function useThisWasMeToken(email: string) {
  const { id } = useSelectedSubscriptionContext();

  const { data: thisWasMeToken, isPending: isLoadingToken } = useQuery({
    queryKey: ["thisWasMeToken", email],
    queryFn: () => getThisWasMeTokenRequest(id, email),
  });

  return { thisWasMeToken, isLoadingToken };
}
