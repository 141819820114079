import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { VariantProps, cva } from "class-variance-authority";
import { Check } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

const checkboxVariants = cva(
  [
    "shrink-0 rounded border-2 border-black-400",
    "data-[state=checked]:border-black-950 data-[state=checked]:bg-black-950 data-[state=checked]:text-white",
    "enabled:hover:border-black-950",
    "transition-colors ease-in-out",
    "disabled:cursor-not-allowed disabled:opacity-30",
  ],
  {
    variants: {
      size: {
        sm: "size-3.5",
        md: "size-4",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

type SwitchProps = ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
  VariantProps<typeof checkboxVariants>;

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  SwitchProps
>(({ className, size, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ size, className }))}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center")}
    >
      <Check size={size === "md" ? 12 : 10} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
