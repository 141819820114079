import { useLocation, useParams } from "react-router-dom";
import { useMemo } from "react";

import routes from "../../pages/routes";

export default function useCurrentBaseRoute() {
  const { subscriptionId } = useParams();
  const location = useLocation();

  const basePath = subscriptionId ? `/${subscriptionId}/` : "/";
  const rawRoute = routes.find(({ path }) =>
    location.pathname.includes(basePath + path.split("/")[0].replace("/*", "")),
  );

  return useMemo(() => rawRoute, [rawRoute]);
}
