import React, { ReactNode, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { CampaignType } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import CampaignTypes from "./CampaignTypes";
import CampaignSettings from "./CampaignSettings";

export default function CreateNewCampaign({
  triggerButton,
}: {
  triggerButton: ReactNode;
}) {
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [campaignType, setCampaignType] = useState<CampaignType>("search");
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;

  function toggleStep() {
    setIsFirstStep(!isFirstStep);
  }

  return (
    <Component
      onAfterClose={() => {
        setIsFirstStep(true);
        setCampaignType("search");
      }}
    >
      <Trigger asChild>{triggerButton}</Trigger>
      <Content className="max-md:h-dvh md:max-w-[740px] md:rounded-3xl">
        {isFirstStep ? (
          <CampaignTypes
            type={campaignType}
            setType={setCampaignType}
            goToNextStep={toggleStep}
          />
        ) : (
          <CampaignSettings type={campaignType} goBack={toggleStep} />
        )}
      </Content>
    </Component>
  );
}
