import React, { useEffect } from "react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useAppSumo from "common/datahooks/useAppSumo";
import { Button } from "common/components/ui/Button";

export default function AppSumoDialog({
  isOpen,
  code,
  onClose,
}: {
  isOpen: boolean;
  code: string;
  onClose: () => void;
}) {
  const { applyAppSumo, isApplyingAppSumo, appSumoError } = useAppSumo();

  useEffect(() => {
    if (code) {
      applyAppSumo({ code });
    }
  }, [code]);

  let title = "You AppSumo license is activated";
  let subtitle =
    "Your AppSumo license has just been activated. It will be applied on your next subscription";

  if (isApplyingAppSumo) {
    title = "Your AppSumo license is being activated";
    subtitle = "Please do not close or refresh this window";
  } else if (appSumoError) {
    title = "We could not activate your AppSumo license";
    subtitle = "Please try again in your AppSumo dashboard";
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && !isApplyingAppSumo) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[540px]">
        <h3 className="mb-2 mt-4 text-center text-headline-2xl-bold">
          {title}
        </h3>
        <p className="text-center text-body-14-regular text-black-500">
          {subtitle}
        </p>
        <Button
          size="lg"
          onClick={onClose}
          className="mt-8"
          isLoading={isApplyingAppSumo}
        >
          Proceed
        </Button>
      </DialogContent>
    </Dialog>
  );
}
