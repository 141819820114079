import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

import { timeSince } from "common/helpers/utils";
import useNotifications from "common/datahooks/useNotifications";

export default function NotificationItem({
  title,
  body,
  seen,
  created_at,
  id,
}: {
  title: string;
  body: string;
  seen: boolean;
  created_at: number;
  id: string;
}) {
  const { openNotification } = useNotifications();

  useEffect(() => {
    if (!seen) {
      const timeoutId = setTimeout(
        () => openNotification({ notificationId: id }),
        1000,
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <Link
      className={clsx(
        "flex flex-col gap-y-2 rounded-2xl p-3.5",
        !seen && "bg-purple-50",
      )}
      to="account/notifications"
    >
      <div className="flex items-center">
        <span className="text-caption-12-bold text-black-950">{title}</span>
        <span className="ml-auto text-caption-10-regular text-black-500">
          {timeSince(created_at * 1000)}
        </span>
        {!seen && (
          <span className="m-1 ml-3 size-1.5 rounded-full bg-purple-500" />
        )}
      </div>
      <span className="text-caption-12-regular">{body}</span>
    </Link>
  );
}
