import React from "react";

import { getMonthName } from "common/helpers/utils";
import useSubscriptionUpdates from "common/datahooks/useSubscriptionUpdates";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

export default function CancelSubscriptionDialog({
  expirationTimestamp,
  subscriptionId,
}: {
  expirationTimestamp: number;
  subscriptionId: string;
}) {
  const {
    cancelSubscription,
    cancelledSubscription,
    isCancellingSubscription,
  } = useSubscriptionUpdates();

  const cancellationDate = new Date(expirationTimestamp);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  function onCancel() {
    cancelSubscription({ subscriptionId });
  }

  let content = (
    <>
      <div className="flex flex-col gap-4">
        <h3 className="text-center text-headline-2xl-bold">
          Are you sure you want to cancel your subscription?
        </h3>

        <p className="mb-6 text-center text-black-400">
          Your LinkedIn Outreach subscription will stay active, and you will
          keep access to all features until{" "}
          <span className="text-body-14-bold text-black-950">
            {cancellationDate.getDate()}{" "}
            {getMonthName(cancellationDate.getMonth())}{" "}
            {cancellationDate.getFullYear()}
          </span>
        </p>
      </div>

      <div className="mt-4 flex w-full flex-col justify-center gap-3 md:mt-0">
        <Button
          variant="tertiary-danger"
          className="w-full md:whitespace-nowrap"
          isLoading={isCancellingSubscription}
          onClick={onCancel}
        >
          Yes
        </Button>

        <Close asChild>
          <Button
            variant="quaternary-black"
            className="w-full"
            disabled={isCancellingSubscription}
            type="button"
          >
            No
          </Button>
        </Close>
      </div>
    </>
  );

  if (cancelledSubscription) {
    content = (
      <>
        <h3 className="mb-4 text-center text-headline-2xl-bold">
          Subscription scheduled for cancellation
        </h3>

        <p className="mb-6 text-center text-black-400">
          Your LinkedIn Outreach subscription is scheduled for cancellation. You
          will keep full access to all features until{" "}
          <span className="text-body-14-bold text-black-950">
            {cancellationDate.getDate()}{" "}
            {getMonthName(cancellationDate.getMonth())}{" "}
            {cancellationDate.getFullYear()}
          </span>
          , after which your subscription will cancel.
        </p>

        <Close asChild>
          <Button
            className="mt-4 w-full md:mt-0"
            disabled={isCancellingSubscription}
            type="button"
          >
            Close
          </Button>
        </Close>
      </>
    );
  }

  return (
    <Component>
      <Trigger asChild>
        <Button variant="gradient">Cancel Subscription</Button>
      </Trigger>

      <Content className="md:max-w-[540px]">{content}</Content>
    </Component>
  );
}
