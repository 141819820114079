import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { del, get, post } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Member } from "common/types";

async function fetchMembers(subscriptionId: string) {
  return (await get(`subscriptions/${subscriptionId}/members`)).members;
}

async function inviteMemberRequest(
  subscriptionId: string,
  email: string,
  role: string,
): Promise<Member> {
  return (
    await post(`subscriptions/${subscriptionId}/members`, {
      email: email.toLocaleLowerCase(),
      role,
    })
  ).invite;
}

async function removeMemberRequest(memberToken: string) {
  await del(`invites/${memberToken}`);
}
export default function useSubscriptionMembers() {
  const queryClient = useQueryClient();
  const selectedSubscription = useSelectedSubscriptionContext();

  const queryKey = [selectedSubscription.id, "members"];

  const { data: members, isLoading: isLoadingMembers } = useQuery<Member[]>({
    queryKey,
    queryFn: () => fetchMembers(selectedSubscription.id),
  });

  const {
    mutateAsync: inviteMember,
    isPending: isInvitingMember,
    error: inviteMemberError,
    reset: resetInviteMember,
  } = useMutation({
    mutationFn: ({ email, role }: { email: string; role: string }) =>
      inviteMemberRequest(selectedSubscription.id, email, role),
    onSuccess: (member) => {
      queryClient.setQueryData(queryKey, (prevMembers: Member[]) => [
        ...prevMembers,
        member,
      ]);
    },
  });
  const { mutateAsync: removeMember, isPending: isRemovingMember } =
    useMutation({
      mutationFn: ({ memberToken }: { memberToken: string }) =>
        removeMemberRequest(memberToken),
      onSuccess: (data, { memberToken }) => {
        queryClient.setQueryData(queryKey, (prevMembers: Member[]) =>
          prevMembers.filter(({ token }) => token !== memberToken),
        );
        toast.success("Member successfully removed");
      },
    });
  return {
    members,
    isLoadingMembers,
    inviteMember,
    isInvitingMember,
    inviteMemberError,
    resetInviteMember,
    removeMember,
    isRemovingMember,
  };
}
