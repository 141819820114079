import React, { ReactNode } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";

interface AccountItemProps {
  label: string;
  isEditing: boolean;
  onEditClick?: () => void;
  children: ReactNode;
}

export default function AccountSettingsItem({
  label,
  isEditing,
  onEditClick = null,
  children,
}: AccountItemProps) {
  return (
    <div className="flex w-full items-center justify-between border-b border-black-200 py-6">
      <div className="flex w-full flex-col items-start justify-start gap-2 md:flex-row md:gap-0">
        <span className="w-full max-w-[224px] text-black-600">{label}</span>

        <div className="w-full">{children}</div>
      </div>

      <RenderIf condition={!isEditing}>
        <Button
          className="mt-2 md:mt-0"
          intent="iconOnly"
          variant="quaternary-black"
          onClick={onEditClick}
        >
          <PencilSimpleLine />
        </Button>
      </RenderIf>
    </div>
  );
}
