import React from "react";
import { Info } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

export default function PaidPlanDetails() {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-2">
        <span className="text-body-16-bold">LinkedIn Outreach</span>

        <Tooltip>
          <TooltipTrigger>
            <Info className="fill-black-400" size={20} weight="fill" />
          </TooltipTrigger>

          <TooltipContent>
            <ul className="list-disc pl-3">
              <li>Bypass LinkedIn connection request limits</li>
              <li>AI-optimized outreach automation</li>
              <li>Follow-up direct message sequences</li>
              <li>Various performance optimization options</li>
              <li>Auto-translate campaigns and DMs</li>
            </ul>
          </TooltipContent>
        </Tooltip>
      </div>

      <ul className="flex w-full max-w-[697px] flex-col text-black-500">
        <li>
          Grow your LinkedIn network with fully automated, personalized
          campaigns that convert
        </li>
        <li>And a sprinkle of AI</li>
      </ul>
    </div>
  );
}
