import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import usePageTitle from "common/hooks/usePageTitle";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useCurrentBaseRoute from "../../hooks/useCurrentBaseRoute";
import useAppStore from "../../hooks/useAppStore";

import AccountDisconnectedImage from "assets/images/empty-placeholders/account-disconnected.png";

export default function AccountDisconnected() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const selectedSubscription = useSelectedSubscriptionContext();
  const baseRoute = useCurrentBaseRoute();
  const pageTitle = usePageTitle();
  const [isOpen, setIsOpen] = useState(false);
  const { subscriptionId } = useParams();

  useEffect(() => {
    if (
      selectedSubscription.account.state === "LoggedOut" &&
      !baseRoute.shouldHideDialogs
    ) {
      setIsOpen(true);
    }
  }, [subscriptionId, baseRoute]);

  function onReconnect() {
    setIsOpen(false);
    useAppStore.getState().setIsShowingLogin(true);
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:w-[540px]">
        <img
          alt=""
          className="mx-auto mb-4 mt-12"
          src={AccountDisconnectedImage}
        />
        <h2 className="mb-2 text-center text-headline-2xl-bold">
          You have been disconnected
        </h2>
        {baseRoute.accountDisconnectedText?.split("\n").map((text) => (
          <Fragment key={text}>
            <p className="text-center text-black-500">{text}</p>
            <br />
          </Fragment>
        ))}
        <Button className="my-2" size="lg" onClick={onReconnect}>
          Reconnect account
        </Button>
        <Button
          onClick={() => setIsOpen(false)}
          size="lg"
          variant="tertiary-black"
        >
          Continue to {pageTitle}
        </Button>
      </Content>
    </Component>
  );
}
