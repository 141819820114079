import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { get, post } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import useUpdateCampaign from "./useUpdateCampaign";
import { Target } from "../types";

async function getTargetsRequest(
  accountId: number,
  campaignId: string,
): Promise<Target[]> {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}/targets`))
    .targets;
}

async function removeTargetsRequest(
  accountId: number,
  campaignId: string,
  targetUrns: string[],
): Promise<string[]> {
  return (
    await post(`accounts/${accountId}/campaigns/${campaignId}/targets/delete`, {
      target_urns: targetUrns,
    })
  ).targets;
}

export default function useTargets() {
  const queryClient = useQueryClient();
  const campaignId = useCampaignIdContext();
  const { account } = useSelectedSubscriptionContext();
  const { updateCachedCampaign } = useUpdateCampaign(account);
  const { campaignsKeys } = getQueryKeys(account);

  const { data: targets, isLoading: isLoadingTargets } = useQuery({
    queryKey: campaignsKeys.targets(campaignId),
    queryFn: () => getTargetsRequest(account.id, campaignId),
  });

  const { mutateAsync: removeTargets, isPending: isRemovingTargets } =
    useMutation({
      mutationFn: ({ targetUrns }: { targetUrns: string[] }) =>
        removeTargetsRequest(account.id, campaignId, targetUrns),
      onSuccess: (targetUrns) => {
        queryClient.setQueryData(
          campaignsKeys.targets(campaignId),
          (prevTargets: Target[]) => {
            const newTargets = prevTargets.filter(
              (prevTarget) => !targetUrns.includes(prevTarget.urn),
            );

            updateCachedCampaign(campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    targets,
    isLoadingTargets,
    removeTargets,
    isRemovingTargets,
  };
}
