import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import RenderIf from "common/components/RenderIf";
import Skeleton from "common/components/ui/Skeleton";

export default function CampaignInfoLoader() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const stateTag = <Skeleton className="h-6 w-12" />;
  const subtypeTag = <Skeleton className="h-6 w-20" />;

  return (
    <section className="mb-4 flex items-center rounded-2xl border border-black-200 px-3 py-4 md:px-4">
      <Skeleton className="size-12" />

      <ConditionalWrapper
        condition={!isTabletOrDesktop}
        renderWrapper={(children) => <div className="ml-3">{children}</div>}
      >
        <Skeleton className="h-[22px] w-40 md:mx-3" />

        <RenderIf condition={!isTabletOrDesktop}>
          <div className="mt-1 flex items-center gap-2">
            {subtypeTag} {stateTag}
          </div>
        </RenderIf>
      </ConditionalWrapper>

      <RenderIf condition={isTabletOrDesktop}>{subtypeTag}</RenderIf>

      <div className="flex flex-1 items-center justify-end gap-4">
        <RenderIf condition={isTabletOrDesktop}>{stateTag}</RenderIf>
        <span className="h-10 w-px bg-black-200" />
        <Skeleton className="size-10 rounded-full" />
      </div>
    </section>
  );
}
