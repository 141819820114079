import React from "react";

import Email from "./Email/Email";
import Password from "./Password/Password";
import Communication from "./Communication/Communication";
import UpdateName from "./UpdateName/UpdateName";
import AccountInfo from "./AccountInfo";

export default function Account() {
  return (
    <>
      <AccountInfo />

      <div className="w-full max-w-2xl">
        <UpdateName />
        <Email />
        <Password />
        <Communication />
      </div>
    </>
  );
}
