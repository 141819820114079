import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { Campaign, SubscriptionAccount } from "common/types";

import { CampaignUpdates, DetailedCampaign } from "../types";

export default function useUpdateCampaign(account: SubscriptionAccount) {
  const queryClient = useQueryClient();
  const { campaignsKeys } = getQueryKeys(account);

  function updateCachedCampaigns(
    updateCampaigns: (campaigns: Campaign[]) => Campaign[],
  ) {
    if (queryClient.getQueryData(campaignsKeys.list())) {
      queryClient.setQueryData(
        campaignsKeys.list(),
        (prevCampaigns: Campaign[]) =>
          produce(prevCampaigns, (draftState) => updateCampaigns(draftState)),
      );
    }
  }

  function updateCachedCampaign(
    campaignId: string,
    updateCampaign: (campaign: DetailedCampaign) => void,
  ) {
    if (queryClient.getQueryData(campaignsKeys.details(campaignId))) {
      queryClient.setQueryData(
        campaignsKeys.details(campaignId),
        (prevCampaign: DetailedCampaign) =>
          produce(prevCampaign, updateCampaign),
      );
    }
  }

  // Used for updating Campaign list when the single campaign changes
  function updateCachedCampaignInList(
    campaignId: string,
    updates: CampaignUpdates,
  ) {
    if (queryClient.getQueryData(campaignsKeys.list())) {
      queryClient.setQueryData(
        campaignsKeys.list(),
        (prevCampaigns: DetailedCampaign[]) =>
          produce(prevCampaigns, (draftCampaigns) => {
            const campaignIndex = draftCampaigns.findIndex(
              (campaign) => campaign.id === campaignId,
            );

            draftCampaigns[campaignIndex] = {
              ...draftCampaigns[campaignIndex],
              ...updates,
            };
          }),
      );
    }
  }

  return {
    updateCachedCampaign,
    updateCachedCampaigns,
    updateCachedCampaignInList,
  };
}
