import React from "react";
import {
  CaretLeft,
  UsersThree,
  CaretRight,
  Envelope,
} from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import { CampaignType } from "common/types";
import useCampaigns from "common/datahooks/useCampaigns";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useRelativeNavigate from "common/hooks/useRelativeNavigate";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";
import { CampaignSettingsSchema } from "common/schemas";

import ItemContent from "./ItemContent";
import PrimaryMethod from "./PrimaryMethod";
import useCampaignSettingsState from "./useCampaignSettingsState";
// Campaign imports
import useCampaignMutations from "../../../../pages/campaigns/datahooks/useCampaignMutations";

interface CampaignSettingsProps {
  type: CampaignType;
  goBack: () => void;
}

export default function CampaignSettings({
  type,
  goBack,
}: CampaignSettingsProps) {
  const isListCampaign = type === "list";
  const isGroupOrEvent = type === "group" || type === "event";

  const navigate = useRelativeNavigate();
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns");
  const { campaigns } = useCampaigns();
  const { createCampaign, isCreatingCampaign } = useCampaignMutations();
  const { settings, primaryMethod, setPrimaryMethod } =
    useCampaignSettingsState();

  function onSubmit({ name, size }: { name: string; size: number }) {
    createCampaign({
      name,
      type,
      audience_size: size,
      ...settings,
    }).then((campaign) => navigate(`campaigns/${campaign.id}`));
  }

  const defaultName = `${type.charAt(0).toUpperCase() + type.slice(1)} Campaign${campaigns ? ` #${campaigns.length + 1}` : ""}`;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ name: string; size: number }>({
    defaultValues: {
      name: defaultName,
      size: isListCampaign ? 1000 : 250,
    },
    resolver: valibotResolver(CampaignSettingsSchema),
  });

  return (
    <>
      <div className="-mx-4 mb-6 flex items-center justify-start gap-1 border-b border-b-black-200 px-2 pb-4">
        <Button intent="iconOnly" variant="quaternary-black" onClick={goBack}>
          <CaretLeft />
        </Button>
        <h2 className="text-headline-xl-bold">Create a new {type} campaign</h2>
      </div>
      <Label htmlFor="campaignName" className="mb-1">
        Campaign name
      </Label>
      <MaxLengthInput
        variant="lg"
        id="campaignName"
        placeholder="Campaign name"
        type="text"
        error={errors?.name?.message}
        characterLength={watch("name").length}
        maxLength={getSchemaMaxLength(CampaignSettingsSchema.entries.name)}
        {...register("name")}
      />
      <PrimaryMethod
        primaryMethod={primaryMethod}
        setPrimaryMethod={setPrimaryMethod}
        isGroupOrEvent={isGroupOrEvent}
      />

      <section className="flex items-center justify-between gap-6 border-b border-b-black-200 py-6">
        <ItemContent
          icon={Envelope}
          title={t(`flowTypes.INMAIL_OPTIMIZATION_Title`)}
          description={t(`flowTypes.INMAIL_OPTIMIZATION_Description`)}
          infoText={t(`flowTypes.INMAIL_OPTIMIZATION_InfoText`)}
          shouldDisplayTag
        />
      </section>

      {/* AudienceSize */}
      <RenderIf condition={!isListCampaign}>
        <section className="flex items-center justify-between gap-6 border-b border-b-black-200 py-6">
          <ItemContent
            icon={UsersThree}
            infoText={t("audienceSize.infoText")}
            title={t("audienceSize.title")}
            description={t("audienceSize.description")}
          />
          <div className="flex flex-col gap-1">
            <Label htmlFor="audienceSize" size="sm">
              Audience
            </Label>
            <Input
              variant={isTabletOrDesktop ? "md" : "lg"}
              className="max-w-32"
              id="audienceSize"
              name="audienceSize"
              error={errors?.size?.message}
              leftComponent={isTabletOrDesktop && <UsersThree weight="fill" />}
              {...register("size", {
                valueAsNumber: true,
              })}
            />
          </div>
        </section>
      </RenderIf>

      <Button
        className="mt-6 self-end"
        onClick={handleSubmit(onSubmit)}
        rightIcon={<CaretRight />}
        size={isTabletOrDesktop ? "lg" : "md"}
        isLoading={isCreatingCampaign}
      >
        Create campaign
      </Button>
    </>
  );
}
