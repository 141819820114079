import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { HTTPError, del, get, post } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { Targeting } from "../types";

async function getTargetingRequest(
  accountId: number,
  campaignId: string,
): Promise<Targeting> {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}/targeting`))
    .targeting;
}

async function setTargetingRequest(
  accountId: number,
  campaignId: string,
  targeting: Targeting,
): Promise<Targeting> {
  return (
    await post(`accounts/${accountId}/campaigns/${campaignId}/targeting`, {
      ...targeting,
    })
  ).targeting;
}

async function deleteTargetingRequest(accountId: number, campaignId: string) {
  await del(`accounts/${accountId}/campaigns/${campaignId}/targeting`);
}

export default function useTargeting() {
  const campaignId = useCampaignIdContext();
  const queryClient = useQueryClient();
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);

  const { data: targeting, isLoading: isLoadingTargeting } = useQuery({
    queryKey: campaignsKeys.targeting(campaignId),
    queryFn: () => getTargetingRequest(account.id, campaignId),
  });

  const { mutateAsync: setTargeting, isPending: isSettingTargeting } =
    useMutation({
      mutationFn: ({ campaignTargeting }: { campaignTargeting: Targeting }) =>
        setTargetingRequest(account.id, campaignId, campaignTargeting),
      onSuccess: (newTargeting) => {
        queryClient.setQueryData(
          campaignsKeys.targeting(campaignId),
          newTargeting,
        );
      },
    });

  const { mutateAsync: removeTargeting, isPending: isRemovingTargeting } =
    useMutation({
      mutationFn: () => deleteTargetingRequest(account.id, campaignId),
      onSuccess: () => {
        queryClient.setQueryData(campaignsKeys.targeting(campaignId), null);
        queryClient.setQueryData(campaignsKeys.targets(campaignId), []);
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  return {
    targeting,
    isLoadingTargeting,
    setTargeting,
    isSettingTargeting,
    removeTargeting,
    isRemovingTargeting,
  };
}
