import { useState } from "react";

import { Campaign, CampaignState } from "common/types";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

export default function useCampaignsTable(campaigns: Campaign[]) {
  const [searchText, setSearchText] = useState("");
  const [campaignState, setCampaignState] = useState<CampaignState>(null);

  const selectedSubscription = useSelectedSubscriptionContext();

  const filteredCampaigns = campaigns
    ? campaigns.filter(
        ({ name, state }) =>
          name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) &&
          (selectedSubscription.role === "member" ? state !== "INIT" : true) &&
          (campaignState ? state === campaignState : true),
      )
    : [];

  function clearFilters() {
    setSearchText("");
    setCampaignState(null);
  }

  return {
    searchText,
    setSearchText,
    campaignState,
    setCampaignState,
    filteredCampaigns,
    hasFilters: !!searchText || !!campaignState,
    clearFilters,
  };
}
