import React, { useEffect, useRef, useState } from "react";
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper";
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup";

import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";

export default function CreditCardInput({
  getTokenFunctionRef,
}: {
  getTokenFunctionRef: React.MutableRefObject<() => Promise<string>>;
}) {
  const cardRef = useRef<ChargebeeComponents>();
  const [errorsState, setErrorsState] = useState({
    number: null,
    expiry: null,
    cvv: null,
    name: null,
  });
  const [nameOnCard, setNameOnCard] = useState<string>();

  async function getToken(): Promise<string> {
    if (!nameOnCard) {
      setErrorsState((prevState) => ({
        ...prevState,
        name: "Card holder's name is required",
      }));
    }
    const { token } = await cardRef.current.tokenize({
      firstName: nameOnCard,
    });
    return token;
  }

  useEffect(() => {
    getTokenFunctionRef.current = getToken;
  }, [nameOnCard]);

  function onChange(state: ChargebeeCurrentState) {
    setErrorsState((prevState) => ({
      ...prevState,
      [state.field]: state.error?.message,
    }));
  }

  return (
    <CardComponent
      ref={cardRef}
      classes={{ invalid: "invalid-card-input" }}
      fonts={[{ fontFamily: "", src: "" }]}
      // @ts-expect-error: the chargebee type definitions are wrong
      onChange={onChange}
    >
      <div className="flex flex-col gap-4 py-6">
        <div className="flex flex-col gap-1">
          <Label htmlFor="cardHolder" size="md">
            Card Holder’s Name
          </Label>

          <Input
            id="cardHolder"
            placeholder="Example: John Doe"
            variant="md"
            value={nameOnCard}
            error={errorsState.name}
            onChange={(e) => setNameOnCard(e.target.value)}
            onBlur={(e) => {
              setErrorsState((prevState) => ({
                ...prevState,
                name: e.target.value.trim()
                  ? null
                  : "Card holder's name is required",
              }));
            }}
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label htmlFor="cardNumber" size="md">
            Card Number
          </Label>

          <CardNumber className="chargebee-input-field" />

          {errorsState.number && (
            <span className="text-caption-12-regular text-red-500">
              {errorsState.number}
            </span>
          )}
        </div>

        <div className="flex flex-row justify-start gap-4">
          <div className="flex w-1/2 flex-col">
            <Label htmlFor="expiryDate" size="md">
              Expiry Date
            </Label>

            <CardExpiry className="chargebee-input-field" />

            {errorsState.expiry && (
              <span className="text-caption-12-regular text-red-500">
                {errorsState.expiry}
              </span>
            )}
          </div>

          <div className="flex w-1/2 flex-col">
            <Label htmlFor="cvc" size="md">
              CVC
            </Label>

            <CardCVV className="chargebee-input-field" />

            {errorsState.cvv && (
              <span className="text-caption-12-regular text-red-500">
                {errorsState.cvv}
              </span>
            )}
          </div>
        </div>
      </div>
    </CardComponent>
  );
}
