import React, { useState } from "react";
import { CreditCard, Plus } from "@phosphor-icons/react";

import useSubscriptions from "common/datahooks/useSubscriptions";
import { PaymentSource, Subscription } from "common/types";
import usePaymentSources from "common/datahooks/usePaymentSources";
import { Button } from "common/components/ui/Button";
import AddSubscription from "common/components/Layout/AddSubscription";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import DesktopSubscriptions from "./DesktopSubscriptions";
import MobileSubscriptions from "./MobileSubscriptions";

export default function Subscriptions() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const [isAddSubscriptionOpen, setIsAddSubscriptionOpen] = useState(false);
  const { subscriptions } = useSubscriptions();
  const { paymentSources, isLoadingPaymentSources } = usePaymentSources({
    shouldIncludeSubscriptionId: true,
  });

  const Component = isTabletOrDesktop
    ? DesktopSubscriptions
    : MobileSubscriptions;
  const subscriptionsWithPaymentSources: (Subscription & {
    paymentSource: PaymentSource;
  })[] = [];

  subscriptions.forEach((subscription) => {
    let paymentSource;

    if (paymentSources) {
      paymentSource = paymentSources.find(
        ({ subscription_id }) => subscription_id === subscription.id,
      );
    }
    subscriptionsWithPaymentSources.push({ ...subscription, paymentSource });
  });

  function getWorkspacesAmount(amount: number): string {
    return `${amount} workspace${amount > 1 ? "s" : ""}`;
  }

  return (
    <>
      <div className="my-2 flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4 py-2">
          <div className="flex size-12  items-center justify-center rounded-full bg-black-900">
            <CreditCard color="white" weight="fill" size={24} />
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Manage Workspaces</span>

            <span className="text-caption-12-regular text-black-500">
              {getWorkspacesAmount(subscriptions.length)}
            </span>
          </div>
        </div>

        <Button
          variant="secondary-purple"
          leftIcon={<Plus />}
          size="md"
          onClick={() => setIsAddSubscriptionOpen(true)}
        >
          {isTabletOrDesktop ? "Add subscription" : "Add"}
        </Button>
      </div>

      <Component
        subscriptions={subscriptionsWithPaymentSources}
        isLoading={isLoadingPaymentSources}
      />

      <AddSubscription
        isOpen={isAddSubscriptionOpen}
        onClose={() => setIsAddSubscriptionOpen(false)}
      />
    </>
  );
}
