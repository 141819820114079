import React from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";
import { Button } from "common/components/ui/Button";

import { Target } from "../../../../types";

interface PreviewHeaderProps {
  selectedTarget: Target;
  currentTarget: number;
  targetsLength: number;
  isLoading: boolean;
  handleNextClick: () => void;
  handlePrevClick: () => void;
}

export default function PreviewHeader({
  selectedTarget,
  currentTarget,
  targetsLength,
  isLoading,
  handleNextClick,
  handlePrevClick,
}: PreviewHeaderProps) {
  return (
    <div className="flex gap-2 border-b border-b-black-200 bg-white p-3">
      <div className="flex flex-1 items-center gap-2">
        {isLoading ? (
          <Skeleton className="size-8" />
        ) : (
          <ProfileImage size="sm" src={selectedTarget.picture_url} />
        )}

        <div className="flex flex-col gap-0.5">
          {isLoading ? (
            <>
              <Skeleton className="h-[22px] w-20" />
              <Skeleton className="h-4 w-24" />
            </>
          ) : (
            <>
              <h5 className="line-clamp-1 break-all text-button-16">
                {selectedTarget.full_name}
              </h5>
              <p className="line-clamp-1 text-caption-12-regular text-black-700">
                {selectedTarget.occupation}
              </p>
            </>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button
          size="xs"
          intent="iconOnly"
          onClick={handlePrevClick}
          disabled={isLoading}
        >
          <CaretLeft />
        </Button>

        {isLoading ? (
          <Skeleton className="h-6 w-5" />
        ) : (
          <p className="text-nowrap">
            {currentTarget + 1}
            <span className="text-black-400">/{targetsLength}</span>
          </p>
        )}

        <Button
          size="xs"
          intent="iconOnly"
          onClick={handleNextClick}
          disabled={isLoading}
        >
          <CaretRight />
        </Button>
      </div>
    </div>
  );
}
