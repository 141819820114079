import React, { Dispatch, SetStateAction, useState } from "react";

import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import PaymentMethodSelection from "common/components/PaymentMethodSelection";
import AddPaymentMethod from "common/components/AddPaymentMethod";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";

interface UpdatePaymentMethodProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function UpdatePaymentMethod({
  isOpen,
  setIsOpen,
}: UpdatePaymentMethodProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const { setPrimaryPaymentSource, isSettingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  const content = isAddingPayment ? (
    <AddPaymentMethod onBack={() => setIsAddingPayment(false)} />
  ) : (
    <>
      <PaymentMethodSelection
        onAddPaymentMethod={() => setIsAddingPayment(true)}
        onSelectPaymentSource={(paymentSource) =>
          setPrimaryPaymentSource({ paymentSource })
        }
      />
      <Button
        className="mt-4"
        onClick={() => setIsOpen(false)}
        isLoading={isSettingPrimaryPaymentSource}
      >
        Done
      </Button>
    </>
  );

  return (
    <Component
      open={isOpen}
      onOpenChange={setIsOpen}
      onAfterClose={() => setIsAddingPayment(false)}
    >
      <Content className="md:max-w-[540px]">{content}</Content>
    </Component>
  );
}
