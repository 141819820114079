import { useState } from "react";

import {
  LinkedinProfile,
  GenericSearchParam,
  SearchParamWithImage,
} from "common/types";

// this hook is used to merge selected search parameter values with suggestions & search results
export default function useMergeSearchOptions<
  T extends GenericSearchParam | LinkedinProfile | SearchParamWithImage,
>() {
  const [allOptions, setAllOptions] = useState<Array<T>>([]);

  function mergeOptions(
    appliedSearchParamValues: Array<T>,
    searchResults: Array<T>,
    suggestedOptions: Array<T>,
  ) {
    let mergedOptions;

    if (searchResults) {
      mergedOptions = [
        ...appliedSearchParamValues,
        ...searchResults.filter(
          (option) =>
            !appliedSearchParamValues.find((item) => item.urn === option.urn),
        ),
      ];
    } else {
      mergedOptions = [
        ...appliedSearchParamValues,
        ...suggestedOptions.filter(
          (option) =>
            !appliedSearchParamValues.find((item) => item.urn === option.urn),
        ),
      ];
    }
    setAllOptions(mergedOptions);
  }

  return { mergeOptions, allOptions };
}
