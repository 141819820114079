import React from "react";
import * as Sentry from "@sentry/react";
import { Lock } from "@phosphor-icons/react";
import { UseFormReturn } from "react-hook-form";

import useGTM from "common/hooks/useGTM";
import { Subscription } from "common/types";
import { HTTPError } from "common/helpers/HTTP";
import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { LoginSchemaType } from "common/schemas";
import LinkedInAccountCard from "common/components/Layout/LinkedInAccountCard";
import RenderIf from "common/components/RenderIf";
import useDebounce from "common/hooks/useDebounce";

import useLinkedInAccounts from "../../../datahooks/useLinkedInAccounts";

interface LoginProps {
  formReturn: UseFormReturn<LoginSchemaType>;
  subscription: Subscription;
  onSuccess: () => void;
  setStep: (step: "thiswasme" | "pin") => void;
}

export default function Login({
  formReturn: {
    setError,
    register,
    formState: { errors },
    handleSubmit,
  },
  subscription,
  onSuccess,
  setStep,
}: LoginProps) {
  const { sendEvent } = useGTM();
  const { login, isLoggingIn } = useLinkedInAccounts();
  const shouldShowDelayMessage = useDebounce(isLoggingIn, 7000);

  function onSubmit(loginData: LoginSchemaType) {
    login({ subscriptionId: subscription.id, loginData })
      .then((response) => {
        if (response.loginResult === "PASS") {
          onSuccess();
        } else if (response.loginResult === "CHALLENGE") {
          const { challengeType } = response;
          if (
            [
              "EmailPinChallenge",
              "SMSPinChallenge",
              "AuthenticatorAppChallenge",
            ].includes(challengeType)
          ) {
            Sentry.captureMessage("Login challenge");
            setStep("pin");
          } else if (challengeType === "CaptchaChallenge") {
            Sentry.captureMessage("Login - Captcha challenge");
            setError("password", {
              message: "Captcha challenge",
            });
          } else if (challengeType === "AppChallenge") {
            setStep("thiswasme");
          }
        }
      })
      .catch((err) => {
        Sentry.setExtra("error", err);
        Sentry.setExtra("error_json", JSON.stringify(err));
        Sentry.captureMessage(
          `Login error${err instanceof HTTPError ? ` - ${err.type}` : ""}`,
        );

        sendEvent("connect_linkedin_account_fail");
        switch (err.code) {
          // this happens when the account is already connected to another subscription
          case 409:
            setError("password", {
              message: "Account already connected to another subscription",
            });
            break;
          case 401:
            setError("password", {
              message: "Invalid email or password",
            });
            break;
          default:
            setError("password", {
              message: "Error connecting account",
            });
        }
      });
  }

  return (
    <form className="flex flex-col pt-4" onSubmit={handleSubmit(onSubmit)}>
      {subscription.account.email ? (
        <LinkedInAccountCard account={subscription.account} />
      ) : (
        <>
          <Label className="mb-1 ml-0.5" size="sm" htmlFor="email">
            Email address
          </Label>
          <Input
            id="email"
            placeholder="Email"
            error={errors.email?.message}
            {...register("email")}
          />
        </>
      )}
      <Label className="mb-1 ml-0.5 mt-8 md:mt-4" size="sm" htmlFor="password">
        Password
      </Label>
      <Input
        id="password"
        error={errors.password?.message}
        type="password"
        {...register("password")}
        placeholder="Write your password here"
      />

      {/* Long Loading text */}
      <RenderIf condition={isLoggingIn && shouldShowDelayMessage}>
        <span className="mt-1 text-caption-12-regular text-black-500 animate-in fade-in-0">
          The login process is taking some time, please do not close or refresh
          this page
        </span>
      </RenderIf>

      <Button
        className="mb-3 mt-8 md:mt-12"
        variant="primary-black"
        type="submit"
        isLoading={isLoggingIn}
      >
        Connect Account
      </Button>
      <div className="flex items-center justify-center gap-x-0.5">
        <Lock weight="fill" className="size-4 text-black-700" />
        <span className="text-caption-12-regular text-black-700">
          Secured with TLS 1.3 encryption
        </span>
      </div>
    </form>
  );
}
