import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import useUpdateConversation from "./useUpdateConversation";
import { ConversationMessage, ConversationParticipant } from "../types";

async function sendMessageRequest(
  accountId: number,
  message: string,
  recipients: ConversationParticipant[],
) {
  const {
    conversation_urn: conversationUrn,
    message_urn: messageUrn,
    created_at: createdAt,
  } = await post(`accounts/${accountId}/conversations`, {
    message: message.trim(),
    recipients: recipients.map(({ id }) => id),
  });
  return {
    message,
    createdAt,
    recipients,
    conversationUrn,
    messageUrn,
  };
}
async function resetUnreadMessagesRequest(
  accountId: number,
  conversationUrn: string,
) {
  await post(`accounts/${accountId}/conversations/${conversationUrn}/read`);
  return conversationUrn;
}

export default function useConversationUpdates() {
  const queryClient = useQueryClient();
  const selectedSubscription = useSelectedSubscriptionContext();
  const { account } = selectedSubscription;
  const { updateConversation, addMessage } = useUpdateConversation(account);

  const { leadsKeys } = getQueryKeys(account);

  const { mutateAsync: sendMessage, isPending: isSendingMessage } = useMutation(
    {
      mutationFn: ({
        message,
        recipients,
      }: {
        message: string;
        recipients: ConversationParticipant[];
      }) => sendMessageRequest(account?.id, message, recipients),
      onSuccess: ({
        message,
        createdAt,
        recipients,
        conversationUrn: returnedConversationUrn,
        messageUrn,
      }) => {
        const newMessage: ConversationMessage = {
          urn: messageUrn,
          body: message,
          created_at: createdAt,
          sender: selectedSubscription.account,
          type: "MEMBER_TO_MEMBER",
        };

        updateConversation(
          returnedConversationUrn,
          (draftConversation) => {
            draftConversation.last_message = newMessage;
            // this is for conversations that are new in the cache
            if (!draftConversation.participants) {
              draftConversation.participants = recipients;
            }
            if (!draftConversation.last_activity_at) {
              draftConversation.last_activity_at = createdAt;
            }
          },
          true,
        );
        addMessage(returnedConversationUrn, newMessage);

        // for conversations with leads that don't have a conversationUrn yet
        if (
          recipients.length === 1 &&
          !queryClient.getQueryData(leadsKeys.conversationUrn(recipients[0].id))
        ) {
          queryClient.setQueryData(
            leadsKeys.conversationUrn(recipients[0].id),
            returnedConversationUrn,
          );
        }
      },
      onError: () => {
        toast.error("Unable to send a message, please try again later");
      },
    },
  );
  const { mutateAsync: resetUnreadMessages } = useMutation({
    mutationFn: ({ conversationUrn }: { conversationUrn: string }) =>
      resetUnreadMessagesRequest(account?.id, conversationUrn),
    onSuccess: (conversationUrn) => {
      updateConversation(conversationUrn, (draftConversation) => {
        draftConversation.unread_count = 0;
      });
    },
  });
  return { sendMessage, isSendingMessage, resetUnreadMessages };
}
