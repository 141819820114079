import React from "react";
import clsx from "clsx";

import { getMonthName } from "common/helpers/utils";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import Skeleton from "common/components/ui/Skeleton";

import DownloadInvoice from "./DownloadInvoice";
import { BILLING_STATUSES } from "../../constants";
import { Invoice } from "../../types";

export default function DesktopTable({
  invoices,
  isLoading,
}: {
  invoices: Invoice[];
  isLoading: boolean;
}) {
  const getInvoiceDate = (date: number) => {
    const currentDate = new Date(date * 1000);
    return `${currentDate.getDate()} ${getMonthName(currentDate.getMonth())}`;
  };

  return (
    <Table variant="simple">
      <TableHeader>
        <tr>
          <TableHead>Month</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Plan</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Amount</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody>
        {(isLoading ? new Array<Invoice>(3).fill(null) : invoices).map(
          (invoice, index) => (
            <TableRow key={invoice?.id || index}>
              <TableCell>
                <div className="flex flex-row gap-4 py-4">
                  {isLoading ? (
                    <>
                      <Skeleton className="h-[18px] w-4 rounded" />
                      <Skeleton className="h-[18px] w-32 rounded-lg" />
                    </>
                  ) : (
                    <span className="text-caption-12-bold text-black-700">
                      {getMonthName(new Date(invoice.date * 1000).getMonth())}{" "}
                      {new Date(invoice.date * 1000).getFullYear()}
                    </span>
                  )}
                </div>
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-16 rounded-lg" />
                ) : (
                  <div className="flex items-center justify-center gap-2.5">
                    <div
                      className={clsx(
                        "size-1.5 rounded-full",
                        BILLING_STATUSES[invoice.status].bgClassname,
                      )}
                    />
                    <span className="text-caption-12-bold">
                      {BILLING_STATUSES[invoice.status].label}
                    </span>
                  </div>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-44 rounded-lg" />
                ) : (
                  <span className="text-caption-12-regular text-black-700">
                    {invoice.line_items[0].description}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-32 rounded-lg" />
                ) : (
                  <span className="text-caption-12-regular text-black-700">
                    {getInvoiceDate(invoice.date)}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-10 rounded-lg" />
                ) : (
                  <span className="text-caption-12-regular text-black-700">
                    ${invoice.total / 100}
                  </span>
                )}
              </TableCell>

              <TableCell className="text-end">
                <div className="flex items-center justify-end">
                  {isLoading ? (
                    <Skeleton className="size-[18px] rounded" />
                  ) : (
                    <DownloadInvoice invoiceId={invoice.id} />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ),
        )}
      </tbody>
    </Table>
  );
}
