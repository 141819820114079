import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { LinkedInGroup } from "../types";

async function getUserGroupsRequest(
  accountId: number,
): Promise<LinkedInGroup[]> {
  return (await get(`accounts/${accountId}/groups`)).groups;
}

async function getLinkedinGroupRequest(
  accountId: number,
  groupId: string,
): Promise<LinkedInGroup> {
  return (await get(`accounts/${accountId}/groups/${groupId}`)).group;
}

export default function useLinkedinGroups({
  groupId,
  disableQuery,
}: {
  groupId?: string;
  disableQuery?: boolean;
} = {}) {
  const { account } = useSelectedSubscriptionContext();

  const { data: linkedinGroups, isLoading: isLoadingLinkedinGroups } = useQuery(
    {
      queryKey: ["linkedinGroups", account.id],
      queryFn: () => getUserGroupsRequest(account.id),
      enabled: !groupId && !disableQuery,
    },
  );

  const { data: linkedinGroup, isLoading: isLoadingLinkedinGroup } = useQuery({
    queryKey: ["linkedinGroups", account.id, groupId],
    queryFn: () => getLinkedinGroupRequest(account.id, groupId),
    enabled: !!groupId,
  });

  return {
    linkedinGroups,
    isLoadingLinkedinGroups,
    linkedinGroup,
    isLoadingLinkedinGroup,
  };
}
