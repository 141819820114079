import React, { useMemo, useState } from "react";

import { Tag } from "common/components/ui/Tag";
import RestrictedComponent from "common/components/RestrictedComponent";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import useAccountRestriction from "common/hooks/useAccountRestriction";
import useAppStore from "common/hooks/useAppStore";
import { Button } from "common/components/ui/Button";
import SearchInput from "common/components/SearchInput";

import useBlacklist from "../../datahooks/useBlacklist";
import BlacklistList from "./BlacklistList";
import AddAccountDialog from "./AddAccountDialog";

import EmptyStateImage from "assets/images/empty-placeholders/blacklist.png";

export default function Blacklist() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { isRestricted } = useAccountRestriction();
  const { blacklist, isLoadingBlacklist, blacklistError, refetchBlacklist } =
    useBlacklist();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredBlacklist = useMemo(() => {
    if (!searchTerm) {
      return blacklist;
    }

    return blacklist.filter((profile) =>
      profile.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [blacklist, searchTerm]);

  if (blacklistError) {
    return (
      <div className="mt-11 md:mt-20">
        <ErrorPlaceholder
          onRetry={refetchBlacklist}
          errorMessage="Could not display Blacklist"
        />
      </div>
    );
  }

  if (!blacklist?.length && !isLoadingBlacklist) {
    return (
      <EmptyPlaceholder
        imageSrc={EmptyStateImage}
        title="No blacklist accounts added"
        subtitle="Blacklisted accounts cannot be enrolled in an Aimfox campaign, and will never be contacted in any way"
        actionComponent={
          isRestricted ? (
            <Button
              onClick={() => useAppStore.getState().setIsShowingLogin(true)}
            >
              Reconnect Account
            </Button>
          ) : (
            <RestrictedComponent
              hiddenForRoles={["member"]}
              disabledForRoles={[]}
            >
              <AddAccountDialog />
            </RestrictedComponent>
          )
        }
      />
    );
  }

  return (
    <>
      <section className="mt-2 flex flex-col justify-between gap-y-4 py-4 md:flex-row md:gap-0">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2">
            <h3 className="text-body-16-bold">Accounts</h3>

            <Tag variant="danger">
              {isLoadingBlacklist ? 0 : filteredBlacklist.length}
            </Tag>
          </div>

          <RenderIf condition={!isTabletOrDesktop}>
            <RestrictedComponent
              hiddenForRoles={["member"]}
              disabledForRoles={[]}
            >
              <AddAccountDialog />
            </RestrictedComponent>
          </RenderIf>
        </div>

        <div className="flex flex-row gap-4">
          <SearchInput
            className="w-full md:w-80"
            placeholder="Search accounts"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClear={() => setSearchTerm("")}
          />

          <RenderIf condition={isTabletOrDesktop}>
            <RestrictedComponent
              hiddenForRoles={["member"]}
              disabledForRoles={[]}
            >
              <AddAccountDialog />
            </RestrictedComponent>
          </RenderIf>
        </div>
      </section>

      <BlacklistList
        isLoading={isLoadingBlacklist}
        profiles={filteredBlacklist}
        searchTerm={searchTerm}
      />
    </>
  );
}
