import { useQuery } from "@tanstack/react-query";

import { get } from "../helpers/HTTP";
import usePaymentPlans from "./usePaymentPlans";
import { Subscription } from "../types";

async function getSubscriptions() {
  return (await get(`subscriptions`)).subscriptions;
}

export default function useSubscriptions({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const { plans } = usePaymentPlans({ disableQuery });
  const { data: subscriptions, refetch } = useQuery<Subscription[]>({
    queryKey: ["subscriptions"],
    queryFn: getSubscriptions,
    enabled: !disableQuery,
    refetchOnMount: false,
  });

  let modifiedSubscriptions;

  if (subscriptions && plans) {
    modifiedSubscriptions = subscriptions.map((subscription) => {
      for (let i = 0; i < plans.length; i += 1) {
        for (let j = 0; j < plans[i].prices.length; j += 1) {
          if (plans[i].prices[j].id === subscription.pricing_id) {
            return {
              ...subscription,
              plan: { ...plans[i], pricing: plans[i].prices[j] },
            };
          }
        }
      }
      return subscription;
    });
  }

  return {
    subscriptions: modifiedSubscriptions,
    refetchSubscriptions: refetch,
  };
}
