import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import useAppStore from "common/hooks/useAppStore";

import { post, get } from "../helpers/HTTP";
import { LinkedinProfile, Subscription } from "../types";

async function loginRequest(
  subscriptionId: string,
  loginData: { email: string; password: string },
): Promise<
  | {
      loginResult: "PASS";
      profile: LinkedinProfile;
    }
  | {
      loginResult: "CHALLENGE";
      challengeType:
        | "EmailPinChallenge"
        | "SMSPinChallenge"
        | "AuthenticatorAppChallenge"
        | "CaptchaChallenge"
        | "AppChallenge";
    }
> {
  const {
    login_result: loginResult,
    challenge_type: challengeType,
    profile,
  } = await post(`subscriptions/${subscriptionId}/account/login`, {
    ...loginData,
    debug: useAppStore.getState().debugOptions.shouldDebugLogin,
  });
  return { loginResult, challengeType, profile };
}
async function findAccountRequest(
  emailOrUrl: string,
): Promise<LinkedinProfile> {
  return (await get(`accounts/find?param=${emailOrUrl}`)).account;
}
async function resolveChallengeRequest(
  subscriptionId: string,
  email: string,
  pin: string,
): Promise<{
  loginResult: "PASS";
}> {
  const { login_result: loginResult } = await post(
    `subscriptions/${subscriptionId}/account/challenge/${email}/resolve`,
    { pin },
  );
  return { loginResult };
}
async function resendChallengeRequest(subscriptionId: string, email: string) {
  await post(
    `subscriptions/${subscriptionId}/account/challenge/${email}/resend`,
  );
}
export default function useLinkedInAccounts() {
  const queryClient = useQueryClient();

  function updateAccountState(subscriptionId: string) {
    queryClient.setQueryData<Subscription[]>(
      ["subscriptions"],
      (subscriptions) =>
        produce(subscriptions, (draftSubscriptions) => {
          for (let i = 0; i < draftSubscriptions.length; i += 1) {
            if (draftSubscriptions[i].id === subscriptionId) {
              draftSubscriptions[i].account.state = "LoggedIn";
              return;
            }
          }
        }),
    );
  }

  const { mutateAsync: login, isPending: isLoggingIn } = useMutation({
    mutationFn: ({
      subscriptionId,
      loginData,
    }: {
      subscriptionId: string;
      loginData: { email: string; password: string };
    }) => loginRequest(subscriptionId, loginData),
    onSuccess: ({ loginResult }, { subscriptionId }) => {
      if (loginResult === "PASS") {
        updateAccountState(subscriptionId);
      }
    },
  });

  const { mutateAsync: findAccount, isPending: isFindingAccount } = useMutation(
    {
      mutationFn: ({ emailOrUrl }: { emailOrUrl: string }) =>
        findAccountRequest(emailOrUrl),
    },
  );

  const { mutateAsync: resolveChallenge, isPending: isResolvingChallenge } =
    useMutation({
      mutationFn: ({
        subscriptionId,
        email,
        pin,
      }: {
        subscriptionId: string;
        email: string;
        pin: string;
      }) => resolveChallengeRequest(subscriptionId, email, pin),
      onSuccess: ({ loginResult }, { subscriptionId }) => {
        if (loginResult === "PASS") {
          updateAccountState(subscriptionId);
        }
      },
    });

  const { mutateAsync: resendChallenge, isPending: isResendingChallenge } =
    useMutation({
      mutationFn: ({
        subscriptionId,
        email,
      }: {
        subscriptionId: string;
        email: string;
      }) => resendChallengeRequest(subscriptionId, email),
    });

  return {
    login,
    isLoggingIn,
    findAccount,
    isFindingAccount,
    resolveChallenge,
    isResolvingChallenge,
    resendChallenge,
    isResendingChallenge,
  };
}
