import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";

import { Accordion } from "common/components/ui/Accordion";

import {
  FacetParam,
  Facets,
  LeadsSearchParams,
  SelectedFacetParam,
} from "../../types";
import SearchFacet from "./SearchFacet";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import { leadsFacets } from "../../constants";

export default function SearchFilters({
  facets,
  isLoading,
  setSearchParams,
  searchParams,
}: {
  facets: Facets;
  isLoading: boolean;
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void;
}) {
  const [accordionValue, setAccordionValue] = useState(
    // tests don't work because all the accordions are closed by default. This opens all the accordions in test environment.
    import.meta.env.MODE === "test"
      ? leadsFacets.map(({ label }) => label)
      : [leadsFacets[0].label],
  );
  const { toggleSearchParam, resetSearchParam } = useLeadsSearchParams(
    searchParams,
    setSearchParams,
  );

  useEffect(() => {
    setSearchParams(searchParams);
  }, [searchParams]);

  // we merge facets that have the same location name but different ids
  const mergedFacets = useMemo(
    () =>
      produce(facets, (draftState) => {
        if (draftState) {
          draftState.locations = draftState.locations.reduce(
            (accumulator, currentValue, index) => {
              const groupedFacet = currentValue;
              for (let i = 0; i < draftState.locations.length; i += 1) {
                if (
                  groupedFacet.name === draftState.locations[i].name &&
                  i !== index
                ) {
                  groupedFacet.target_count +=
                    draftState.locations[i].target_count;

                  if (typeof groupedFacet.id === "string") {
                    groupedFacet.id = [
                      groupedFacet.id,
                      draftState.locations[i].id as string,
                    ];
                  } else {
                    groupedFacet.id = [
                      ...(groupedFacet.id as string[]),
                      draftState.locations[i].id as string,
                    ];
                  }
                  draftState.locations.splice(i, 1);
                  i -= 1;
                }
              }
              accumulator.push(groupedFacet);
              return accumulator;
            },
            [],
          );
        }
      }),
    [facets],
  );

  function toggleAccordion(value: string) {
    if (accordionValue.includes(value)) {
      setAccordionValue(accordionValue.filter((item) => item !== value));
    } else {
      setAccordionValue([...accordionValue, value]);
    }
  }

  return (
    <Accordion type="multiple" value={accordionValue}>
      {leadsFacets.map(({ searchKey, facetKey, label }) => (
        <SearchFacet
          toggleAccordion={() => toggleAccordion(label)}
          key={searchKey}
          label={label}
          allFacets={mergedFacets ? mergedFacets[facetKey || searchKey] : []}
          selectedFacets={searchParams[searchKey]}
          toggleSearchParam={(param: FacetParam) =>
            toggleSearchParam(searchKey, param)
          }
          resetFacet={() => resetSearchParam(searchKey)}
          isLoading={isLoading}
        />
      ))}
    </Accordion>
  );
}
