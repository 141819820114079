import React, { useState } from "react";
import { EyedropperSample, Plus } from "@phosphor-icons/react";
import { clsx } from "clsx";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { LabelSchema, LabelSchemaType } from "common/schemas";
import useAccountLabels from "common/datahooks/useAccountLabel";
import { tagVariants } from "common/components/ui/Tag";
import MaxLengthInput from "common/components/MaxLengthInput";
import { cn, getSchemaMaxLength } from "common/helpers/utils";
import useLeadLabels from "common/datahooks/useLeadLabels";

const colors: LabelSchemaType["color"][] = [
  "quaternary",
  "secondary",
  "danger",
  "yellow",
  "info",
  "success",
];

export default function AddLabel({ profileId }: { profileId: string }) {
  const [isAddingLabel, setIsAddingLabel] = useState(false);
  const { addLeadLabel, isAddingLeadLabel } = useLeadLabels();
  const { addAccountLabel, isAddingAccountLabel } = useAccountLabels();
  const { register, handleSubmit, reset, watch, getValues, setValue } =
    useForm<LabelSchemaType>({
      defaultValues: { name: "", color: colors[0] },
      resolver: valibotResolver(LabelSchema),
    });
  function onSubmit(data: LabelSchemaType) {
    addAccountLabel(data).then((label) => {
      reset({ ...getValues(), name: "" });
      addLeadLabel({ label, profileId });
    });
  }

  const color = watch("color");
  const name = watch("name");

  return (
    <>
      {isAddingLabel && (
        <>
          <span className="mb-1 text-body-14-bold text-black-700">
            Create new tag
          </span>
          <div className="mb-4 flex gap-x-1 lg:mb-6">
            <MaxLengthInput
              {...register("name")}
              maxLength={getSchemaMaxLength(LabelSchema.entries.name)}
              characterLength={name.length}
              className="grow"
              placeholder="Tag name"
            />
            <Popover>
              <PopoverTrigger className="flex size-10 rounded-xl border border-black-400 p-1">
                <div
                  className={cn(
                    tagVariants({
                      variant: color,
                    }),
                    "size-full rounded-lg",
                  )}
                >
                  <EyedropperSample size={20} />
                </div>
              </PopoverTrigger>
              <PopoverContent className="z-aboveDialog flex w-fit gap-x-1 p-2 text-purple-500">
                {colors.map((variant) => (
                  <button
                    key={variant}
                    aria-label={variant}
                    className={clsx(
                      "size-8 rounded-lg border  p-1",
                      variant === color
                        ? "border-black-400"
                        : "border-transparent",
                    )}
                    type="button"
                    onClick={() => setValue("color", variant)}
                  >
                    <div
                      className={cn(
                        tagVariants({
                          variant,
                        }),
                        "rounded size-full min-w-0 p-0",
                      )}
                    />
                  </button>
                ))}
              </PopoverContent>
            </Popover>
          </div>
        </>
      )}
      <Button
        leftIcon={<Plus />}
        isLoading={isAddingAccountLabel || isAddingLeadLabel}
        onClick={() =>
          isAddingLabel ? handleSubmit(onSubmit)() : setIsAddingLabel(true)
        }
      >
        Create Tag
      </Button>
    </>
  );
}
