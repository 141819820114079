import useUnreadMessagesCount from "common/datahooks/useUnreadMessagesCount";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import useNotifications from "common/datahooks/useNotifications";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { NavItemType } from "common/components/Layout";

import { getBaseNavItems } from "../../pages/routes";

export default function useNavItems(isNavDisabled: boolean) {
  const isDesktop = useTwBreakpoint("lg");
  const { unreadMessagesCount } = useUnreadMessagesCount();
  const { unseenCount } = useNotifications();
  const selectedSubscription = useSelectedSubscriptionContext();

  const navItems: NavItemType[] = [];

  getBaseNavItems(isDesktop).forEach((baseNavItem, index) => {
    const navItem: NavItemType = {
      ...baseNavItem,
      isDisabled:
        (isNavDisabled && isDesktop) ||
        ((!selectedSubscription.account ||
          selectedSubscription.account.state === "NeverLoggedIn") &&
          index !== 0 &&
          index !== 5),
    };

    if (navItem.path === "inbox") {
      navItem.notifications = unreadMessagesCount;
    } else if (navItem.path === "account/notifications" && !isDesktop) {
      navItem.notifications = !!unseenCount;
    }
    navItems.push(navItem);
  });
  return navItems;
}
