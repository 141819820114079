import { useQuery } from "@tanstack/react-query";

import { get } from "../helpers/HTTP";
import { SubscriptionPlan } from "../types";

async function getPlans() {
  return (await get(`billing/plans`)).plans;
}

export default function usePaymentPlans({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const { data: plans, isLoading: isLoadingPlans } = useQuery<
    SubscriptionPlan[]
  >({
    queryKey: ["plans"],
    queryFn: getPlans,
    refetchOnMount: false,
    enabled: !disableQuery,
  });

  return { plans, isLoadingPlans };
}
