import React from "react";
import { useTranslation } from "react-i18next";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import { CampaignMetrics } from "common/types";
import MetricSelector from "common/components/MetricSelector";
import TimeframeSelector from "common/components/TimeframeSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import RecentLeads from "common/components/RecentLeads";

import FlowsOverview from "./FlowsOverview";
import CampaignStats from "./CampaignStats";
import { CampaignFlow, DetailedCampaign } from "../../../types";

interface CampaignOverviewProps {
  flows: CampaignFlow[];
  campaign: DetailedCampaign;
  goToFlowTab: (flowId: string) => void;
}

export default function CampaignOverview({
  flows,
  campaign,
  goToFlowTab,
}: CampaignOverviewProps) {
  const { t } = useTranslation("campaigns");
  const {
    setSelectedMetric,
    selectedMetric,
    selectedMetricParams,
    setSelectedMetricParams,
  } = useSelectedMetric();
  const {
    metrics,
    metricsSum,
    refetchMetrics,
    metricsError,
    isLoadingMetrics,
  } = useMetrics({
    campaignId: campaign.id,
    parameters: selectedMetricParams,
  });

  const hiddenMetricsColumns: (keyof CampaignMetrics)[] = [];

  const isPrimaryEnabled = campaign.connect || campaign.message;

  if (campaign.subtype === "connect") {
    hiddenMetricsColumns.push("message_requests");
  } else {
    hiddenMetricsColumns.push("sent_connections", "accepted_connections");
  }

  if (!campaign.optimizations.inmail) {
    hiddenMetricsColumns.push("sent_inmails");
  }

  if (!isPrimaryEnabled) {
    hiddenMetricsColumns.push(
      "views",
      "sent_connections",
      "sent_messages",
      "accepted_connections",
      "message_requests",
    );
  }

  const hiddenColumns = [...new Set(hiddenMetricsColumns)];

  return (
    <>
      <section className="mb-4 flex flex-col items-end justify-between gap-4 md:flex-row md:items-start">
        <div className="flex w-full flex-col gap-y-1">
          <h2 className="text-headline-xl-bold">
            {t("campaignDetails.analyticsTitle")}
          </h2>
          <p className="text-black-500">
            {t("campaignDetails.analyticsSubtitle")}
          </p>
        </div>
        <TimeframeSelector
          selectedParams={selectedMetricParams}
          setSelectedParams={setSelectedMetricParams}
        />
      </section>

      <CampaignStats
        campaignMetrics={campaign.metrics}
        campaignCompletion={campaign.completion}
      />

      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        totalMetrics={campaign.metrics}
        isLoading={isLoadingMetrics}
        hiddenColumns={hiddenColumns}
      />

      <div className="relative my-6 flex h-[400px] md:h-[540px]">
        <StatisticsGraph
          selectedMetric={selectedMetric}
          metrics={metrics}
          metricsSum={metricsSum}
          requestError={metricsError}
          isLoading={isLoadingMetrics}
          isHourly={selectedMetricParams.bucketSize === "1 hour"}
          refetchMetrics={refetchMetrics}
        />
      </div>

      <FlowsOverview
        flows={flows}
        metricsParams={selectedMetricParams}
        goToFlowTab={goToFlowTab}
      />

      <RecentLeads campaignId={campaign.id} showFlowOrigin />
    </>
  );
}
