import React, { JSX } from "react";
import { X } from "@phosphor-icons/react";
import { cva, VariantProps } from "class-variance-authority";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

const containerClassName = cva(
  "mb-4 flex flex-col rounded-3xl border p-2 shadow-lg lg:mb-6 lg:flex-row lg:items-center lg:pr-4",
  {
    variants: {
      variant: {
        default: "border-black-200 bg-white",
        trial: "border-purple-600 bg-purple-50",
        danger: "border-red-400 bg-white",
        warning: "border-yellow-400 bg-white",
      },
    },
  },
);
const iconBgClassName = cva(
  "mr-4 flex size-10 shrink-0 items-center justify-center rounded-2xl lg:size-14 [&_svg]:size-6",
  {
    variants: {
      variant: {
        default: "bg-black-900",
        trial: "bg-blackPurple",
        danger: "bg-red-400",
        warning: "bg-yellow-400",
      },
    },
  },
);

type AlertBannerVariantProps = VariantProps<typeof containerClassName>;
interface AlertBannerProps extends Required<AlertBannerVariantProps> {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  buttonText: string;
  onButtonClick: () => void;
  onClose?: () => void;
}

export default function AlertBanner({
  title,
  subtitle,
  icon,
  buttonText,
  onButtonClick,
  onClose = null,
  variant,
}: AlertBannerProps) {
  const isDesktop = useTwBreakpoint("lg");

  const closeComponent = onClose && (
    <button
      className="ml-auto lg:ml-4"
      aria-label="Close banner"
      type="button"
      onClick={onClose}
    >
      <X size={16} className="fill-black-700" />
    </button>
  );

  return (
    <div className={containerClassName({ variant })}>
      <div className="mb-2 flex lg:mb-0 lg:mr-auto lg:items-center">
        <div className={iconBgClassName({ variant })}>{icon}</div>
        <div className="flex flex-1 flex-col gap-y-1">
          <div className="flex">
            <span className="text-button-16 text-black-950">{title}</span>
            <RenderIf condition={!isDesktop}>{closeComponent}</RenderIf>
          </div>
          <span className="text-body-14-regular text-black-700 lg:text-button-14">
            {subtitle}
          </span>
        </div>
      </div>
      <Button onClick={onButtonClick}>{buttonText}</Button>
      <RenderIf condition={isDesktop}>{closeComponent}</RenderIf>
    </div>
  );
}
