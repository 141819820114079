import React, { Suspense, useEffect, useRef, useState } from "react";
import { zebraStripes } from "@uiw/codemirror-extensions-zebra-stripes";
import { UploadSimple } from "@phosphor-icons/react";
import clsx from "clsx";
import CodeMirror from "@uiw/react-codemirror";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import Skeleton from "common/components/ui/Skeleton";

export default function ProfilesList({
  linkedInProfiles,
  updateProfiles,
  onUpload,
}: {
  linkedInProfiles: string;
  updateProfiles: (validLinks: string[]) => void;
  onUpload: () => void;
}) {
  const editorRef = useRef(null);
  const [validLinkCount, setValidLinkCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [profilesList, setProfilesList] = useState(linkedInProfiles);

  const linkedInUrlRegex =
    /https?:\/\/(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9\-%\u00C0-\u00FF]+\/?$/;
  // Array of invalid links position numbers
  const [invalidLinks, setInvalidLinks] = useState([]);

  const highlightLines = (content: string) => {
    if (!content) {
      // Reset states
      updateProfiles([]);
      setInvalidLinks([]);

      // Set valid links counter to 0
      setValidLinkCount(0);

      if (isLimitReached) {
        setIsLimitReached(false);
      }

      return;
    }

    const lines = content.split("\n");
    const newValidLinks = [];
    const newInvalidLinks = [];

    for (let i = 0; i < lines.length; i += 1) {
      if (!linkedInUrlRegex.test(lines[i])) {
        newInvalidLinks.push(i + 1);
      } else {
        newValidLinks.push(lines[i]);
      }
    }

    if (isLimitReached) {
      setIsLimitReached(false);
    }

    if (newValidLinks.length > 0) {
      // Update valid link count
      setValidLinkCount(newValidLinks.length);
      // Limit valid links to 1000 URLs
      if (newValidLinks.length > 1000) {
        setIsLimitReached(true);
        return;
      }
    } else {
      // Reset state
      setValidLinkCount(0);
    }

    // Update parent component
    updateProfiles(lines);

    // Keep log of invalid links
    setInvalidLinks(newInvalidLinks);
  };

  // When file is uploaded, we need this code
  // to keep track of it and show highlighted lines count
  useEffect(() => {
    setProfilesList(linkedInProfiles);
    highlightLines(linkedInProfiles);
  }, [linkedInProfiles]);

  return (
    <>
      <Suspense fallback={<Skeleton className="h-56" />}>
        <CodeMirror
          value={profilesList}
          ref={editorRef}
          className={clsx([
            "overflow-hidden rounded-xl bg-whiteGray px-2.5 py-1.5 outline-none",
            // Editor
            "[&_.cm-content]:bg-whiteGray",
            "[&_.cm-placeholder]:text-black-400",
            "[&_.cm-focused]:!outline-none",
            "[&_.cm-selectionMatch]:bg-transparent",
            "[&_.cm-line]:flex [&_.cm-line]:h-7 [&_.cm-line]:items-center [&_.cm-line]:font-manrope [&_.cm-line]:text-black-950 [&_.cm-line]:transition-colors [&_.cm-line]:selection:!bg-purple-200",
            // Gutter
            "[&_.cm-gutters]:bg-whiteGray",
            "[&_.cm-gutters]:border-r-0",
            "[&_.cm-gutterElement]:bg-purple-100",
            "[&_.cm-gutterElement]:font-manrope",
            "[&_.cm-gutterElement]:rounded",
            "[&_.cm-gutterElement]:w-8",
            "[&_.cm-gutterElement]:!text-center",
            "[&_.cm-gutterElement]:text-button-12",
            "[&_.cm-gutterElement]:text-purple-600",
            "[&_.cm-gutterElement]:flex",
            "[&_.cm-gutterElement]:items-center",
            "[&_.cm-gutterElement]:shrink-0",
            "[&_.cm-gutterElement]:transition-colors",
            "[&_.cm-gutterElement]:justify-center",
            "[&_.cm-foldGutter]:!hidden",
            // Active lines
            "[&_.cm-activeLineGutter]:!bg-purple-200",
            "[&_.cm-activeLine]:bg-purple-50",
          ])}
          height="212px"
          onDragStart={(event) => {
            event.stopPropagation();
          }}
          onChange={highlightLines}
          placeholder="Example: https://www.linkedin.com/in/username/"
          extensions={[
            zebraStripes({
              lineNumber: invalidLinks.length ? invalidLinks : [0],
              className: "!text-red-500 !bg-red-50",
            }),
          ]}
        />
      </Suspense>

      <RenderIf condition={isLimitReached}>
        <p className="mt-1 text-caption-12-regular text-red-500">
          You&apos;ve reached the limit of LinkedIn URLs. The maximum limit is
          1000 URLs.
        </p>
      </RenderIf>

      {/* List info */}
      <div className="mt-1 flex gap-2 text-caption-12-regular">
        <p className="text-black-700">
          <span className={validLinkCount > 1000 && "text-red-500"}>
            {validLinkCount}
          </span>
          /1000 Links Added
        </p>

        {/* Show number of invalid links */}
        <RenderIf condition={invalidLinks.length > 0}>
          <span className="text-red-500">({invalidLinks.length} invalid)</span>
        </RenderIf>
      </div>

      <Button
        variant="secondary-purple"
        className="ml-auto"
        leftIcon={<UploadSimple />}
        onClick={onUpload}
      >
        Upload file
      </Button>
    </>
  );
}
