import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

interface RangeSelectProps {
  options: {
    value: number;
    label: string;
  }[];
  onSelect: (value: number) => void;
  isDisabled: boolean;
  selectedRangeLabel: string;
}

export default function RangeSelect({
  options,
  onSelect,
  isDisabled,
  selectedRangeLabel,
}: RangeSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className="group flex h-10 w-24 items-center justify-between rounded-xl bg-whiteGray px-3 text-button-14 data-[state=open]:text-black-950 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50"
        disabled={isDisabled}
      >
        <span className="line-clamp-1 text-left">{selectedRangeLabel}</span>
        <CaretDown
          size={20}
          className="ml-2 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>

      <PopoverContent className="flex max-h-44 w-[--radix-popper-anchor-width] flex-col overflow-y-auto py-2 scrollbar-thin">
        {options.map(({ value, label }) => (
          <button
            key={value}
            onClick={() => {
              onSelect(value);
              setIsOpen(false);
            }}
            type="button"
            className="w-full px-4 py-2 transition-colors hover:bg-black-50"
          >
            {label}
          </button>
        ))}
      </PopoverContent>
    </Popover>
  );
}
