import React, { FormEvent, useState } from "react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useLinkedInAccountSearch from "common/hooks/useLinkedInAccountSearch";
import LinkedInAccountCard from "common/components/Layout/LinkedInAccountCard";
import { Input } from "common/components/ui/Input";
import { LinkedinProfile } from "common/types";
import Label from "common/components/ui/Label";
import { Button } from "common/components/ui/Button";
import useSubscriptionUpdates from "common/datahooks/useSubscriptionUpdates";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import useGTM from "common/hooks/useGTM";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

export default function AddSubscription({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const { selectSubscription } = useSubscriptionSelection();
  const { sendEvent } = useGTM();
  const [selectedAccount, setSelectedAccount] = useState<LinkedinProfile>(null);
  const { createSubscription, isCreatingSubscription } =
    useSubscriptionUpdates();
  const { inputProps, onSubmit, isFindingAccount } =
    useLinkedInAccountSearch(setSelectedAccount);

  function startFreeTrial(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    createSubscription({ account: selectedAccount }).then((subscription) => {
      sendEvent("free_trial_start");
      selectSubscription(subscription.id);
      onClose();
    });
  }

  const content = (
    <form
      className="flex flex-col"
      onSubmit={selectedAccount ? startFreeTrial : onSubmit}
    >
      <h2 className="mb-2 text-center text-headline-2xl-bold">
        Find your LinkedIn account
      </h2>
      <p className="mb-4 text-center text-body-14-regular text-black-500">
        Select the LinkedIn account you want to add to Aimfox
      </p>
      {selectedAccount ? (
        <LinkedInAccountCard
          account={selectedAccount}
          changeAccount={() => setSelectedAccount(null)}
        />
      ) : (
        <>
          <Label htmlFor="linkedinProfileSearch" className="mb-1" size="sm">
            LinkedIn Email or URL
          </Label>
          <Input
            id="linkedinProfileSearch"
            placeholder="Enter LinkedIn email or URL..."
            {...inputProps}
          />
        </>
      )}
      <p className="mb-12 mt-4 text-caption-12-regular text-black-400">
        Note: Your LinkedIn Outreach trial for this profile will start
        immediately after adding the account
      </p>
      <Button
        type="submit"
        isLoading={isFindingAccount || isCreatingSubscription}
      >
        {selectedAccount ? "Start Outreach Trial" : "Proceed"}
      </Button>
    </form>
  );

  const Component = isDesktop ? Dialog : Drawer;
  const Content = isDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
      onAfterClose={() => {
        setSelectedAccount(null);
      }}
    >
      <Content className="flex lg:w-[540px]">{content}</Content>
    </Component>
  );
}
