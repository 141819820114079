import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "common/components/Layout/Layout";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useGTM from "common/hooks/useGTM";
import useInvitations from "common/datahooks/useInvitations";
import AppSumoDialog from "common/components/Layout/AppSumoDialog";

import WorkspaceInvitation from "./WorkspaceInvitation";
import Onboarding from "./Onboarding";
import { getBaseNavItems } from "../../../pages/routes";
import useInvitation from "../../datahooks/useInvitation";

export default function OnboardingContainer() {
  const isDesktop = useTwBreakpoint("lg");
  const { t } = useTranslation("onboarding");
  const { sendEvent } = useGTM();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  /* We'll need both hooks here because `useInvitations` will only get the invitations for current account,
  but with `useInvitation`(singular) we can get any invitation based on the `inviteToken` (not necessarily tied to the account) */
  const { invitation, isLoadingInvitation } = useInvitation(
    searchParams.get("invite_token"),
  );
  const { invitations, isLoadingInvitations } = useInvitations();
  const [openDialogId, setOpenDialogId] = useState<
    "onboarding" | "invitation" | "appsumo"
  >();

  useEffect(() => {
    sendEvent("virtualPageView", () => ({
      pageUrl: window.location.href,
      pagePath: location.pathname,
    }));
  }, []);

  useEffect(() => {
    if (searchParams.get("code")) {
      setOpenDialogId("appsumo");
    } else if (!isLoadingInvitations && !isLoadingInvitation) {
      if (invitation || (invitations && invitations.length)) {
        setOpenDialogId("invitation");
      } else {
        setOpenDialogId("onboarding");
      }
    }
  }, [isLoadingInvitation, isLoadingInvitations]);

  return (
    <Layout navItems={getBaseNavItems(isDesktop)} headerTitle={t("title")}>
      <WorkspaceInvitation
        invitation={invitation || (invitations && invitations[0])}
        isOpen={openDialogId === "invitation"}
        onResponse={() => {
          setOpenDialogId("onboarding");
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.delete("invite_token");
            return newParams;
          });
        }}
      />
      <Onboarding isOpen={openDialogId === "onboarding"} />
      <AppSumoDialog
        isOpen={openDialogId === "appsumo"}
        code={searchParams.get("code")}
        onClose={() => {
          setOpenDialogId(
            invitation || (invitations && invitations.length)
              ? "invitation"
              : "onboarding",
          );
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.delete("code");
            return newParams;
          });
        }}
      />
    </Layout>
  );
}
