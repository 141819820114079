import React from "react";
import { clsx } from "clsx";
import { GearSix } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import { userRoles } from "common/constants";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Subscription } from "common/types";
import { Tag } from "common/components/ui/Tag";
import ProfileImage from "common/components/ui/ProfileImage";

export default function SubscriptionSelectionItem({
  subscription,
}: {
  subscription: Subscription;
}) {
  const selectedSubscription = useSelectedSubscriptionContext();
  const { selectSubscription } = useSubscriptionSelection();

  const { id, account, role } = subscription;
  const { label, variant } = userRoles[role];

  const isSelected = id === selectedSubscription.id;

  return (
    <button
      type="button"
      key={id}
      className={clsx(
        "flex w-full shrink-0 items-center rounded-20 px-4 hover:bg-whiteGray max-lg:h-[74px] lg:rounded-2xl lg:p-1",
        isSelected ? "bg-black-100" : "pr-[3.25rem]",
      )}
      aria-label={`select ${account.full_name}'s workspace`}
      onClick={(e) => {
        if (
          !e.nativeEvent
            .composedPath()
            .some((element) => (element as Element).tagName === "A")
        ) {
          selectSubscription(id);
        }
      }}
    >
      <ProfileImage src={account.picture_url} size="md" />
      <div className="ml-2 flex grow flex-col items-start gap-y-0.5 overflow-x-hidden">
        <div className="flex w-full items-center">
          <span
            className={clsx(
              "mr-2 truncate text-body-14-bold lg:mr-1 lg:text-caption-12-bold",
              isSelected && "text-body-16-bold",
            )}
          >
            {account.full_name}
          </span>
          <Tag className="ml-auto" variant={variant}>
            {label}
          </Tag>
        </div>
        {isSelected && (
          <span className="line-clamp-1 w-full text-left text-caption-12-regular text-black-600">
            {account.occupation}
          </span>
        )}
      </div>
      {isSelected && (
        <Link to="settings" className="ml-4 lg:hidden">
          <GearSix size={20} />
        </Link>
      )}
    </button>
  );
}
