import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Invitation } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import useInvitations from "common/datahooks/useInvitations";
import { userRoles } from "common/constants";

export default function WorkspaceInvitation({
  invitation,
  isOpen,
  onResponse = null,
  onClose = null,
}: {
  invitation: Invitation | undefined;
  isOpen: boolean;
  onResponse?: () => void;
  onClose?: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const navigate = useNavigate();
  const isAcceptRef = useRef<boolean>();
  const { respondToInvitation, isRespondingToInvitation } = useInvitations({
    disableQuery: true,
  });

  function acceptInvite() {
    isAcceptRef.current = true;
    respondToInvitation({ accept: true, memberToken: invitation.token }).then(
      (subscription) => {
        navigate(`/${subscription.id}`, {
          replace: true,
        });
        if (onResponse) {
          onResponse();
        }
      },
    );
  }

  function declineInvite() {
    isAcceptRef.current = false;
    respondToInvitation({ accept: false, memberToken: invitation.token }).then(
      () => {
        if (onResponse) {
          onResponse();
        }
      },
    );
  }
  const content = invitation && (
    <>
      <h2 className="mb-12 text-center text-headline-2xl-bold lg:mb-6">
        You have been invited to {invitation.inviter_first_name}{" "}
        {invitation.inviter_last_name}&apos;s workspace
      </h2>
      <div className="mb-12 flex flex-col items-center rounded-3xl border border-black-200 py-8 lg:mb-6">
        <ProfileImage
          src={invitation.account.picture_url}
          size="lg"
          className="mb-2.5"
        />
        <span className="mb-0.5 text-body-16-bold">
          {invitation.account.full_name}
        </span>
        <span className="mb-0.5 text-caption-12-regular">
          {invitation.account.email}
        </span>
        <span className="text-caption-12-regular text-black-400">
          Aimfox business
        </span>
      </div>
      <div className="mb-6 flex justify-center gap-x-2 text-body-16-regular text-black-600">
        Invited as{" "}
        <Tag variant={userRoles[invitation.role].variant} size="lg">
          {userRoles[invitation.role].label}
        </Tag>
      </div>
      <div className="flex flex-col gap-y-2.5 border-b border-b-black-200 pb-6 lg:gap-y-4">
        <Button
          variant="primary-black"
          size="lg"
          onClick={acceptInvite}
          isLoading={isAcceptRef.current === true && isRespondingToInvitation}
          disabled={isAcceptRef.current === false && isRespondingToInvitation}
        >
          Accept
        </Button>
        <Button
          variant="secondary-black"
          size="lg"
          onClick={declineInvite}
          isLoading={isAcceptRef.current === false && isRespondingToInvitation}
          disabled={isAcceptRef.current === true && isRespondingToInvitation}
        >
          Decline
        </Button>
      </div>
      <span className="mt-6 text-center text-black-500">
        Invited by: {invitation.inviter_first_name}{" "}
        {invitation.inviter_last_name}
      </span>
      <span className="text-center text-black-500">
        ({invitation.inviter_email})
      </span>
    </>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <Content className="md:w-[500px]">{content}</Content>
    </Component>
  );
}
