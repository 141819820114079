import React, { Fragment } from "react";

import { ConversationMessage } from "../../../types";

export default function TextMessage({
  message,
}: {
  message: ConversationMessage;
}) {
  const { body } = message;

  return (
    <div className="flex flex-col">
      <div className="whitespace-pre-wrap">
        {body.split(/(\s+)/).map((part, index) => {
          // Regex to match URLs
          const urlRegex =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
          const match = part.match(urlRegex);

          if (match) {
            // Extract the URL and any trailing punctuation
            const url = match[0];
            const punctuation = part.slice(url.length);

            const href = url.startsWith("http") ? url : `http://${url}`;

            return (
              <Fragment key={index || part}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 underline"
                >
                  {url}
                </a>
                {punctuation}
              </Fragment>
            );
          }

          return <Fragment key={index || part}>{part}</Fragment>;
        })}
      </div>
    </div>
  );
}
