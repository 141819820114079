import React, { ReactNode, useState } from "react";
import { ClockCountdown, PencilSimpleLine, Plus } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import Skeleton from "common/components/ui/Skeleton";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import PaymentMethodSelection from "common/components/PaymentMethodSelection";
import AddPaymentMethod from "common/components/AddPaymentMethod";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { cardIcons, paymentMethods } from "common/constants";

function RemainingDays({ nextBilling }: { nextBilling: number }) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const dateToday = new Date();
  dateToday.setHours(0, 0, 0);

  const billingDate = new Date(nextBilling * 1000);
  billingDate.setHours(0, 0, 0);

  const daysRemaining = Math.ceil(
    (billingDate.getTime() - dateToday.getTime()) / (24 * 60 * 60 * 1000),
  );

  return (
    <div className="flex flex-row items-center gap-2 text-blue-500">
      <ClockCountdown size={isTabletOrDesktop ? 24 : 16} />

      <span className="text-caption-12-bold sm:text-body-16-bold">
        {daysRemaining} {daysRemaining > 1 ? "days" : "day"} remaining
      </span>
    </div>
  );
}

export default function PaymentMethodInfo() {
  const { primaryPaymentSource, isLoading } = usePrimaryPaymentSource();
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const {
    next_billing: nextBilling,
    status,
    plan,
  } = useSelectedSubscriptionContext();

  const { setPrimaryPaymentSource } = usePrimaryPaymentSource();

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  function onAddPaymentMethod() {
    setIsAddingPayment(true);
    setIsDialogOpen(true);
  }

  let iconComponent: ReactNode = null;
  if (primaryPaymentSource) {
    const Icon =
      primaryPaymentSource.type === "card"
        ? cardIcons[primaryPaymentSource.card.brand]
        : paymentMethods[primaryPaymentSource.type].icon;

    iconComponent = <Icon className="w-12 scale-150" />;
  }

  const paymentInfoContent = isAddingPayment ? (
    <AddPaymentMethod onBack={() => setIsAddingPayment(false)} />
  ) : (
    <>
      <PaymentMethodSelection
        onAddPaymentMethod={() => setIsAddingPayment(true)}
        onSelectPaymentSource={(paymentSource) =>
          setPrimaryPaymentSource({ paymentSource })
        }
      />

      <Close asChild>
        <Button className="mt-4">Done</Button>
      </Close>
    </>
  );

  let paymentMethodComponent;
  if (isLoading) {
    paymentMethodComponent = (
      <div className="flex flex-row items-center gap-3 rounded-xl border border-purple-400 bg-purple-50 p-3">
        <Skeleton className="h-[27px] w-12 bg-purple-200" />

        <div className="flex flex-1 flex-col">
          <Skeleton className="h-4 w-32 bg-purple-200" />
          <Skeleton className="mt-1 h-4 w-24 bg-purple-200" />
        </div>

        <Skeleton className="size-10 rounded-full bg-purple-200" />
      </div>
    );
  } else if (primaryPaymentSource) {
    paymentMethodComponent = (
      <div className="flex flex-row items-center gap-3 rounded-xl border border-purple-400 bg-purple-50 p-3">
        {iconComponent}

        <div className="flex flex-1 flex-col">
          <span className="text-body-14-bold">
            {primaryPaymentSource.type === "card"
              ? `Card ending with ${primaryPaymentSource.card.last4}`
              : primaryPaymentSource.paypal.email}
          </span>

          {primaryPaymentSource?.type === "card" && (
            <span className="text-caption-12-regular text-black-500">
              Expiry{" "}
              {`${primaryPaymentSource.card.expiry_month}/${primaryPaymentSource.card.expiry_year}`}
            </span>
          )}
        </div>

        <Button
          onClick={() => setIsDialogOpen(true)}
          variant="secondary-black"
          intent="iconOnly"
        >
          <PencilSimpleLine />
        </Button>
      </div>
    );
  } else {
    paymentMethodComponent = (
      <button
        type="button"
        className="flex items-center justify-start gap-2 rounded-2xl border border-dashed border-black-300 bg-white px-4 py-3 transition-colors hover:bg-black-50"
        onClick={onAddPaymentMethod}
      >
        <Button intent="labelIcon">
          <Plus />
        </Button>

        <span className="text-body-14-bold">Add Payment Method</span>
      </button>
    );
  }

  return (
    <>
      <section className="mt-4 flex flex-col gap-4 rounded-2xl border border-black-200 bg-whiteGray p-4">
        {/* Info header */}
        <div className="flex flex-row justify-between">
          <h3 className="text-body-16-bold capitalize">
            {plan.name.split("-").join(" ")}
          </h3>

          {status === "in_trial" ? (
            <RemainingDays nextBilling={nextBilling} />
          ) : (
            <span className="text-caption-12-bold text-green-400 sm:text-body-16-bold">
              Active
            </span>
          )}
        </div>

        {/* Payment Method */}
        {paymentMethodComponent}
      </section>

      <Component open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Content className="flex flex-col gap-4 p-4 pb-6 pt-10 max-md:h-dvh md:max-w-[540px]">
          {paymentInfoContent}
        </Content>
      </Component>
    </>
  );
}
