import React from "react";
import { Route, Routes } from "react-router-dom";

import AccountSettings from "./accountsettings/components/AccountSettings";
import Notifications from "./notifications/Notifications";

export default function Account() {
  return (
    <Routes>
      <Route path="settings" element={<AccountSettings />} />
      <Route path="notifications" element={<Notifications />} />
    </Routes>
  );
}
