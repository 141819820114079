import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

async function getConversationUrn(accountId: number, leadId: string) {
  const { conversation_urn: conversationUrn } = await get(
    `accounts/${accountId}/leads/${leadId}/conversation-urn`,
  );
  return conversationUrn;
}

export default function useLeadConversationUrn({ leadId }: { leadId: string }) {
  const { account } = useSelectedSubscriptionContext();

  const { leadsKeys } = getQueryKeys(account);

  const { data: leadConversationUrn } = useQuery<string>({
    queryKey: leadsKeys.conversationUrn(leadId),
    queryFn: () => getConversationUrn(account?.id, leadId),
    enabled: !!leadId,
  });
  return leadConversationUrn;
}
