import React from "react";
import { ArrowRight, DotsThreeVertical } from "@phosphor-icons/react";

import { PaymentSource, Subscription } from "common/types";
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableCell,
} from "common/components/ui/Table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import { getMonthName } from "common/helpers/utils";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";

import AccountPreview from "./AccountPreview";
import PaymentMethodLabel from "./PaymentMethodLabel";

export default function DesktopSubscriptions({
  subscriptions,
  isLoading,
}: {
  subscriptions: (Subscription & { paymentSource: PaymentSource })[];
  isLoading: boolean;
}) {
  const { selectSubscription } = useSubscriptionSelection();

  return (
    <Table className="mt-4 w-full" variant="simple">
      <TableHeader>
        <TableRow className="border-none">
          <TableHead>Account</TableHead>
          <TableHead>Members</TableHead>
          <TableHead>Plan</TableHead>
          <TableHead>Billing Method</TableHead>
          <TableHead>Next Billing</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>

      <tbody className="mt-4">
        {subscriptions.map((subscription) => {
          const {
            id: subscriptionId,
            account,
            next_billing: nextBilling,
            plan,
            role,
            paymentSource,
            member_count: memberCount,
          } = subscription;
          const nextBillingDate = new Date(nextBilling * 1000);

          return (
            <TableRow key={subscriptionId}>
              <TableCell>
                <AccountPreview account={account} role={role} />
              </TableCell>

              <TableCell>
                <span className="text-body-14-regular">{memberCount}</span>
              </TableCell>

              <TableCell>
                <span className="text-body-14-regular capitalize">
                  {plan?.name.split("-").join(" ")}
                </span>
              </TableCell>

              <TableCell>
                <div className="flex flex-row justify-center">
                  <PaymentMethodLabel
                    isCompact
                    paymentSource={paymentSource}
                    isLoading={isLoading}
                  />
                </div>
              </TableCell>

              <TableCell>
                <span className="text-body-14-regular">
                  {subscription.next_billing &&
                    `${nextBillingDate.getDate()} - ${getMonthName(nextBillingDate.getMonth())}`}
                </span>{" "}
                <span className="text-body-14-bold">
                  {`($${plan.pricing.price / 100})`}
                </span>
              </TableCell>

              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button intent="iconOnly" variant="secondary-black">
                      <DotsThreeVertical />
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onClick={() => {
                        selectSubscription(subscriptionId);
                      }}
                    >
                      <ArrowRight size={20} />
                      <span>Go to workspace</span>
                    </DropdownMenuItem>

                    {/* Add functionality for removing account */}
                    {/* <DropdownMenuItem className="text-red-500">
                      <Trash size={20} />
                      <span>Remove account</span>
                    </DropdownMenuItem> */}
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}
