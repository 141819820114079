import React from "react";
import {
  ArrowUUpRight,
  Check,
  EnvelopeSimpleOpen,
  UsersThree,
  type Icon,
} from "@phosphor-icons/react";

import useMetrics from "common/datahooks/useMetrics";
import { CampaignMetrics, MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";

import { CampaignFlow } from "../../../../types";

const metricsList: {
  icon: Icon;
  label: string;
  getValue: (metricsSum: CampaignMetrics) => string | number;
  getTotalValue: (totalMetrics: CampaignMetrics) => string | number;
}[] = [
  {
    icon: UsersThree,
    label: "Leads gained",
    getValue: (metricsSum) =>
      metricsSum.accepted_connections + metricsSum.replies,
    getTotalValue: (totalMetrics) =>
      totalMetrics.accepted_connections + totalMetrics.replies,
  },
  {
    icon: EnvelopeSimpleOpen,
    label: "Invites sent",
    getValue: (metricsSum) => metricsSum.sent_connections,
    getTotalValue: (totalMetrics) => totalMetrics.sent_connections,
  },
  {
    icon: ArrowUUpRight,
    label: "Reply rate",
    getValue: (metricsSum) => {
      const {
        sent_inmails: sentInmails,
        sent_messages: sentMessages,
        replies,
      } = metricsSum;

      let replyPercentage = 0;
      if (sentMessages) {
        replyPercentage = (replies / sentMessages) * 100;
      } else if (sentInmails) {
        replyPercentage = (replies / sentInmails) * 100;
      }

      return `${replies} (${Math.round(replyPercentage)}%)`;
    },
    getTotalValue: (totalMetrics) => totalMetrics.replies.toString(),
  },
  {
    icon: Check,
    label: "Acceptance %",
    getValue: (metricsSum) =>
      `${
        metricsSum.sent_connections
          ? Math.round(
              (metricsSum.accepted_connections / metricsSum.sent_connections) *
                100,
            )
          : 0
      }%`,
    getTotalValue: (totalMetrics) =>
      `${
        totalMetrics.sent_connections
          ? Math.round(
              (totalMetrics.accepted_connections /
                totalMetrics.sent_connections) *
                100,
            )
          : 0
      }%`,
  },
];

interface OverviewCardProps {
  flow: CampaignFlow;
  metricsParams: MetricsParameters;
}

export default function OverviewCard({
  flow,
  metricsParams,
}: OverviewCardProps) {
  const { metricsSum, isLoadingMetrics } = useMetrics({
    parameters: metricsParams,
    flowId: flow.id,
  });

  return metricsList.map(({ icon: Icon, label, getValue, getTotalValue }) => (
    <div
      key={label}
      className="flex items-center gap-4 border-b border-black-200 py-4"
    >
      <Button intent="labelIcon" variant="secondary-black">
        <Icon />
      </Button>

      <div className="flex flex-col gap-0.5">
        {isLoadingMetrics ? (
          <>
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-7 w-12" />
          </>
        ) : (
          <>
            <h5 className="text-caption-12-regular">{label}</h5>
            <span className="text-headline-xl-bold">
              {getValue(metricsSum)}
            </span>
          </>
        )}
      </div>

      <p className="ml-auto text-blue-500">
        Total {getTotalValue(flow.metrics)}
      </p>
    </div>
  ));
}
