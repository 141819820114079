import React from "react";
import { Info } from "@phosphor-icons/react";

import useSubscriptionSettings from "common/datahooks/useSubscriptionSettings";
import { isRocketHubUser } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { Button } from "common/components/ui/Button";

import PaidPlan from "./PaidPlan";

export default function SubscriptionPlans() {
  const { subscriptionSettings, isLoadingSubscriptionSettings } =
    useSubscriptionSettings();

  return (
    <div className="flex flex-col gap-4">
      <div className="relative flex w-full flex-col items-start justify-between gap-4 rounded-2xl border border-black-200 bg-white p-3 pr-6 md:flex-row md:items-center md:gap-0">
        <div className="flex flex-col gap-4">
          {isLoadingSubscriptionSettings ? (
            <div className="flex flex-row items-center gap-2">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="size-5 rounded-full" />
            </div>
          ) : (
            <Tag className="w-fit" size="lg" variant="tertiary">
              Free
              {isRocketHubUser(subscriptionSettings) ? " (lifetime)" : ""}
            </Tag>
          )}

          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <span className="text-body-16-bold">LinkedIn CRM</span>

              <Tooltip>
                <TooltipTrigger>
                  <Info className="fill-black-400" size={20} weight="fill" />
                </TooltipTrigger>

                <TooltipContent>
                  <ul className="list-disc pl-3">
                    <li>Dedicated IP address for each account</li>
                    <li>Fully integrated Inbox</li>
                    <li>Leads database and management</li>
                    <li>Export leads data</li>
                    <li>In-depth analytics dashboard</li>
                    <li>3rd party Integrations support</li>
                    <li>Multi-user support & permission control</li>
                  </ul>
                </TooltipContent>
              </Tooltip>
            </div>

            <ul className="max-w-[725px] flex-col text-black-500">
              <li>
                Unlimited access to all LinkedIn CRM features. Includes the
                Leads CRM, Inbox, as well as Multi-User support
              </li>
              <li>
                Communicate with your leads and organize your prospects at scale
              </li>
            </ul>
          </div>
        </div>

        <Button disabled>Always active (Free)</Button>
      </div>

      <PaidPlan />
    </div>
  );
}
