import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { get, post } from "../helpers/HTTP";

async function resetUnreadMessagesRequest(
  accountId: number,
  versionTag: string,
) {
  await post(
    `accounts/${accountId}/conversations/unread?version_tag=${versionTag}`,
  );
}
async function getUnreadMessagesCount(accountId: number) {
  const { version_tag: versionTag, unread_count: unreadMessagesCount } =
    await get(`accounts/${accountId}/conversations/unread`);
  return { versionTag, unreadMessagesCount };
}
export default function useUnreadMessagesCount({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const queryKey = ["unreadMessages"];
  const queryClient = useQueryClient();
  const { account } = useSelectedSubscriptionContext();
  const { data } = useQuery<{
    unreadMessagesCount: number;
    versionTag: string;
  }>({
    queryKey,
    queryFn: () => getUnreadMessagesCount(account?.id),
    refetchInterval: 1000,
    enabled: !disableQuery,
    refetchOnMount: false,
  });
  const { mutate: resetUnreadMessages } = useMutation({
    mutationFn: ({ versionTag }: { versionTag: string }) =>
      resetUnreadMessagesRequest(account?.id, versionTag),
    onSuccess: () => {
      queryClient.setQueryData<{
        unreadMessagesCount: number;
        versionTag: string;
      }>(queryKey, (prevValue) => ({
        ...prevValue,
        unreadMessagesCount: 0,
      }));
    },
  });
  return {
    ...data,
    resetUnreadMessages,
  };
}
