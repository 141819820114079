import React from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useCurrentBaseRoute from "common/hooks/useCurrentBaseRoute";
import { SubscriptionContainer } from "common/components/Layout";

import useSubscriptions from "../datahooks/useSubscriptions";
import { SelectedSubscriptionProvider } from "./SelectedSubscriptionContext";
import routes from "../../pages/routes";

// this component is used to validate subscription ids in the url and navigate the user according to their subscription
export default function SubscriptionValidator() {
  const { subscriptionId } = useParams();
  const { subscriptions } = useSubscriptions();
  const { pathname } = useLocation();
  const baseRoute = useCurrentBaseRoute();
  const [searchParams] = useSearchParams();

  const selectedSubscription = subscriptions.find(
    ({ id }) => id === subscriptionId,
  );
  // first validate the subscription id in the url
  if (
    !subscriptionId ||
    !subscriptions.find(({ id }) => id === subscriptionId)
  ) {
    return <Navigate to={`/${subscriptions[0].id}`} replace />;
  }
  if (!baseRoute) {
    return (
      <Navigate to={`${routes[0].path}?${searchParams.toString()}`} replace />
    );
  }
  if (
    (!selectedSubscription.account ||
      selectedSubscription.account.state === "NeverLoggedIn") &&
    !pathname.includes("/home") &&
    !pathname.includes("/settings") &&
    !baseRoute.path.includes("account") &&
    !baseRoute.path.includes("invitations")
  ) {
    return <Navigate to="home" replace />;
  }
  if (subscriptionId && subscriptions.some(({ id }) => id === subscriptionId)) {
    return (
      <SelectedSubscriptionProvider value={selectedSubscription}>
        <SubscriptionContainer />
      </SelectedSubscriptionProvider>
    );
  }
}
