import React, { JSX } from "react";
import {
  House,
  UsersThree,
  Envelope,
  GearSix,
  CardsThree,
  ColumnsPlusRight,
  IconProps,
  Bell,
} from "@phosphor-icons/react";
import { clsx } from "clsx";

import BadgeWrapper from "common/components/Layout/BadgeWrapper";

import Home from "./home/components/Home";
import Campaigns from "./campaigns/components/Campaigns";
import Templates from "./templates/components/Templates";
import Inbox from "./inbox/components/Inbox";
import Leads from "./leads/components/Leads";
import WorkspaceSettings from "./workspacesettings/components/WorkspaceSettings";
import Account from "./account/components/Account";

export interface BaseRoute {
  path: string;
  component: JSX.Element;
  accountDisconnectedText?: string;
  shouldHideBanners?: boolean;
  // if this is true, dialogs such as account disconnected, subscription expired are not automatically opened on navigation
  shouldHideDialogs?: boolean;
}
const baseRoutes: BaseRoute[] = [
  {
    path: "home",
    component: <Home />,
    accountDisconnectedText:
      "You cannot create or launch any new campaigns until you reconnect your LinkedIn account.\n Some of the information on this page may be outdated and will be automatically synchronized as soon as you reconnect your LinkedIn account",
  },
  {
    path: "campaigns/*",
    component: <Campaigns />,
    accountDisconnectedText:
      "You cannot create or launch any new campaigns until you reconnect your LinkedIn account.\n Some of the information on this page may be outdated and will be automatically synchronized as soon as you reconnect your LinkedIn account",
  },
  {
    path: "leads/*",
    component: <Leads />,
    accountDisconnectedText:
      "The information on this page may be outdated, please connect your LinkedIn Account to synchronize with your LinkedIn data",
  },
  {
    path: "templates/*",
    component: <Templates />,
    accountDisconnectedText:
      "You can create and manage templates, but all messages using the templates cannot be sent until you connect your LinkedIn account",
  },
  {
    path: "inbox",
    component: <Inbox />,
    accountDisconnectedText:
      "You cannot send or receive any new messages until you connect your LinkedIn account.\n Some of the information on this page may be outdated and will be automatically synchronized as soon as you reconnect your LinkedIn account",
  },
  {
    path: "settings/*",
    component: <WorkspaceSettings />,
    shouldHideDialogs: true,
  },
  {
    path: "account/*",
    component: <Account />,
    shouldHideBanners: true,
    shouldHideDialogs: true,
  },
];
export default baseRoutes;
export function getBaseNavItems(isDesktop: boolean) {
  return [
    {
      path: "home",
      label: "Home",
      renderIcon: (props: IconProps) => <House {...props} />,
    },
    {
      path: "campaigns",
      label: "Campaigns",
      renderIcon: ({ className, ...rest }: IconProps) => (
        <ColumnsPlusRight className={clsx("rotate-90", className)} {...rest} />
      ),
    },
    {
      path: "leads",
      label: "Leads",
      renderIcon: (props: IconProps) => <UsersThree {...props} />,
    },
    {
      path: "inbox",
      label: "Inbox",
      renderIcon: (props: IconProps, badgeClassName: string) => (
        <BadgeWrapper
          badgeClassName={clsx(badgeClassName, "-right-px top-px size-2")}
        >
          <Envelope {...props} />
        </BadgeWrapper>
      ),
    },
    {
      path: "templates",
      label: "Templates",
      renderIcon: (props: IconProps) => <CardsThree {...props} />,
    },
    {
      path: "settings",
      label: "Workspace Settings",
      renderIcon: (props: IconProps) => <GearSix {...props} />,
    },
    ...(isDesktop
      ? []
      : [
          {
            path: "account/notifications",
            label: "Notifications",
            renderIcon: (props: IconProps, badgeClassName: string) => (
              <BadgeWrapper
                badgeClassName={clsx(badgeClassName, "right-0 top-0 size-2")}
              >
                <Bell {...props} />
              </BadgeWrapper>
            ),
          },
        ]),
  ];
}
