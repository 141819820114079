import React, { useEffect } from "react";
import clsx from "clsx";
import { DotOutline } from "@phosphor-icons/react";

import useNotifications from "common/datahooks/useNotifications";
import { timeSince } from "common/helpers/utils";
import { Notification } from "common/types";
import RenderIf from "common/components/RenderIf";

export default function NotificationItem({
  title,
  body,
  seen,
  created_at,
  id,
}: Notification) {
  const { openNotification } = useNotifications();

  useEffect(() => {
    if (!seen) {
      const timeoutId = setTimeout(
        () => openNotification({ notificationId: id }),
        1000,
      );
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <div className={clsx("rounded-2xl p-3.5", seen && "bg-purple-50")}>
      <div className="flex">
        <h3 className="flex-1 text-caption-12-bold">{title}</h3>
        <span className="text-caption-10-regular text-black-500">
          {timeSince(created_at * 1000)}
        </span>
        <RenderIf condition={seen}>
          <DotOutline size={16} className="ml-2 fill-purple-500" />
        </RenderIf>
      </div>
      <p className="text-caption-12-regular">{body}</p>
    </div>
  );
}
