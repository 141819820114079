import { useInfiniteQuery } from "@tanstack/react-query";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import getQueryKeys from "./getQueryKeys";
import { post } from "../helpers/HTTP";
import { LinkedinProfile, ProfileSearchParams } from "../types";

async function searchProfiles(
  accountId: number,
  searchParams: object,
  page: number,
) {
  const queryParams = new URLSearchParams();
  queryParams.set("page", page.toString());
  queryParams.set("count", "20");

  const {
    targets,
    total_count: totalCount,
    limit_exceeded: limitExceeded,
  } = await post(
    `accounts/${accountId}/search?${queryParams.toString()}`,
    searchParams,
  );
  return { targets, totalCount, limitExceeded };
}

export default function useProfileSearch({
  searchParams,
}: {
  searchParams: Partial<ProfileSearchParams>;
}) {
  const { account } = useSelectedSubscriptionContext();

  const { profileSearchKeys } = getQueryKeys(account);

  const { isLoading: isLoadingProfiles, data: searchResults } =
    useInfiniteQuery({
      queryKey: profileSearchKeys.list(searchParams),
      queryFn: ({ pageParam }) =>
        searchProfiles(account?.id, searchParams, pageParam),
      enabled: Object.values(searchParams).some((param) =>
        Array.isArray(param) ? param.length : param,
      ),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => allPages.length,
    });

  let profiles: LinkedinProfile[];
  if (searchResults) {
    profiles = searchResults.pages.map(({ targets }) => targets).flat();
  }
  return {
    profiles,
    totalCount: searchResults?.pages[0].totalCount,
    limitExceeded: searchResults?.pages[0].limitExceeded,
    isLoadingProfiles,
  };
}
