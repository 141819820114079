import { useQuery, keepPreviousData } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { Invitation } from "common/types";

async function getInvitation(inviteToken: string): Promise<Invitation> {
  return (await get(`invites/${inviteToken}`)).invite;
}

export default function useInvitation(inviteToken: string) {
  const { data: invitation, isLoading: isLoadingInvitation } = useQuery({
    queryKey: ["invitations", inviteToken],
    queryFn: () => getInvitation(inviteToken),
    placeholderData: keepPreviousData,
    enabled: !!inviteToken,
  });

  return { invitation, isLoadingInvitation };
}
