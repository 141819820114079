import React from "react";

import { LinkedinProfile } from "common/types";
import { Input } from "common/components/ui/Input";
import { Button } from "common/components/ui/Button";
import useLinkedInAccountSearch from "common/hooks/useLinkedInAccountSearch";

export default function AccountSearch({
  setAccount,
}: {
  setAccount: (profile: LinkedinProfile) => void;
}) {
  const { isFindingAccount, inputProps, onSubmit } =
    useLinkedInAccountSearch(setAccount);

  return (
    <>
      <Input variant="lg" placeholder="Email or LinkedIn URL" {...inputProps} />
      <Button
        size="lg"
        variant="primary-black"
        className="mt-6"
        onClick={onSubmit}
        isLoading={isFindingAccount}
      >
        Search
      </Button>
    </>
  );
}
