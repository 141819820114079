import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import RenderIf from "common/components/RenderIf";

import useCampaignMutations from "../../datahooks/useCampaignMutations";
import PrimaryFlows from "./PrimaryFlows";
import CampaignOptimization from "./CampaignOptimization";
import { CampaignFlow, DetailedCampaign, TargetingStats } from "../../types";
import { getFlowsByType, validateFlows } from "../../utils";
import {
  CampaignFlowsErrorProvider,
  useCampaignFlowsErrorContext,
} from "../../context/CampaignFlowsErrorContext";
import Stepper from "./Stepper";

interface CampaignFlowsProps {
  campaign: DetailedCampaign;
  targetingStats: TargetingStats;
  isLoadingTargetingStats: boolean;
}

function CampaignFlowsContent({
  campaign,
  targetingStats,
  isLoadingTargetingStats,
}: CampaignFlowsProps) {
  const navigate = useNavigate();
  const { updateCampaign } = useCampaignMutations();
  const { setInvalidFlows } = useCampaignFlowsErrorContext();

  const { primaryFlows, inmailFlow } = getFlowsByType(campaign.flows);

  let inmailFlowWithTemplate = inmailFlow;
  if (inmailFlow && !inmailFlow.template) {
    inmailFlowWithTemplate = {
      ...inmailFlow,
      template: {
        ai: false,
        edited: false,
        message: "",
        original_id: null,
        subject: "",
        type: "INMAIL_TEMPLATE",
      },
    };
  }

  const {
    connect: isPrimaryConnect,
    message: isPrimaryMessage,
    subtype,
    optimizations,
  } = campaign;

  const isPrimaryEnabled = isPrimaryConnect || isPrimaryMessage;

  function goToNextStep() {
    const { isFlowListValid, flowsWithError } = validateFlows(
      campaign.flows,
      campaign.optimizations,
    );

    setInvalidFlows(flowsWithError);

    if (isFlowListValid) {
      navigate("../schedule");
    } else {
      toast.error("All campaign flows must be valid");
    }
  }

  // Hide Connect Optimization https://linear.app/constel/issue/ENG-785/hide-connect-optimization

  // function toggleConnectOptimization() {
  //   updateCampaign({
  //     campaignId: campaign.id,
  //     updates: {
  //       optimizations: {
  //         ...campaign.optimizations,
  //         connect: !campaign.optimizations.connect,
  //       },
  //     },
  //   });
  // }

  function toggleInmailOptimizations() {
    updateCampaign({
      campaignId: campaign.id,
      updates: {
        optimizations: {
          ...campaign.optimizations,
          inmail: !campaign.optimizations.inmail,
        },
      },
    });
  }

  return (
    <>
      {/* Flows */}
      <>
        <RenderIf condition={isPrimaryEnabled}>
          <PrimaryFlows
            flows={primaryFlows}
            type={subtype}
            targetsCount={campaign.target_count}
          />
        </RenderIf>
        {/* <RenderIf condition={isPrimaryEnabled || optimizations.connect}>
          <CampaignOptimization
            flow={connectFlow}
            canToggle={isPrimaryEnabled}
            isEnabled={optimizations.connect}
            targetsCount={targetingStats.connectOptimization}
            toggleOptimization={toggleConnectOptimization}
          />
        </RenderIf> */}
        <RenderIf condition={isPrimaryEnabled || optimizations.inmail}>
          <CampaignOptimization
            flow={inmailFlowWithTemplate}
            canToggle={isPrimaryEnabled}
            isEnabled={optimizations.inmail}
            isLoadingTargetsCount={isLoadingTargetingStats}
            targetsCount={
              targetingStats ? targetingStats.inmailOptimization : 0
            }
            toggleOptimization={toggleInmailOptimizations}
          />
        </RenderIf>
      </>

      {/* Stepper */}
      <Stepper selectedIndex={2} goToNextStep={goToNextStep} />
    </>
  );
}

export default function CampaignFlows({
  campaign,
  targetingStats,
  isLoadingTargetingStats,
}: CampaignFlowsProps) {
  const [invalidFlows, setInvalidFlows] = useState<CampaignFlow[]>([]);

  return (
    <CampaignFlowsErrorProvider value={{ invalidFlows, setInvalidFlows }}>
      <CampaignFlowsContent
        campaign={campaign}
        targetingStats={targetingStats}
        isLoadingTargetingStats={isLoadingTargetingStats}
      />
    </CampaignFlowsErrorProvider>
  );
}
