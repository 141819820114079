import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { del, patch, post } from "../helpers/HTTP";
import { LinkedinProfile, Subscription, SubscriptionSettings } from "../types";

async function createSubscriptionRequest(
  account: LinkedinProfile,
): Promise<Subscription> {
  return (await post("subscriptions", { account })).subscription;
}
async function cancelSubscriptionRequest(
  subscriptionId: string,
): Promise<Subscription> {
  return (await post(`subscriptions/${subscriptionId}/cancel`)).subscription;
}
async function reactivateSubscriptionRequest(
  subscriptionId: string,
): Promise<Subscription> {
  return (await post(`subscriptions/${subscriptionId}/reactivate`))
    .subscription;
}
async function revertCancellationRequest(
  subscriptionId: string,
): Promise<Subscription> {
  return (await del(`subscriptions/${subscriptionId}/cancel`)).subscription;
}
async function addCouponRequest(
  subscriptionId: string,
  coupon: string,
): Promise<SubscriptionSettings> {
  return (
    await patch(`subscriptions/${subscriptionId}/coupon`, {
      coupon,
    })
  ).subscription;
}

export default function useSubscriptionUpdates() {
  const queryClient = useQueryClient();

  const {
    data: cancelledSubscription,
    mutateAsync: cancelSubscription,
    isPending: isCancellingSubscription,
  } = useMutation({
    mutationFn: ({ subscriptionId }: { subscriptionId: string }) =>
      cancelSubscriptionRequest(subscriptionId),
    onSuccess: ({ status, cancelled_at }, { subscriptionId }) => {
      queryClient.setQueryData<Subscription[]>(
        ["subscriptions"],
        (prevSubscriptions) =>
          produce(prevSubscriptions, (draftState) => {
            for (let i = 0; i < draftState.length; i += 1) {
              if (subscriptionId === draftState[i].id) {
                draftState[i].status = status;
                draftState[i].cancelled_at = cancelled_at;
                return;
              }
            }
          }),
      );
    },
  });

  const {
    mutateAsync: reactivateSubscription,
    isPending: isReactivatingSubscription,
    error: reactivateSubscriptionError,
    reset: resetReactivateSubscription,
    data: reactivatedSubscription,
  } = useMutation({
    mutationFn: ({ subscriptionId }: { subscriptionId: string }) =>
      reactivateSubscriptionRequest(subscriptionId),
    onSuccess: (subscription) => {
      queryClient.setQueryData<Subscription[]>(
        ["subscriptions"],
        (prevSubscriptions) =>
          produce(prevSubscriptions, (draftState) => {
            for (let i = 0; i < draftState.length; i += 1) {
              if (subscription.id === draftState[i].id) {
                draftState[i] = subscription;
                return;
              }
            }
          }),
      );
    },
  });

  const {
    data: revertedSubscription,
    mutateAsync: revertSubscriptionCancellation,
    isPending: isRevertingSubscriptionCancellation,
  } = useMutation({
    mutationFn: ({ subscriptionId }: { subscriptionId: string }) =>
      revertCancellationRequest(subscriptionId),
    onSuccess: ({ status, cancelled_at }, { subscriptionId }) => {
      queryClient.setQueryData<Subscription[]>(
        ["subscriptions"],
        (prevSubscriptions) =>
          produce(prevSubscriptions, (draftState) => {
            for (let i = 0; i < draftState.length; i += 1) {
              if (subscriptionId === draftState[i].id) {
                draftState[i].status = status;
                draftState[i].cancelled_at = cancelled_at;
                return;
              }
            }
          }),
      );
    },
  });

  const { mutateAsync: createSubscription, isPending: isCreatingSubscription } =
    useMutation({
      mutationFn: ({ account }: { account: LinkedinProfile }) =>
        createSubscriptionRequest(account),
      onSuccess: (newSubscription) => {
        queryClient.setQueryData<Subscription[]>(
          ["subscriptions"],
          (prevSubscriptions) => [...prevSubscriptions, newSubscription],
        );
      },
    });

  const { mutateAsync: addCoupon, isPending: isAddingCoupon } = useMutation({
    mutationFn: ({
      subscriptionId,
      coupon,
    }: {
      subscriptionId: string;
      coupon: string;
    }) => addCouponRequest(subscriptionId, coupon),
    onSuccess: (subscriptionSettings) =>
      queryClient.setQueryData(
        ["subscriptionSettings", subscriptionSettings.id],
        subscriptionSettings,
      ),
  });

  return {
    cancelledSubscription,
    cancelSubscription,
    isCancellingSubscription,

    reactivateSubscription,
    reactivatedSubscription,
    isReactivatingSubscription,
    reactivateSubscriptionError,
    resetReactivateSubscription,

    createSubscription,
    isCreatingSubscription,

    revertSubscriptionCancellation,
    revertedSubscription,
    isRevertingSubscriptionCancellation,

    addCoupon,
    isAddingCoupon,
  };
}
