import { useRef } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import * as Sentry from "@sentry/react";

import { LinkedInSearchSchema, LinkedInSearchSchemaType } from "common/schemas";
import useLinkedInAccounts from "common/datahooks/useLinkedInAccounts";
import { HTTPError } from "common/helpers/HTTP";
import useGTM from "common/hooks/useGTM";
import { LinkedinProfile } from "common/types";

export default function useLinkedInAccountSearch(
  setAccount: (profile: LinkedinProfile) => void,
) {
  const { sendEvent } = useGTM();
  const { findAccount, isFindingAccount } = useLinkedInAccounts();
  const typingEventSent = useRef(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{
    emailOrUrl: string;
  }>({ resolver: valibotResolver(LinkedInSearchSchema) });

  function onSubmit(data: LinkedInSearchSchemaType) {
    if (isFindingAccount) {
      return;
    }
    findAccount(data)
      .then((account) => {
        sendEvent("search_account_success", ({ email }) => ({
          email,
        }));
        setAccount(account);
      })
      .catch((error) => {
        Sentry.setExtra("error", error);
        Sentry.setExtra("error_json", JSON.stringify(error));
        Sentry.captureMessage(
          `LinkedIn account not found: ${error instanceof HTTPError && error.serverMessage ? error.serverMessage : "Error finding account"}`,
        );
        sendEvent("search_account_fail", ({ email }) => ({
          email,
        }));
        if (error.code === 409) {
          setError("emailOrUrl", {
            message: "Account is already active on another subscription",
          });
        } else if (error.code === 404) {
          setError("emailOrUrl", {
            message:
              "We couldn't find a LinkedIn account with that email address. Make sure this is the correct email and that your profile discovery is turned on",
          });
        } else {
          setError("emailOrUrl", {
            message: "Error finding account",
          });
        }
      });
  }

  return {
    inputProps: {
      ...register("emailOrUrl", {
        onChange: (e) => {
          if (!typingEventSent.current && e.target.value) {
            typingEventSent.current = true;
            sendEvent("search_account_start");
          }
        },
      }),
      error: errors.emailOrUrl?.message,
    },
    onSubmit: handleSubmit(onSubmit),
    isFindingAccount,
  };
}
