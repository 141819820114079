import { FunctionComponent, SVGProps } from "react";
import {
  MagnifyingGlass,
  Image,
  CalendarDots,
  UsersThree,
  Files,
  type Icon,
  Envelope,
  Chat,
  NoteBlank,
  Eye,
  EnvelopeSimpleOpen,
  Check,
  ArrowUUpLeft,
  PaperPlaneTilt,
  Sparkle,
} from "@phosphor-icons/react";

import {
  FlowInmailTemplateSchema,
  FlowMessageTemplateSchema,
  FlowNoteTemplateSchema,
  InmailTemplateSchema,
  MessageTemplateSchema,
  NoteTemplateSchema,
} from "common/schemas";
import {
  CampaignMetrics,
  CampaignType,
  PaymentMethodTypes,
  SubscriptionStatus,
  TemplateType,
  UserRole,
} from "common/types";
import { TagVariantType } from "common/components/ui/Tag";
import { tailwindTheme } from "common/helpers/utils";

import VisaIcon from "assets/icons/paymentCards/visa.svg?react";
import JcbIcon from "assets/icons/paymentCards/jcb.svg?react";
import AmexIcon from "assets/icons/paymentCards/americanExpress.svg?react";
import DiscoverIcon from "assets/icons/paymentCards/discover.svg?react";
import DinersIcon from "assets/icons/paymentCards/dinnersClub.svg?react";
import MastercardIcon from "assets/icons/paymentCards/mastercard.svg?react";
import PaypalIcon from "assets/icons/paymentCards/paypal.svg?react";
import ApplePayIcon from "assets/icons/paymentCards/applePay.svg?react";

export const campaignTypes: Record<
  CampaignType,
  { icon: Icon; label: string }
> = {
  search: { icon: MagnifyingGlass, label: "Search" },
  post: { icon: Image, label: "Post" },
  event: { icon: CalendarDots, label: "Event" },
  group: { icon: UsersThree, label: "Group" },
  list: { icon: Files, label: "List" },
  ai: { icon: Sparkle, label: "AI" },
};
export const templateTypes: Record<
  TemplateType,
  {
    icon: Icon;
    validationSchema:
      | typeof InmailTemplateSchema
      | typeof NoteTemplateSchema
      | typeof MessageTemplateSchema;
    flowValidationSchema:
      | typeof FlowInmailTemplateSchema
      | typeof FlowNoteTemplateSchema
      | typeof FlowMessageTemplateSchema;
  }
> = {
  NOTE_TEMPLATE: {
    icon: NoteBlank,
    validationSchema: NoteTemplateSchema,
    flowValidationSchema: FlowNoteTemplateSchema,
  },
  MESSAGE_TEMPLATE: {
    icon: Chat,
    validationSchema: MessageTemplateSchema,
    flowValidationSchema: FlowMessageTemplateSchema,
  },
  INMAIL_TEMPLATE: {
    icon: Envelope,
    validationSchema: InmailTemplateSchema,
    flowValidationSchema: FlowInmailTemplateSchema,
  },
} as const;
export const subscriptionStatuses: Record<SubscriptionStatus, string> = {
  in_trial: "Trial",
  active: "Active",
  non_renewing: "Non Renewing",
  cancelled: "Cancelled",
} as const;

export const allMetrics: {
  [key in keyof CampaignMetrics]: {
    label: string;
    tooltipText: string;
    icon: Icon;
    className: { background: string; icon: string; stroke: string };
    selectedClassName: { icon: string; button: string };
    gradientColor: string;
  };
} = {
  views: {
    label: "Profile Views",
    tooltipText:
      "The number of LinkedIn accounts you viewed as part of Aimfox campaigns",
    icon: Eye,
    className: {
      background: "bg-orange-100",
      icon: "text-orange-600",
      stroke: "stroke-orange-600",
    },
    selectedClassName: { button: "bg-orange-300", icon: "text-orange-300" },
    gradientColor: tailwindTheme.colors.orange["300"],
  },
  sent_connections: {
    label: "Invites",
    tooltipText:
      "The number of connection invites you sent with Aimfox. This includes both invites with and without a note",
    icon: EnvelopeSimpleOpen,
    className: {
      background: "bg-magenta-100",
      icon: "text-magenta-600",
      stroke: "stroke-magenta-600",
    },
    selectedClassName: { button: "bg-magenta-300", icon: "text-magenta-300" },
    gradientColor: tailwindTheme.colors.magenta["300"],
  },
  accepted_connections: {
    label: "Accepted",
    tooltipText:
      "How many of your connection invites were accepted. Everyone who accepts your connection request is considered a lead",
    icon: Check,
    className: {
      background: "bg-green-100",
      icon: "text-green-600",
      stroke: "stroke-green-600",
    },
    selectedClassName: { button: "bg-green-300", icon: "text-green-300" },
    gradientColor: tailwindTheme.colors.green["300"],
  },
  message_requests: {
    label: "Message Requests",
    tooltipText:
      "The number of message requests you sent with Aimfox campaigns. Message requests are initial messages sent in group message and event message campaigns",
    icon: PaperPlaneTilt,
    className: {
      background: "bg-black-100",
      icon: "text-black-950",
      stroke: "stroke-black-950",
    },
    selectedClassName: { button: "bg-black-300", icon: "text-black-300" },
    gradientColor: "",
  },
  sent_messages: {
    label: "Messages",
    tooltipText:
      "The number of direct messages you sent with Aimfox. This stat shows your follow-up messages",
    icon: Chat,
    className: {
      background: "bg-blue-100",
      icon: "text-blue-600",
      stroke: "stroke-blue-600",
    },
    selectedClassName: { button: "bg-blue-300", icon: "text-blue-300" },
    gradientColor: tailwindTheme.colors.blue["300"],
  },
  sent_inmails: {
    label: "InMails",
    tooltipText:
      "How many free inmails you sent out with Aimfox. We do not count free, or other inmails you sent out manually",
    icon: Envelope,
    className: {
      background: "bg-purple-100",
      icon: "text-purple-600",
      stroke: "stroke-purple-600",
    },
    selectedClassName: { button: "bg-purple-300", icon: "text-purple-300" },
    gradientColor: tailwindTheme.colors.purple["300"],
  },
  replies: {
    label: "Replies",
    tooltipText:
      "How many replies you received to your inmails, messages and message requests",
    icon: ArrowUUpLeft,
    className: {
      background: "bg-yellow-100",
      icon: "text-yellow-600",
      stroke: "stroke-yellow-600",
    },
    selectedClassName: { button: "bg-yellow-300", icon: "text-yellow-300" },
    gradientColor: tailwindTheme.colors.yellow["300"],
  },
};
export const paymentMethods: Record<
  PaymentMethodTypes,
  {
    label: string;
    name: "card" | "paypal" | "apple-pay";
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }
> = {
  card: { label: "Credit Card", name: "card" },
  paypal_express_checkout: {
    label: "PayPal",
    name: "paypal",
    icon: PaypalIcon,
  },
  apple_pay: { label: "Apple Pay", name: "apple-pay", icon: ApplePayIcon },
};
export const cardIcons: {
  [cardType: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  visa: VisaIcon,
  jcb: JcbIcon,
  american_express: AmexIcon,
  discover: DiscoverIcon,
  diners_club: DinersIcon,
  mastercard: MastercardIcon,
};
export const templateVariables = [
  { label: "First Name", string: "{{FIRST_NAME}}" },
  { label: "Last Name", string: "{{LAST_NAME}}" },
  { label: "Occupation", string: "{{OCCUPATION}}" },
  { label: "Location", string: "{{LOCATION}}" },
] as const;
export const userRoles: Record<
  UserRole,
  {
    label: string;
    variant: TagVariantType;
  }
> = {
  owner: {
    label: "Owner",
    variant: "primary",
  },
  admin: {
    label: "Admin",
    variant: "secondary",
  },
  member: {
    label: "Member",
    variant: "tertiary",
  },
} as const;
