import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useGTM from "common/hooks/useGTM";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useAppStore from "common/hooks/useAppStore";
import EnterPin from "common/components/Layout/LinkedInLogin/EnterPin";
import ThisWasMeChallenge from "common/components/Layout/LinkedInLogin/ThisWasMeChallenge";
import { LoginSchema, LoginSchemaType } from "common/schemas";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import Login from "./Login";

export default function LinkedInLogin() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { sendEvent } = useGTM();
  const isOpen = useAppStore((state) => state.isShowingLogin);
  const selectedSubscription = useSelectedSubscriptionContext();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<"login" | "pin" | "thiswasme">(
    "login",
  );
  const formReturn = useForm<LoginSchemaType>({
    defaultValues: {
      email: selectedSubscription.account.email
        ? selectedSubscription.account.email
        : "",
      password: "",
    },
    resolver: valibotResolver(LoginSchema),
  });

  function onAppChallengeFail() {
    setCurrentStep("login");
    formReturn.setError("password", {
      message: "Error resolving app challenge",
    });
  }
  function onSuccess() {
    sendEvent("connect_linkedin_account_success");
    useAppStore.getState().setIsShowingLogin(false);
  }

  const steps = {
    login: {
      title: t("linkedinLoginTitle", { ns: "common" }),
      subtitle: t("linkedinLoginSubtitle", { ns: "common" }),
      mainComponent: (
        <Login
          subscription={selectedSubscription}
          onSuccess={onSuccess}
          formReturn={formReturn}
          setStep={setCurrentStep}
        />
      ),
    },
    pin: {
      title: "Enter your PIN",
      subtitle:
        "You received a pin from LinkedIn via SMS, email or authenticator app. Please enter it below to connect your LinkedIn account",
      mainComponent: (
        <EnterPin
          subscriptionId={selectedSubscription.id}
          email={formReturn.getValues("email")}
          onSuccess={() => useAppStore.getState().setIsShowingLogin(false)}
        />
      ),
    },
    thiswasme: {
      title: "Confirm your login",
      subtitle:
        "LinkedIn wants to make sure this is an authorized login to your account. Please go to your LinkedIn app and confirm this connection",
      mainComponent: (
        <ThisWasMeChallenge
          email={formReturn.getValues("email")}
          onSuccess={() => useAppStore.getState().setIsShowingLogin(false)}
          onAppChallengeFail={onAppChallengeFail}
        />
      ),
    },
  };
  const { title, subtitle, mainComponent } = steps[currentStep];
  const content = (
    <>
      <h2 className="mb-2 text-center text-headline-2xl-bold">{title}</h2>
      <p className="text-center text-body-14-regular text-black-500">
        {subtitle}
      </p>
      {mainComponent}
    </>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          useAppStore.getState().setIsShowingLogin(false);
        }
      }}
    >
      <Content className="lg:w-[540px]">{content}</Content>
    </Component>
  );
}
