import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Label } from "common/types";

import useUpdateLead from "./useUpdateLead";

async function addLabel(accountId: number, profileId: string, label: Label) {
  await post(`accounts/${accountId}/leads/${profileId}/labels`, {
    labels: [label.id],
  });
}
async function removeLabel(accountId: number, profileId: string, label: Label) {
  await del(`accounts/${accountId}/leads/${profileId}/labels/${label.id}`);
}

export default function useLeadLabels() {
  const { account } = useSelectedSubscriptionContext();
  const updateLead = useUpdateLead(account);

  const { mutateAsync: addLeadLabel, isPending: isAddingLeadLabel } =
    useMutation({
      mutationFn: ({ profileId, label }: { profileId: string; label: Label }) =>
        addLabel(account?.id, profileId, label),
      onMutate: async ({ profileId, label }) => {
        await updateLead(profileId, (lead) => {
          lead.labels.push(label);
        });
      },
      onError: async (error, { profileId, label }) => {
        await updateLead(profileId, (draftLead) => {
          draftLead.labels = draftLead.labels.filter(
            ({ id }) => label.id !== id,
          );
        });
        toast.error("Error adding tag");
      },
    });
  const { mutateAsync: removeLeadLabel, isPending: isRemovingLeadLabel } =
    useMutation({
      mutationFn: ({ profileId, label }: { profileId: string; label: Label }) =>
        removeLabel(account?.id, profileId, label),
      onMutate: async ({ profileId, label }) => {
        await updateLead(profileId, (draftLead) => {
          draftLead.labels = draftLead.labels.filter(
            ({ id }) => id !== label.id,
          );
        });
        return label;
      },
      onError: async (error, { profileId, label }) => {
        await updateLead(profileId, (lead) => {
          lead.labels.push(label);
        });
        toast.error("Error removing tag");
      },
    });
  return {
    addLeadLabel,
    isAddingLeadLabel,
    removeLeadLabel,
    isRemovingLeadLabel,
  };
}
