import React, { ReactNode, useState } from "react";
import { CaretDown, Plus } from "@phosphor-icons/react";
import clsx from "clsx";

import usePaymentSources from "common/datahooks/usePaymentSources";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import useCustomer from "common/datahooks/useCustomer";
import { PaymentSource } from "common/types";
import { paymentMethods, cardIcons } from "common/constants";

import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";
import Skeleton from "./ui/Skeleton";

export default function PaymentMethodSelection({
  isCompactComponent = false,
  onAddPaymentMethod,
  onSelectPaymentSource,
}: {
  isCompactComponent?: boolean;
  onAddPaymentMethod: () => void;
  onSelectPaymentSource: (paymentSource: PaymentSource) => void;
}) {
  const { customerInfo } = useCustomer();
  const { paymentSources, isLoadingPaymentSources } = usePaymentSources({
    shouldIncludeSubscriptionId: false,
  });
  const { primaryPaymentSource } = usePrimaryPaymentSource();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  let defaultPaymentMethod = primaryPaymentSource;
  if (!primaryPaymentSource && paymentSources?.length) {
    defaultPaymentMethod = paymentSources.find(
      (paymentSource) => paymentSource.id === customerInfo.payment_source_id,
    );
  }

  let iconComponent: ReactNode = null;
  if (defaultPaymentMethod) {
    const Icon =
      defaultPaymentMethod.type === "card"
        ? cardIcons[defaultPaymentMethod.card.brand]
        : paymentMethods[defaultPaymentMethod.type].icon;

    iconComponent = <Icon className="size-5" />;
  }

  const addPaymentMethodButtonComponent = (
    <button
      type="button"
      className="flex h-full items-center justify-start gap-2 rounded-2xl border border-dashed border-black-300 px-4 py-2"
      onClick={onAddPaymentMethod}
    >
      <div className="rounded-full bg-black-900 p-2">
        <Plus color="white" size={20} />
      </div>

      <span className="text-body-14-bold">Add Payment Method</span>
    </button>
  );

  function getPaymentMethodOptionsComponent() {
    if (isLoadingPaymentSources)
      return <Skeleton className="h-[54px] w-full max-w-[616px] rounded-2xl" />;

    if (!paymentSources?.length) return null;

    return (
      <div className="flex max-h-[310px] flex-col overflow-y-auto scrollbar-thin">
        {paymentSources.map((paymentSource) => {
          const Icon =
            paymentSource.type === "card"
              ? cardIcons[paymentSource.card.brand]
              : paymentMethods[paymentSource.type].icon;

          const isSelected = defaultPaymentMethod.id === paymentSource.id;

          function onClick() {
            onSelectPaymentSource(paymentSource);
            if (isCompactComponent) {
              setIsPopoverOpen(false);
            }
          }

          return (
            <button
              className={clsx(
                "mb-2 flex w-full items-center justify-start gap-3 rounded-2xl p-3 transition-all duration-100 ease-linear last:mb-0",
                isSelected
                  ? "border-[3px] border-purple-200 bg-purple-50"
                  : "bg-black-100",
              )}
              key={paymentSource.id}
              onClick={onClick}
              type="button"
            >
              <Icon />
              <span className="text-body-14-bold">
                {paymentSource.type === "card"
                  ? `Card ending with ${paymentSource.card.last4}`
                  : paymentSource.paypal.email}
              </span>
            </button>
          );
        })}
      </div>
    );
  }

  if (isCompactComponent)
    return (
      <>
        <span className="text-black-700">Payment Method:</span>

        {paymentSources?.length ? (
          <Popover modal open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <PopoverTrigger className="outline-3 group flex w-full flex-row items-center justify-between rounded-2xl bg-black-100 p-4">
              <div className="flex items-center gap-3">
                {iconComponent}

                <span className="text-button-16 text-black-700">
                  {defaultPaymentMethod.type === "card"
                    ? `Card ending with ${defaultPaymentMethod.card.last4}`
                    : defaultPaymentMethod.paypal.email}
                </span>
              </div>

              <CaretDown
                size={20}
                className="fill-black-700 transition-transform group-data-[state=open]:rotate-180"
              />
            </PopoverTrigger>

            <PopoverContent className="z-aboveDialog flex w-[--radix-popper-anchor-width] flex-col gap-2 p-4">
              {getPaymentMethodOptionsComponent()}
              {addPaymentMethodButtonComponent}
            </PopoverContent>
          </Popover>
        ) : (
          addPaymentMethodButtonComponent
        )}
      </>
    );

  return (
    <>
      <div className="flex flex-col gap-2">
        <h3 className="text-headline-xl-bold">
          Select your preferred payment method
        </h3>

        <span className="mb-4 text-black-400">
          Set a preferred payment method for your Aimfox subscription. You can
          change this at any time.
        </span>
      </div>

      <div className="flex flex-col gap-2">
        {getPaymentMethodOptionsComponent()}
        {addPaymentMethodButtonComponent}
      </div>
    </>
  );
}
