import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { toast } from "react-toastify";
import { Plus } from "@phosphor-icons/react";

import { EmailSchema } from "common/schemas";
import { HTTPError } from "common/helpers/HTTP";
import { UserRole } from "common/types";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Input } from "common/components/ui/Input";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { typedObjectEntries } from "common/helpers/utils";
import { userRoles } from "common/constants";
import Switcher from "common/components/ui/Switcher";

import useSubscriptionMembers from "../../datahooks/useSubscriptionMembers";

import InviteMemberImage from "assets/images/invite-member.png";

export default function InviteMember() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const {
    inviteMember,
    isInvitingMember,
    inviteMemberError,
    resetInviteMember,
  } = useSubscriptionMembers();
  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: valibotResolver(EmailSchema),
  });

  const [selectedRole, setSelectedRole] = useState<UserRole>("member");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const filteredRolesArray = typedObjectEntries(userRoles).filter(
    ([role]) => role !== "owner",
  );

  const roleOptions = filteredRolesArray.map(([role, { label }]) => ({
    label,
    value: role,
  }));

  function resetStates() {
    resetForm();
    resetInviteMember();
  }

  function onSubmit({ email }: { email: string }): void {
    inviteMember({
      email,
      role: selectedRole,
    }).then(() => {
      setIsDialogOpen(false);
      toast.success("Invite successfully sent");
      resetStates();
    });
  }

  let errorMessage: string;

  if (inviteMemberError) {
    if (
      inviteMemberError instanceof HTTPError &&
      inviteMemberError.type === "CustomerIsAlreadyMember"
    ) {
      errorMessage = "User is already a member of this workspace";
    } else {
      errorMessage = "Error inviting member, please try again later";
    }
  }

  const content = (
    <form
      className="flex flex-col items-center justify-center gap-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <img
        src={InviteMemberImage}
        width={364}
        height={266}
        className="mx-auto"
        alt="Invite Member"
      />

      <div className="flex flex-col items-center gap-2">
        <h3 className="text-headline-2xl-bold">Invite a member</h3>

        <span className="text-center text-black-500">
          Invite someone to this workspace. They will get an email invite with a
          link to join you
        </span>
      </div>

      <Switcher
        onOptionSelect={(value) => setSelectedRole(value as UserRole)}
        options={roleOptions}
        selectedOption={selectedRole}
      />

      <div className="flex w-full flex-col gap-4">
        <Input
          {...register("email", { onChange: resetInviteMember })}
          error={errorMessage || errors.email?.message}
          placeholder="Teammate email"
          aria-label="Teammate email address"
          className="w-full"
          variant="lg"
        />

        <Button
          type="submit"
          size="lg"
          className="w-full"
          isLoading={isInvitingMember}
        >
          Invite
        </Button>
      </div>
    </form>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isDialogOpen}
      onOpenChange={(isOpen) => setIsDialogOpen(isOpen)}
      onAfterClose={resetStates}
    >
      <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
        {(isDisabled) => (
          <Trigger asChild>
            <Button leftIcon={<Plus />} disabled={isDisabled} size="md">
              {isTabletOrDesktop ? "Add Member" : "Add"}
            </Button>
          </Trigger>
        )}
      </RestrictedComponent>

      <Content className="px-4 py-6 max-md:h-dvh md:max-w-[540px] md:px-8 md:py-10">
        {content}
      </Content>
    </Component>
  );
}
