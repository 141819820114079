import { LeadsSearchParams, SelectedFacetParam } from "../../pages/leads/types";
import {
  MetricsParameters,
  ProfileSearchParams,
  SubscriptionAccount,
} from "../types";

export default function getQueryKeys(account: SubscriptionAccount) {
  const { id: accountId, state: accountState } = account;

  const queryKeys = {
    leadsKeys: {
      base: [{ accountId, accountState }, "leads"],
      list: (searchParams?: LeadsSearchParams<SelectedFacetParam>) => {
        const base = [...queryKeys.leadsKeys.base, "list"];
        return searchParams ? [...base, searchParams] : base;
      },
      details: (leadId?: string) => {
        const base = [...queryKeys.leadsKeys.base, "details"];
        return leadId ? [...base, leadId] : base;
      },
      recent: (campaignId?: string) => {
        const base = [...queryKeys.leadsKeys.base, "recent"];
        return campaignId ? [...base, campaignId] : base;
      },
      conversationUrn: (leadId: string) => [
        ...queryKeys.leadsKeys.base,
        "conversationUrn",
        leadId,
      ],
    },
    campaignsKeys: {
      base: [{ accountId, accountState }, "campaigns"],
      list: () => [...queryKeys.campaignsKeys.base, "list"],
      details: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "details",
        campaignId,
      ],
      targets: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "targets",
        campaignId,
      ],
      targeting: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "targeting",
        campaignId,
      ],
      targetingStats: (campaignId: string) => [
        ...queryKeys.campaignsKeys.base,
        "targetingStats",
        campaignId,
      ],
    },
    conversationsKeys: {
      base: [{ accountId, accountState }, "conversations"],
      list: (keywords?: string) => {
        const base = [...queryKeys.conversationsKeys.base, "list"];
        return keywords ? [...base, keywords] : base;
      },
      messages: (conversationUrn: string) => [
        ...queryKeys.conversationsKeys.base,
        "messages",
        conversationUrn,
      ],
    },
    labelsKeys: {
      list: () => [{ accountId, accountState }, "labels"],
    },
    templatesKeys: {
      list: () => [{ accountId, accountState }, "templates"],
    },
    blacklistKeys: {
      list: () => [{ accountId, accountState }, "blacklist"],
    },
    profileSearchKeys: {
      list: (searchParams: Partial<ProfileSearchParams>) => [
        { accountId, accountState },
        "profileSearch",
        searchParams,
      ],
    },
    metricsKeys: {
      list: (
        parameters: MetricsParameters,
        campaignId?: string,
        flowId?: string,
      ) => {
        const base = [{ accountId, accountState }, "metrics", parameters];
        if (campaignId) return [...base, campaignId];
        if (flowId) return [...base, flowId];
        return base;
      },
    },
    paymentSourceKeys: {
      list: () => ["paymentSources"],
      primary: () => [{ accountId }, "primaryPaymentSource"],
    },
  };
  return queryKeys;
}
