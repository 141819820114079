import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { Conversation } from "../types";

const itemsPerPage = 15;

async function getConversations(
  accountId: number,
  keywords: string,
  before: number | "now",
): Promise<Conversation[]> {
  const searchParams = new URLSearchParams();
  searchParams.append("count", itemsPerPage.toString());
  searchParams.append(
    "before",
    (before === "now" ? Date.now() : before).toString(),
  );

  if (keywords) {
    searchParams.append("keywords", keywords);
  }
  return (
    await get(`accounts/${accountId}/conversations?${searchParams.toString()}`)
  ).conversations;
}
export default function useConversations(
  {
    keywords,
    versionTag,
  }: {
    keywords?: string;
    versionTag: string;
  } = { versionTag: "" },
) {
  const { account } = useSelectedSubscriptionContext();

  const { conversationsKeys } = getQueryKeys(account);

  const {
    isLoading: isLoadingConversations,
    data: conversations,
    fetchNextPage: loadNextConversations,
    hasNextPage: hasMoreConversations,
    error: conversationsError,
    refetch: refetchConversations,
  } = useInfiniteQuery<Conversation[], number | "now">({
    queryKey: conversationsKeys.list(keywords),
    queryFn: ({ pageParam }) =>
      getConversations(account?.id, keywords, pageParam as number | "now"),
    refetchOnMount: false,
    initialPageParam: "now",
    getNextPageParam: (lastPage) => {
      if (lastPage.length) {
        return lastPage[lastPage.length - 1].last_message.created_at;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (versionTag) {
      refetchConversations();
    }
  }, [versionTag]);

  return {
    conversations: conversations?.pages.flat() as Conversation[],
    isLoadingConversations,
    loadNextConversations,
    hasMoreConversations,
    conversationsError,
    refetchConversations,
  };
}
