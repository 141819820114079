import React from "react";

import { SubscriptionAccount } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { Tag } from "common/components/ui/Tag";
import { userRoles } from "common/constants";

interface AccountPreviewProps {
  account: SubscriptionAccount;
  role: "owner" | "member" | "admin";
}

export default function AccountPreview({ account, role }: AccountPreviewProps) {
  const roleData = userRoles[role];
  const { label, variant } = roleData || {};

  return (
    <div className="flex flex-row gap-4">
      <ProfileImage src={account.picture_url} />

      <div className="flex flex-col gap-0.5">
        <span className="flex flex-row items-center gap-2 text-body-14-bold">
          {account.full_name}

          <Tag variant={variant} className="capitalize">
            {label}
          </Tag>
        </span>

        <span className="line-clamp-1 max-w-[40ch] text-start text-caption-12-regular text-black-500">
          {account.occupation}
        </span>
      </div>
    </div>
  );
}
