import React, {
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from "react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const tableVariants = cva("w-full", {
  variants: {
    variant: {
      list: "-my-4 border-separate border-spacing-y-4",
      simple: "border-collapse",
    },
  },
  defaultVariants: {
    variant: "list",
  },
});

const tableCellVariants = cva(
  "py-3 align-middle first:pl-3 first:text-left last:pr-3 last:text-right",
  {
    variants: {
      variant: {
        list: "border-y border-black-200 text-body-14-regular first:rounded-l-2xl first:border-l last:rounded-r-2xl last:border-r ",
        simple: "",
      },
    },
    defaultVariants: {
      variant: "list",
    },
  },
);

const tableRowVariants = cva("text-center text-caption-12-regular", {
  variants: {
    variant: {
      list: "rounded-2xl bg-white transition-shadow hover:relative hover:z-10 hover:cursor-pointer hover:shadow-md",
      simple: "border-b border-black-200",
    },
  },
  defaultVariants: {
    variant: "list",
  },
});

const TableVariantContext = createContext<VariantProps<typeof tableVariants>>({
  variant: "list",
});

type TableProps = HTMLAttributes<HTMLTableElement> &
  VariantProps<typeof tableVariants>;

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, variant, ...props }, ref) => {
    const variants = useMemo(() => ({ variant }), []);

    return (
      <TableVariantContext.Provider value={variants}>
        <table
          ref={ref}
          className={cn(tableVariants({ variant, className }))}
          {...props}
        />
      </TableVariantContext.Provider>
    );
  },
);
Table.displayName = "Table";

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("[&_tr]:h-8 bg-black-100", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableHead = forwardRef<
  HTMLTableCellElement,
  ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "first:text-left first:rounded-l-xl first:pl-3 last:text-right last:pr-3 last:rounded-r-xl text-center text-caption-12-regular text-black-700 align-middle",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableRow = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => {
  const { variant } = useContext(TableVariantContext);

  return (
    <tr
      ref={ref}
      className={cn(tableRowVariants({ variant, className }))}
      {...props}
    />
  );
});
TableRow.displayName = "TableRow";

const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => {
  const { variant } = useContext(TableVariantContext);

  return (
    <td
      ref={ref}
      className={cn(tableCellVariants({ variant, className }))}
      {...props}
    />
  );
});
TableCell.displayName = "TableCell";

export { Table, TableHeader, TableHead, TableRow, TableCell };
