import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import Hotjar from "@hotjar/browser";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import usePaymentSources from "common/datahooks/usePaymentSources";
import useCustomer from "common/datahooks/useCustomer";

export default function useSubscriptionChangeUpdates() {
  const selectedSubscription = useSelectedSubscriptionContext();
  const { customerInfo } = useCustomer();
  const { paymentSources } = usePaymentSources({
    shouldIncludeSubscriptionId: true,
  });
  const { update } = useIntercom();

  useEffect(() => {
    if (selectedSubscription && paymentSources) {
      const primaryPaymentSource = paymentSources.find(
        ({ subscription_id }) => subscription_id === selectedSubscription.id,
      );
      let paymentMethod = "No payment method";
      if (primaryPaymentSource) {
        paymentMethod =
          primaryPaymentSource.type === "card"
            ? `Card ending in ${primaryPaymentSource.card.last4}`
            : `Paypal email ${primaryPaymentSource.paypal.email}`;
      }
      update({
        customAttributes: {
          "LN Username": `https://www.linkedin.com/in/${selectedSubscription.account.urn}/`,
          "LN id": selectedSubscription.account.id,
          "Login status": selectedSubscription.account.state,
          "Subscription Status": selectedSubscription.status,
          "Next Billing Date": new Date(
            selectedSubscription.next_billing * 1000,
          ).toISOString(),
          "Payment Method": paymentMethod,
        },
      });
      Sentry.setTag("linkedin id", selectedSubscription.account.id);
      Hotjar.identify(customerInfo.id, {
        "LN Username": `https://www.linkedin.com/in/${selectedSubscription.account.urn}/`,
      });
    }
  }, [selectedSubscription]);
}
