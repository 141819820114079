import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { DetailedCampaign } from "../types";

async function getCampaignRequest(accountId: number, campaignId: string) {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}`)).campaign;
}
export default function useCampaignDetails(campaignId: string) {
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);

  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    refetch: refetchCampaign,
    error: campaignError,
  } = useQuery<DetailedCampaign>({
    queryKey: campaignsKeys.details(campaignId),
    queryFn: () => getCampaignRequest(account?.id, campaignId),
    enabled: !!campaignId,
  });

  return {
    campaign,
    campaignError,
    isLoadingCampaign,
    refetchCampaign,
  };
}
