import React from "react";

import { Button } from "common/components/ui/Button";

import { CampaignFlow, CampaignFlowType } from "../../../../types";
import { flowTypeOptions } from "../../../../constants";

export default function OverviewCardHeading({ flow }: { flow: CampaignFlow }) {
  const headingOptions: Record<
    CampaignFlowType,
    {
      title: string;
      description: string;
    }
  > = {
    CONNECT_OPTIMIZATION: {
      title: "Connect",
      description: "Performance details for the Connect Flow",
    },
    INMAIL_OPTIMIZATION: {
      title: "InMail",
      description: "Performance details for the InMail Flow",
    },
    PRIMARY_CONNECT: {
      title: flow.name,
      description: `Performance details for ${flow.name}`,
    },
    PRIMARY_MESSAGE: {
      title: flow.name,
      description: `Performance details for ${flow.name}`,
    },
  };

  const { title, description } = headingOptions[flow.type];
  const { buttonVariant, icon: Icon } = flowTypeOptions[flow.type];

  return (
    <div className="-mx-3 flex gap-3 border-b border-black-200 px-3 pb-4 md:-mx-4 md:px-4">
      <Button intent="labelIcon" variant={buttonVariant} size="lg">
        <Icon />
      </Button>
      <div>
        <h2 className="text-headline-lg-bold">{title}</h2>
        <p className="text-black-500">{description}</p>
      </div>
    </div>
  );
}
