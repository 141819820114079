import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { formatDate } from "common/helpers/utils";

import useBilling from "../../datahooks/useBilling";
import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";
import PaymentMethodInfo from "./PaymentMethodInfo";

export default function BillingHistory() {
  const { invoices, isLoadingInvoices } = useBilling();
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Table = isTabletOrDesktop ? DesktopTable : MobileList;

  const { next_billing: nextBilling, plan } = useSelectedSubscriptionContext();
  const getNextBillingDate = () => formatDate(nextBilling * 1000);

  return (
    <>
      <PaymentMethodInfo />

      <section className="flex flex-col items-start justify-between md:flex-row md:items-center">
        {/* Billing history title */}
        <div className="flex flex-col px-3 py-6 md:px-0">
          <span className="text-body-16-bold">Billing history</span>
          <span className="text-black-500">Download previous invoices</span>
        </div>

        {/* Next billing date & price */}
        <div className="flex w-full flex-row justify-between gap-0 rounded-2xl bg-black-50 p-4 md:w-auto md:justify-start md:gap-11 md:bg-transparent">
          <div className="flex w-2/3 flex-col md:w-auto">
            <span className="text-black-500">Next billing date</span>
            <span className="text-body-16-bold">{getNextBillingDate()}</span>
          </div>

          <div className="flex w-2/6 flex-col md:w-auto">
            <span className="text-black-500">Amount</span>
            <span className="text-body-16-bold">
              ${plan.pricing.price / 100}
            </span>
          </div>
        </div>
      </section>

      {/* Table content */}
      <Table invoices={invoices ?? []} isLoading={isLoadingInvoices} />
    </>
  );
}
