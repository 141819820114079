import React, { MouseEvent, useRef } from "react";
import { Check, LinkedinLogo } from "@phosphor-icons/react";
import clsx from "clsx";

import { getProfileSubtitle } from "common/helpers/utils";
import { LinkedinProfile } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";

import { Target } from "../../../../types";

interface TargetProfileProps {
  result: Target;
  isSelected: boolean;
  toggleSelection: (profile: LinkedinProfile) => void;
}

export default function TargetProfile({
  result,
  isSelected,
  toggleSelection,
}: TargetProfileProps) {
  const linkedinButtonRef = useRef(null);

  const {
    picture_url: pictureUrl,
    full_name: fullName,
    public_identifier: publicIdentifier,
  } = result;

  function onClick(e: MouseEvent) {
    if (!linkedinButtonRef.current.contains(e.target)) {
      toggleSelection(result);
    }
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        "flex w-full items-center gap-3.5 rounded-20 border border-black-200 p-4 transition-colors",
        isSelected ? "bg-purple-50" : "bg-white hover:bg-black-100",
      )}
    >
      <span
        className={clsx(
          "-ml-1.5 size-4 rounded border-2 border-black-400 transition-colors",
          isSelected && "border-black-950 bg-black-950",
        )}
      >
        <Check size={12} className="fill-white" />
      </span>

      <ProfileImage src={pictureUrl} size="lg" />

      <div className="flex flex-1 flex-col gap-1">
        <h5 className="line-clamp-1 text-left">{fullName}</h5>
        <p className="line-clamp-1 break-all text-left text-black-500">
          {getProfileSubtitle(result)}
        </p>
      </div>

      <Button intent="iconOnly" variant="quaternary-black" asChild>
        <a
          href={`https://linkedin.com/in/${publicIdentifier}`}
          ref={linkedinButtonRef}
          aria-label="LinkedIn Profile Url"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinLogo weight="fill" />
        </a>
      </Button>
    </button>
  );
}
