import React from "react";

import useCustomer from "common/datahooks/useCustomer";
import Avatar from "common/components/ui/Avatar";

export default function AccountInfo() {
  const { customerInfo } = useCustomer();
  const { first_name: firstName, last_name: lastName, email } = customerInfo;

  function getNameDetails() {
    const initials = `${firstName[0]}${lastName[0]}`;
    const fullName = `${firstName} ${lastName}`;

    return { initials, fullName };
  }

  return (
    <div className="mt-4 flex w-full flex-row gap-4 rounded-20 border border-black-200 bg-whiteGray p-3">
      <Avatar size="lg">{getNameDetails().initials}</Avatar>

      <div className="flex flex-col justify-center">
        <span className="w-full max-w-[256px] overflow-hidden truncate text-body-16-bold">
          {getNameDetails().fullName}
        </span>

        <span className="w-full max-w-[256px] overflow-hidden truncate text-caption-12-regular text-black-500">
          {email}
        </span>
        {/* Todo: Check if we can somehow get occupation and display it instead email */}
      </div>
    </div>
  );
}
