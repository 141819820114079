import React from "react";
import { UsersThree } from "@phosphor-icons/react";

import Progress from "common/components/ui/Progress";
import { Tag } from "common/components/ui/Tag";

interface TargetsProgressProps {
  progress: number;
  targetsCount: number;
  audienceSize: number;
}

export default function TargetsProgress({
  progress,
  targetsCount,
  audienceSize,
}: TargetsProgressProps) {
  return (
    <article className="z-10 rounded-2xl bg-white max-md:mb-4 md:fixed md:bottom-28 md:right-6 md:w-96 md:border md:border-purple-500 md:p-4 md:shadow-lg">
      <div className="mb-2 flex items-center text-black-700">
        <UsersThree weight="fill" size={16} />
        <span className="mx-1.5 text-button-12">Targets</span>
        <Tag variant="secondary">{progress}%</Tag>
        <p className="ml-auto text-button-12">
          {targetsCount}/<span className="text-black-400">{audienceSize}</span>
        </p>
      </div>
      <Progress value={progress} />
    </article>
  );
}
