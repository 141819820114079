import React from "react";
import { CreditCard } from "@phosphor-icons/react";
import clsx from "clsx";

import { PaymentSource } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import RenderIf from "common/components/RenderIf";

interface PaymentMethodLabelProps {
  isCompact: boolean;
  paymentSource: PaymentSource;
  isLoading: boolean;
}

export default function PaymentMethodLabel({
  isCompact,
  paymentSource,
  isLoading,
}: PaymentMethodLabelProps) {
  let cardText = "No Billing Method";
  // let iconComponent;

  if (isLoading) {
    return (
      <Skeleton
        className={clsx(
          "h-[18px] rounded-20",
          isCompact ? "w-[100px]" : "w-[140px]",
        )}
      />
    );
  }

  // To Do: Refactor this code when design system is extended with new set of card icons
  if (paymentSource) {
    if (!paymentSource.type) {
      return;
    }

    if (paymentSource.type === "card") {
      const { last4 } = paymentSource.card;
      cardText = isCompact ? `**** - ${last4}` : `card ending in ${last4}`;
      // iconComponent = cardIcons[brand];
    } else {
      cardText = paymentSource.paypal.email;
      // iconComponent = paymentMethods[paymentSource.type].icon;
    }
  }

  return (
    <div className="flex flex-row gap-1">
      <RenderIf condition={paymentSource?.type === "card"}>
        <CreditCard size={20} weight="fill" />
      </RenderIf>

      <span className="text-body-14-bold">{cardText}</span>
    </div>
  );
}
