import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { clsx } from "clsx";

import useInvitations from "common/datahooks/useInvitations";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import InvitationItem from "common/components/Layout/InvitationItem";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Tag } from "common/components/ui/Tag";
import { userRoles } from "common/constants";
import SubscriptionSelectionItem from "common/components/Layout/SubscriptionSelectionItem";
import ProfileImage from "common/components/ui/ProfileImage";
import RenderIf from "common/components/RenderIf";
import SearchInput from "common/components/SearchInput";

import WorkspaceInvitation from "../../WorkspaceInvitation";

const scrollThreshold = 4;
export default function SubscriptionsPopup({
  isExpanded,
}: {
  isExpanded: boolean;
}) {
  const selectedSubscription = useSelectedSubscriptionContext();
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const { invitations } = useInvitations();
  const { subscriptions } = useSubscriptionSelection();
  const [isOpen, setIsOpen] = useState(false);
  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const invitationRef = useRef(null);

  const {
    account: { full_name: fullName, occupation, picture_url: pictureUrl },
    role,
  } = selectedSubscription;

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const unselectedSubscriptions = subscriptions.filter(
    ({ id }) => id !== selectedSubscription.id,
  );

  const lowercasedInput = inputValue.toLowerCase();
  const filteredSubscriptions = subscriptions.filter(
    ({ id, account: { full_name } }) =>
      id !== selectedSubscription.id &&
      full_name.toLowerCase().includes(lowercasedInput),
  );

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger
          className={clsx(
            "mt-auto flex items-center justify-start gap-x-3 rounded-2xl transition-[border,padding] duration-200",
            isExpanded && "border border-black-700 px-3 py-2",
          )}
        >
          <ProfileImage size="md" src={pictureUrl} key={pictureUrl} />
          <div
            className={clsx(
              "flex flex-col items-start gap-y-1 overflow-hidden transition-opacity duration-300",
              isExpanded ? "opacity-100" : "opacity-0",
            )}
          >
            <div className="flex w-full items-center gap-x-1 text-button-14 text-white">
              <span className="truncate">{fullName}</span>
              <Tag variant={userRoles[role].variant}>
                {userRoles[role].label}
              </Tag>
            </div>
            <span className="w-full truncate text-left text-caption-10-semibold text-black-500">
              {occupation}
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col p-4" side="right" align="end">
          <div className="flex gap-x-2 p-1">
            <ProfileImage src={pictureUrl} size="md" />
            <div className="flex flex-col gap-y-0.5 overflow-hidden">
              <div className="flex items-center justify-between gap-x-2">
                <span className="truncate text-body-14-bold">{fullName}</span>
                <Tag variant={userRoles[role].variant}>
                  {userRoles[role].label}
                </Tag>
              </div>
              <span className="truncate text-caption-10-semibold text-black-400">
                {occupation}
              </span>
            </div>
          </div>
          <RenderIf condition={!!unselectedSubscriptions.length}>
            <div className="my-2 h-px bg-black-200" />
            <div className="mb-2 flex items-center gap-x-2">
              <span className="text-caption-12-regular text-black-400">
                Workspaces
              </span>
              {unselectedSubscriptions.length > scrollThreshold && (
                <Tag variant="primary-black">{subscriptions.length}</Tag>
              )}
            </div>
            <RenderIf
              condition={unselectedSubscriptions.length > scrollThreshold}
            >
              <SearchInput
                variant="sm"
                placeholder="Search workspaces"
                className="mb-2"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onClear={() => setInputValue("")}
              />
            </RenderIf>
            <div
              className={clsx(
                "flex flex-col gap-y-1 overflow-y-auto scrollbar-thin",
                subscriptions.length > scrollThreshold && "h-48",
              )}
            >
              {!!inputValue && !filteredSubscriptions.length ? (
                <p className="mx-4 break-words py-8 text-center text-black-500">
                  No results for{" "}
                  <span className="text-black-950">{inputValue}</span>
                </p>
              ) : (
                filteredSubscriptions.map((subscription) => (
                  <SubscriptionSelectionItem
                    key={subscription.id}
                    subscription={subscription}
                  />
                ))
              )}
            </div>
          </RenderIf>
          {!!invitations && !!invitations.length && (
            <>
              <div className="my-2 h-px bg-black-200" />
              <div className="mb-1 flex items-center gap-x-2">
                <span className="text-caption-12-regular text-black-400">
                  Invites
                </span>
                {invitations.length > scrollThreshold && (
                  <Tag variant="magenta">{invitations.length}</Tag>
                )}
              </div>
              <div className="flex max-h-48 flex-col gap-y-1 overflow-y-auto scrollbar-thin">
                {invitations.map((invitation) => (
                  <InvitationItem
                    invitation={invitation}
                    openInvitation={() => {
                      setIsInvitationOpen(true);
                      invitationRef.current = invitation;
                    }}
                    key={invitation.token}
                  />
                ))}
              </div>
            </>
          )}
        </PopoverContent>
      </Popover>
      <WorkspaceInvitation
        invitation={invitationRef.current}
        isOpen={isInvitationOpen}
        onClose={() => setIsInvitationOpen(false)}
        onResponse={() => setIsInvitationOpen(false)}
      />
    </>
  );
}
