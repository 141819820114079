import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { del, post } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import getQueryKeys from "common/datahooks/getQueryKeys";

import useUpdateCampaign from "./useUpdateCampaign";
import { CampaignFlow } from "../types";

async function postSecondFlowRequest(
  accountId: number,
  campaignId: string,
  flow: CampaignFlow,
): Promise<CampaignFlow> {
  return (
    await post(`accounts/${accountId}/campaigns/${campaignId}/flows`, flow)
  ).flow;
}

async function deleteFlowRequest(
  accountId: number,
  campaignId: string,
  flowId: string,
) {
  await del(`accounts/${accountId}/campaigns/${campaignId}/flows/${flowId}`);
  return { flowId };
}

export default function useCampaignFlows() {
  const campaignId = useCampaignIdContext();
  const queryClient = useQueryClient();
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);
  const { updateCachedCampaign } = useUpdateCampaign(account);

  const { mutateAsync: addSecondFlow, isPending: isAddingSecondFlow } =
    useMutation({
      mutationFn: ({ flow }: { flow: CampaignFlow }) =>
        postSecondFlowRequest(account.id, campaignId, flow),
      onSuccess: (flow) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.flows.push(flow);
        });
      },
    });

  const { mutateAsync: deleteCampaignFlow, isPending: isDeletingCampaignFlow } =
    useMutation({
      mutationFn: ({ flowId }: { flowId: string }) =>
        deleteFlowRequest(account.id, campaignId, flowId),
      onMutate: async ({ flowId }) => {
        await queryClient.cancelQueries({
          queryKey: campaignsKeys.details(campaignId),
        });

        const previousCampaign = queryClient.getQueryData(
          campaignsKeys.details(campaignId),
        );

        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.flows = draftCampaign.flows.filter(
            (flow) => flow.id !== flowId,
          );
        });

        return { previousCampaign };
      },
      onError: (_, __, { previousCampaign }) => {
        queryClient.setQueryData(
          campaignsKeys.details(campaignId),
          previousCampaign,
        );

        toast.error("Error deleting flow");
      },
    });

  return {
    addSecondFlow,
    isAddingSecondFlow,
    deleteCampaignFlow,
    isDeletingCampaignFlow,
  };
}
