import { useQuery } from "@tanstack/react-query";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { get } from "../helpers/HTTP";
import { RecentLead } from "../types";
import getQueryKeys from "./getQueryKeys";

async function getRecentLeads(
  accountId: number,
  campaignId: string,
  flowId: string,
): Promise<RecentLead[]> {
  const searchParams = new URLSearchParams();

  if (campaignId) {
    searchParams.append("campaign_id", campaignId);
  }

  if (flowId) {
    searchParams.append("flow_id", flowId);
  }

  return (
    await get(`accounts/${accountId}/leads/recent?${searchParams.toString()}`)
  ).leads;
}
export default function useRecentLeads({
  flowId,
  campaignId,
}: {
  flowId: string;
  campaignId: string;
}) {
  const { account } = useSelectedSubscriptionContext();

  const { leadsKeys } = getQueryKeys(account);

  const {
    data: recentLeads,
    isLoading: isLoadingRecentLeads,
    error: recentLeadsError,
    refetch: refetchRecentLeads,
  } = useQuery({
    queryKey: leadsKeys.recent(campaignId),
    queryFn: () => getRecentLeads(account?.id, campaignId, flowId),
  });

  return {
    recentLeads,
    isLoadingRecentLeads,
    recentLeadsError,
    refetchRecentLeads,
  };
}
