import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

import useAppStore from "common/hooks/useAppStore";
import useCurrentBaseRoute from "common/hooks/useCurrentBaseRoute";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import useSubscriptionChangeUpdates from "common/hooks/useSubscriptionChangeUpdates";
import useNavItems from "common/hooks/useNavItems";
import useGTM from "common/hooks/useGTM";
import usePageTitle from "common/hooks/usePageTitle";
import ToastContainer from "common/components/Layout/ToastContainer";
import AppSumoDialog from "common/components/Layout/AppSumoDialog";

import WorkspaceInvitation from "./WorkspaceInvitation";
import LinkedInLogin from "./LinkedInLogin";
import useInvitation from "../../datahooks/useInvitation";
import AccountDisconnected from "./AccountDisconnected";
import Banners from "./Banners";
import WebPushPrompt from "./WebPushPrompt";
import Layout from "./Layout";

export default function SubscriptionContainer() {
  useSubscriptionChangeUpdates();
  const { sendEvent } = useGTM();
  const baseRoute = useCurrentBaseRoute();
  const location = useLocation();
  const selectedSubscription = useSelectedSubscriptionContext();
  const navItems = useNavItems(baseRoute.path.includes("account"));
  const headerContent = useAppStore((state) => state.headerContent);
  const pageTitle = usePageTitle();
  const [searchParams, setSearchParams] = useSearchParams();
  const { invitation } = useInvitation(searchParams.get("invite_token"));
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  const [isAppSumoDialogOpen, setIsAppSumoDialogOpen] = useState(
    !!searchParams.get("code"),
  );

  useEffect(() => {
    if (invitation) {
      setIsInvitationDialogOpen(true);
    }
  }, [invitation]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    if (baseRoute.shouldHideDialogs) {
      useAppStore.getState().setIsShowingLogin(false);
    } else if (selectedSubscription.status === "cancelled") {
      useAppStore.getState().setIsShowingLogin(false);
    } else if (selectedSubscription.account.state === "NeverLoggedIn") {
      useAppStore.getState().setIsShowingLogin(true);
    } else if (
      selectedSubscription.account.state === "LoggedIn" ||
      selectedSubscription.account.state === "LoggedOut"
    ) {
      useAppStore.getState().setIsShowingLogin(false);
    }
  }, [baseRoute]);

  useEffect(() => {
    const pagePath = selectedSubscription
      ? location.pathname.replace(`/${selectedSubscription.id}`, "")
      : location.pathname;

    if (pagePath) {
      sendEvent("virtualPageView", () => ({
        pageUrl: window.location.href,
        pagePath,
      }));
    }
  }, [location]);

  return (
    <Layout
      navItems={navItems}
      headerTitle={(headerContent && headerContent.title) || pageTitle}
      bannerComponent={!baseRoute.shouldHideBanners && <Banners />}
    >
      <Outlet />
      <LinkedInLogin />
      <AccountDisconnected />
      <WebPushPrompt />
      <WorkspaceInvitation
        invitation={invitation}
        isOpen={isInvitationDialogOpen}
        onResponse={() => {
          setIsInvitationDialogOpen(false);
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.delete("invite_token");
            return newParams;
          });
        }}
      />
      <AppSumoDialog
        isOpen={isAppSumoDialogOpen}
        code={searchParams.get("code")}
        onClose={() => {
          setIsAppSumoDialogOpen(false);
          setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.delete("code");
            return newParams;
          });
        }}
      />
      <ToastContainer />
    </Layout>
  );
}
