import React, { useState } from "react";

import useCustomer from "common/datahooks/useCustomer";

import UpdateNameEdit from "./UpdateNameEdit";
import AccountSettingsItem from "../AccountSettingsItem";

export default function UpdateName() {
  const [isEditingName, setIsEditingName] = useState(false);
  const { customerInfo } = useCustomer();

  function getFullName() {
    const { first_name: firstName, last_name: lastName } = customerInfo;
    return `${firstName} ${lastName}`;
  }

  return (
    <AccountSettingsItem
      label="Full Name"
      isEditing={isEditingName}
      onEditClick={() => {
        setIsEditingName(true);
      }}
    >
      {!isEditingName ? (
        <span className="text-body-14-bold">{getFullName()}</span>
      ) : (
        <UpdateNameEdit
          onClose={() => {
            setIsEditingName(false);
          }}
        />
      )}
    </AccountSettingsItem>
  );
}
