import {
  useNavigate,
  NavigateOptions,
  useParams,
  useLocation,
} from "react-router-dom";

// this function navigates relative to the selected subscription id
// i.e: 'home' becomes :subscriptionId/home
export default function useRelativeNavigate() {
  const { subscriptionId } = useParams();
  const originalNavigate = useNavigate();
  const location = useLocation();

  function relativeNavigate(to: string, options?: NavigateOptions) {
    if (!location.pathname.endsWith(to)) {
      originalNavigate(`/${subscriptionId}/${to}`, options);
    }
  }
  return relativeNavigate;
}
