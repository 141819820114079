import React from "react";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { Lifebuoy } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useAppStore from "common/hooks/useAppStore";

import AccountPopup from "./AccountPopup";
import DebugPopup from "./DebugPopup";
import NotificationsPopup from "./NotificationsPopup";

export default function DesktopHeader({
  headerTitle,
}: {
  headerTitle: string;
}) {
  const { pathname } = useLocation();
  const { show } = useIntercom();
  const headerContent = useAppStore((state) => state.headerContent);

  return (
    <header className="flex h-20 w-full items-center justify-between border-b border-b-black-200 px-4">
      <div className="flex items-center gap-x-1.5">
        <h1 className="text-headline-xl-bold capitalize text-black-950">
          {headerTitle}
        </h1>
        {headerContent && headerContent.component}
      </div>
      <div className="flex items-center gap-x-0.5">
        <Button
          size="lg"
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="support"
          onClick={show}
        >
          <Lifebuoy weight="fill" />
        </Button>
        {import.meta.env.MODE !== "production" && <DebugPopup />}
        {!pathname.endsWith("notifications") && <NotificationsPopup />}
        <AccountPopup />
      </div>
    </header>
  );
}
