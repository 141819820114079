import React from "react";
import {
  LinkSimple,
  LinkedinLogo,
  Chat,
  MinusCircle,
} from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Switcher from "common/components/ui/Switcher";
import Alert from "common/components/ui/Alert";
import RenderIf from "common/components/RenderIf";

import ItemContent from "./ItemContent";

const baseMethodOptions = [
  {
    value: "off",
    label: "None",
    icon: <MinusCircle />,
  },
  {
    value: "connect",
    label: "Connect",
    icon: <LinkSimple />,
  },
  {
    value: "message",
    label: "Message",
    icon: <Chat />,
  },
];

type PrimaryMethodType = "connect" | "message" | "off";

interface PrimaryMethodProps {
  isGroupOrEvent: boolean;
  primaryMethod: PrimaryMethodType;
  setPrimaryMethod: (primaryMethod: PrimaryMethodType) => void;
}

export default function PrimaryMethod({
  primaryMethod,
  setPrimaryMethod,
  isGroupOrEvent,
}: PrimaryMethodProps) {
  const translationKey = isGroupOrEvent ? "connectOrMessage" : "connect";

  const primaryMethodOptions = baseMethodOptions.filter((option) =>
    isGroupOrEvent ? true : option.value !== "message",
  );

  const { t } = useTranslation("campaigns", {
    keyPrefix: "primaryMethods",
  });

  return (
    <>
      <section
        className={clsx(
          "flex flex-col justify-between gap-4 pt-6 md:flex-row md:gap-6",
          primaryMethod !== "off" && "border-b border-b-black-200 pb-6",
        )}
      >
        <ItemContent
          icon={LinkedinLogo}
          title={t(`${translationKey}Title`)}
          description={t(`${translationKey}Description`)}
          infoText={t(`${translationKey}InfoText`)}
        />
        <Switcher
          className="max-md:ml-16"
          size="sm"
          options={primaryMethodOptions}
          selectedOption={primaryMethod}
          onOptionSelect={(option) =>
            setPrimaryMethod(option as PrimaryMethodType)
          }
        />
      </section>
      <RenderIf condition={primaryMethod === "off"}>
        <div className="border-b border-b-black-200 pb-6 pt-4 md:pt-5">
          <Alert
            variant="warning"
            description={t(`${translationKey}Warning`)}
          />
        </div>
      </RenderIf>
    </>
  );
}
