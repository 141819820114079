import React from "react";
import { ToastContainer as ReactToastifyContainer } from "react-toastify";
import { cva } from "class-variance-authority";
import { Info, X } from "@phosphor-icons/react";

const toastVariants = cva(
  "mb-2 flex items-center gap-x-2.5 rounded-xl border bg-white p-4 text-button-16 text-black-950 shadow-lg last:mb-0",
  {
    variants: {
      type: {
        default: "border-black-200",
        success: "border-green-400",
        error: "border-red-400",
        warning: "border-yellow-400",
        info: "",
      },
    },
  },
);
const iconVariants = cva("text-black-950", {
  variants: {
    type: {
      default: "",
      success: "text-green-500",
      error: "text-red-500",
      warning: "",
      info: "",
    },
  },
});
export default function ToastContainer() {
  return (
    <ReactToastifyContainer
      className="top-24 [&_.Toastify\\_\\_toast-icon]:m-0 [&_.Toastify\\_\\_toast-icon]:w-auto"
      position="top-right"
      autoClose={3000}
      hideProgressBar
      /* eslint-disable-next-line react/no-unstable-nested-components */
      icon={({ type }) => (
        <Info weight="fill" size={24} className={iconVariants({ type })} />
      )}
      /* eslint-disable-next-line react/no-unstable-nested-components */
      closeButton={({ closeToast }) => (
        <button
          onClick={closeToast}
          type="button"
          aria-label="close toast"
          className="ml-auto"
        >
          <X size={16} className="text-black-700" />
        </button>
      )}
      toastClassName={({ type }) => toastVariants({ type })}
      // this is a function to ensure that the library's classname is not applied
      bodyClassName={() => "flex gap-x-2.5"}
    />
  );
}
