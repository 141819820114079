import tailwindAnimatePlugin from "tailwindcss-animate";
import tailwindScrollbar from "tailwind-scrollbar";

import type { Config } from "tailwindcss";

export default {
  content: ["./src/**/*.{ts,tsx}"],
  theme: {
    fontFamily: {
      manrope: ["Manrope", "Arial", "sans-serif"],
    },
    colors: {
      white: "#FFFFFF",
      whiteGray: "#F8F9FB",
      transparent: "transparent",
      linkedinBlue: "#0077B5",
      black: {
        "50": "#F9FAFB",
        "100": "#F3F4F6",
        "200": "#E5E7EB",
        "300": "#D1D5DB",
        "400": "#9CA3AF",
        "500": "#6B7280",
        "600": "#4B5563",
        "700": "#374151",
        "800": "#242D3D",
        "900": "#111827",
        "950": "#030712",
      },
      orange: {
        "50": "#FFFBFA",
        "100": "#FFF0E0",
        "200": "#FFD7B3",
        "300": "#FFB87A",
        "400": "#FF8E42",
        "500": "#FF6E1A",
        "600": "#FF4D00",
        "700": "#D43302",
        "800": "#AE2B0A",
        "900": "#8E250B",
        "950": "#531104",
      },
      purple: {
        "50": "#EEEEFF",
        "100": "#E0E1FF",
        "200": "#C7C6FF",
        "300": "#A7A2FF",
        "400": "#8A7AFF",
        "500": "#7755FF",
        "600": "#642CFF",
        "700": "#5718EA",
        "800": "#4515BE",
        "900": "#381997",
        "950": "#230E58",
      },

      // Accent
      green: {
        "50": "#EFFEF2",
        "100": "#DAFEE3",
        "200": "#B7FBC8",
        "300": "#86EFAC",
        "400": "#4ADE80",
        "500": "#16D148",
        "600": "#0DAC37",
      },
      yellow: {
        "50": "#FFFBEB",
        "100": "#FFF4C6",
        "200": "#FFE788",
        "300": "#FFD54A",
        "400": "#FFC120",
        "500": "#FA9F06",
        "600": "#DF7700",
      },
      blue: {
        "50": "#EFFBFF",
        "100": "#DAF5FF",
        "200": "#BEEDFF",
        "300": "#91DEFF",
        "400": "#5BC6FF",
        "500": "#32A3FF",
        "600": "#197DF7",
      },
      magenta: {
        "50": "#FEF4FF",
        "100": "#FDE8FF",
        "200": "#FBCFFF",
        "300": "#FCA8FF",
        "400": "#FD73FF",
        "500": "#F939FC",
        "600": "#E316E3",
      },
      red: {
        "50": "#FEF1F1",
        "100": "#FEDCDC",
        "200": "#FEB9B9",
        "300": "#FD9191",
        "400": "#FA5C5C",
        "500": "#F42525",
        "600": "#CA2121",
      },
    },
    fontSize: {
      // Headline
      "headline-lg-regular": [
        "1.125rem",
        {
          fontWeight: 500,
          lineHeight: "1.75rem",
        },
      ],
      "headline-lg-bold": [
        "1.125rem",
        {
          fontWeight: 700,
          lineHeight: "1.75rem",
        },
      ],
      "headline-xl-regular": [
        "1.25rem",
        {
          fontWeight: 500,
          lineHeight: "1.75rem",
        },
      ],
      "headline-xl-bold": [
        "1.25rem",
        {
          fontWeight: 700,
          lineHeight: "1.75rem",
        },
      ],
      "headline-2xl-regular": [
        "1.5rem",
        {
          fontWeight: 500,
          lineHeight: "2rem",
        },
      ],
      "headline-2xl-bold": [
        "1.5rem",
        {
          fontWeight: 700,
          lineHeight: "2rem",
        },
      ],
      "headline-3xl-regular": [
        "1.875rem",
        {
          fontWeight: 500,
          lineHeight: "2.25rem",
        },
      ],
      "headline-3xl-bold": [
        "1.875rem",
        {
          fontWeight: 700,
          lineHeight: "2.25rem",
        },
      ],
      "headline-4xl-regular": [
        "2.25rem",
        {
          fontWeight: 500,
          lineHeight: "2.5rem",
        },
      ],
      "headline-4xl-bold": [
        "2.25rem",
        {
          fontWeight: 700,
          lineHeight: "2.5rem",
        },
      ],
      "headline-5xl-regular": [
        "3rem",
        {
          fontWeight: 500,
          lineHeight: "3rem",
        },
      ],
      "headline-5xl-bold": [
        "3rem",
        {
          fontWeight: 700,
          lineHeight: "3rem",
        },
      ],

      // Body
      "body-16-regular": [
        "1rem",
        {
          fontWeight: 500,
          lineHeight: "1.5rem",
        },
      ],
      "body-16-bold": [
        "1rem",
        {
          fontWeight: 700,
          lineHeight: "1.5rem",
        },
      ],
      "body-14-regular": [
        "0.875rem",
        {
          fontWeight: 400,
          lineHeight: "1.25rem",
        },
      ],
      "body-14-bold": [
        "0.875rem",
        {
          fontWeight: 700,
          lineHeight: "normal",
        },
      ],

      // Caption
      "caption-12-regular": [
        "0.75rem",
        {
          fontWeight: 400,
          lineHeight: "normal",
        },
      ],
      "caption-12-bold": [
        "0.75rem",
        {
          fontWeight: 700,
          lineHeight: "normal",
        },
      ],
      "caption-10-semibold": [
        "0.625rem",
        {
          lineHeight: "normal",
          fontWeight: 600,
        },
      ],
      "caption-10-bold": [
        "0.625rem",
        {
          lineHeight: "normal",
          fontWeight: 700,
        },
      ],
      "caption-10-regular": [
        "0.625rem",
        {
          fontWeight: 400,
          lineHeight: "normal",
        },
      ],

      // Button
      "button-12": [
        "0.75rem",
        {
          fontWeight: 600,
          lineHeight: "normal",
        },
      ],
      "button-14": [
        "0.875rem",
        {
          fontWeight: 600,
          lineHeight: "normal",
        },
      ],
      "button-16": [
        "1rem",
        {
          fontWeight: 600,
          lineHeight: "normal",
        },
      ],
    },
    boxShadow: {
      sm: "0px 4px 6px -1px rgba(17, 24, 39, 0.06)",
      md: "0px 3px 6px 2px rgba(17, 24, 39, 0.10)",
      lg: "0px 10px 15px 0px rgba(17, 24, 39, 0.10)",
      xl: "0px 20px 25px 0px rgba(17, 24, 39, 0.10)",
      "2xl": "0px 25px 50px 0px rgba(17, 24, 39, 0.25)",
    },
    extend: {
      backgroundImage: {
        blackPurple:
          "linear-gradient(159deg, #040519 13.64%, #4921BA 44.52%, #662DFF 62.65%, #B092FF 96.59%);",
        whitePurple:
          "linear-gradient(157deg, rgba(255, 255, 255, 0.20) -4.8%, rgba(176, 146, 255, 0.20) 35.49%, rgba(102, 45, 255, 0.20) 72.24%, rgba(73, 33, 186, 0.20) 96.29%)",
      },
      borderRadius: {
        20: "20px",
      },
      zIndex: {
        nav: "51",
        popover: "52",
        tooltip: "53",
        bottomNav: "54",
        overlay: "55",
        dialog: "56",
        aboveDialog: "57",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        "caret-blink": {
          "0%,70%,100%": { opacity: "1" },
          "20%,50%": { opacity: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "caret-blink": "caret-blink 1s ease-out infinite",
      },
    },
  },
  plugins: [tailwindAnimatePlugin, tailwindScrollbar],
} satisfies Config;
