import React, { useState } from "react";
import { Play } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import RenderIf from "common/components/RenderIf";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import { Button } from "common/components/ui/Button";

import { DetailedCampaign } from "../../types";
import {
  CampaignActionsDialog,
  CampaignActionsDropdown,
} from "../CampaignActions";
import { campaignSubtypeOptions, campaignStates } from "../../constants";

export default function CampaignInfo({
  campaign,
}: {
  campaign: DetailedCampaign;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { type, name, subtype, state } = campaign;

  const {
    icon: SubtypeIcon,
    tagVariant: subtypeTagVariant,
    label: subtypeLabel,
  } = campaignSubtypeOptions[subtype];
  const { label: stateLabel, tagVariant: stateTagVariant } =
    campaignStates[state];

  const stateTag = <Tag variant={stateTagVariant}>{stateLabel}</Tag>;
  const subtypeTag = (
    <Tag variant={subtypeTagVariant} leftIcon={<SubtypeIcon />}>
      {subtypeLabel}
    </Tag>
  );
  const startCampaignButton = (
    <RenderIf condition={state === "PAUSED" || state === "CREATED"}>
      <Button
        variant="primary-purple"
        leftIcon={<Play weight="fill" />}
        className="max-md:mb-4 md:mr-4"
        onClick={() => setIsOpen(true)}
      >
        Start campaign
      </Button>
    </RenderIf>
  );

  return (
    <>
      <section className="mb-4 flex items-center rounded-2xl border border-black-200 px-3 py-4 md:px-4">
        <CampaignTypeIcon type={type} size="lg" />

        <ConditionalWrapper
          condition={!isTabletOrDesktop}
          renderWrapper={(children) => (
            <div className="ml-3 w-[calc(100%_-_133px)]">{children}</div>
          )}
        >
          <h1 className="break-words text-button-16 md:mx-3">{name}</h1>

          <RenderIf condition={!isTabletOrDesktop}>
            <div className="mt-1 flex items-center gap-2">
              {subtypeTag} {stateTag}
            </div>
          </RenderIf>
        </ConditionalWrapper>

        <RenderIf condition={isTabletOrDesktop}>{subtypeTag}</RenderIf>

        <div className="flex flex-1 items-center justify-end">
          <RenderIf condition={isTabletOrDesktop}>{stateTag}</RenderIf>
          <span className="mx-4 h-10 w-px bg-black-200" />
          <RenderIf condition={isTabletOrDesktop}>
            {startCampaignButton}
          </RenderIf>
          <CampaignActionsDropdown campaign={campaign} isDetailedView />
        </div>
      </section>
      <RenderIf condition={!isTabletOrDesktop}>{startCampaignButton}</RenderIf>
      <CampaignActionsDialog
        campaign={campaign}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="start"
      />
    </>
  );
}
