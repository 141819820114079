import { useQuery } from "@tanstack/react-query";

import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

async function getTargetsToken(
  accountId: number,
  campaignId: string,
): Promise<string> {
  return (
    await get(`accounts/${accountId}/campaigns/${campaignId}/targets/token`)
  ).token;
}

export default function useTargetsToken() {
  const campaignId = useCampaignIdContext();
  const { account } = useSelectedSubscriptionContext();

  const { data: targetsToken } = useQuery({
    queryKey: ["targets_token", campaignId],
    queryFn: () => getTargetsToken(account.id, campaignId),
  });

  return { targetsToken };
}
