import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import RenderIf from "common/components/RenderIf";
import { CampaignType } from "common/types";
import { typedObjectEntries } from "common/helpers/utils";
import { campaignTypes } from "common/constants";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface CampaignTypesProps {
  type: CampaignType;
  setType: Dispatch<SetStateAction<CampaignType>>;
  goToNextStep: () => void;
}

export default function CampaignTypes({
  type,
  setType,
  goToNextStep,
}: CampaignTypesProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns", {
    keyPrefix: "campaignTypes",
  });

  return (
    <>
      <h2 className="-mx-4 mb-6 border-b border-b-black-300 px-4 pb-5 text-headline-xl-bold">
        Create a new campaign
      </h2>

      <div className="grid gap-3 md:grid-cols-2">
        {typedObjectEntries(campaignTypes).map(([key, { label }]) => {
          const isSelected = type === key;
          const isComingSoon = key === "ai";

          return (
            <button
              type="button"
              key={key}
              onClick={() => setType(key)}
              disabled={isComingSoon}
              className={clsx(
                "flex gap-3 rounded-20 border border-black-300 px-3 py-4 transition-all",
                isSelected && "bg-purple-50",
                isComingSoon &&
                  "pointer-events-none border-whiteGray bg-whiteGray",
                !isSelected && !isComingSoon && "hover:shadow-md",
              )}
            >
              <CampaignTypeIcon type={key} size="lg" />
              <div className="flex flex-col gap-0.5 text-left">
                <ConditionalWrapper
                  condition={isComingSoon}
                  renderWrapper={(children) => (
                    <div className="flex gap-1">{children}</div>
                  )}
                >
                  <h3
                    className={clsx(
                      "text-button-16 capitalize",
                      isComingSoon && "text-black-500",
                    )}
                  >
                    {label} campaign
                  </h3>
                  <RenderIf condition={isComingSoon}>
                    <Tag variant="gradient">Coming soon</Tag>
                  </RenderIf>
                </ConditionalWrapper>
                <p className="text-caption-12-regular text-black-500">
                  {t(`${key}Description`)}
                </p>
              </div>
            </button>
          );
        })}
      </div>

      <Button
        className="mt-12 self-end"
        rightIcon={<CaretRight />}
        size={isTabletOrDesktop ? "lg" : "md"}
        onClick={goToNextStep}
      >
        Next
      </Button>
    </>
  );
}
