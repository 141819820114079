import { useQuery } from "@tanstack/react-query";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { get } from "../helpers/HTTP";
import { CreatedTemplate } from "../types";
import getQueryKeys from "./getQueryKeys";

async function getTemplates(accountId: number) {
  return (await get(`accounts/${accountId}/templates`)).templates;
}

export default function useTemplates() {
  const { account } = useSelectedSubscriptionContext();
  const { templatesKeys } = getQueryKeys(account);

  const {
    isLoading: isLoadingAllTemplates,
    data: allTemplates,
    error: templatesError,
    refetch: refetchTemplates,
  } = useQuery<CreatedTemplate[]>({
    queryKey: templatesKeys.list(),
    queryFn: () => getTemplates(account?.id),
  });

  return {
    allTemplates,
    isLoadingAllTemplates,
    templatesError,
    refetchTemplates,
  };
}
