import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Campaign } from "common/types";

async function getCampaigns(accountId: number): Promise<Campaign[]> {
  const campaigns = (await get(`accounts/${accountId}/campaigns`))
    .campaigns as Campaign[];
  return campaigns.sort(
    (campaignA, campaignB) => campaignB.created_at - campaignA.created_at,
  );
}

export default function useCampaigns() {
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);

  const {
    data: campaigns,
    isLoading: isLoadingCampaigns,
    error: campaignsError,
    refetch: refetchCampaigns,
  } = useQuery<Campaign[]>({
    queryKey: campaignsKeys.list(),
    queryFn: () => getCampaigns(account?.id),
  });
  return {
    campaigns,
    campaignsError,
    isLoadingCampaigns,
    refetchCampaigns,
  };
}
