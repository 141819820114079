import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  object,
  string,
  trim,
  pipe,
} from "valibot";

export const MessageTemplateSchema = object({
  delay: number(),
  message: pipe(
    string(),
    trim(),
    minLength(1, "Message is required"),
    maxLength(1900, "Message can't be more than 1900 characters long"),
  ),
});

export const CampaignNameSchema = object({
  name: pipe(
    string(),
    trim(),
    minLength(1, "Name is required"),
    maxLength(50, "Campaign name can't be more than 50 characters long"),
  ),
});

export const FlowNameSchema = object({
  name: pipe(
    string(),
    trim(),
    minLength(1, "Flow name is required"),
    maxLength(20, "Flow name can't be more than 20 characters long"),
  ),
});
export const TargetsLimitSchema = object({
  limit: pipe(
    number("Invalid value"),
    minValue(1, "Limit is required"),
    maxValue(1000, "Limit is 1000"),
  ),
});
