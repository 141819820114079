import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import RenderIf from "common/components/RenderIf";
import Alert from "common/components/ui/Alert";
import { Button } from "common/components/ui/Button";
import UpgradeDialog from "common/components/UpgradeDialog";

import useCampaignMutations from "../../../datahooks/useCampaignMutations";

interface CampaignStartProps {
  isModalOpened: boolean;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;
}

export default function CampaignStart({
  isModalOpened,
  setIsModalOpened,
}: CampaignStartProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const campaignId = useCampaignIdContext();
  const selectedSubscription = useSelectedSubscriptionContext();
  const navigate = useNavigate();
  const isStartingNowRef = useRef(true);
  const { updateCampaign, isUpdatingCampaign } = useCampaignMutations();
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  function startCampaign(startNow: boolean = true) {
    updateCampaign({
      campaignId,
      updates: {
        state: startNow ? "ACTIVE" : "CREATED",
      },
    }).then(() => navigate(`../../details`));
  }
  const isCancelledSubscription = selectedSubscription.status === "cancelled";
  const isStartingNow = isStartingNowRef.current;

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <>
      <Component open={isModalOpened} onOpenChange={setIsModalOpened}>
        <Content className="md:w-[500px]">
          <h5 className="mb-2 text-center text-headline-2xl-bold">
            Are you happy with this campaign?
          </h5>

          <p className="text-center text-black-500">
            {isCancelledSubscription
              ? "Outreach campaigns require a LinkedIn Outreach subscription. You can finalize this campaign save it for later, or upgrade to start now"
              : "All the targets you set for this campaign will be locked, and they will not be able to enter any other campaigns in the future"}
          </p>

          <RenderIf condition={!isCancelledSubscription}>
            <Alert
              className="mt-4"
              variant="warning"
              description="Once started, you will not be able to add Notes, InMails or
                Sequences to your campaign. You will be still be able to edit or
                change those that are currently added."
            />
          </RenderIf>

          <Button
            className="mb-2 mt-4"
            onClick={() => {
              isStartingNowRef.current = false;
              startCampaign(false);
            }}
            disabled={isStartingNow && isUpdatingCampaign}
            isLoading={!isStartingNow && isUpdatingCampaign}
          >
            Finalize & Start Later
          </Button>

          <Button
            variant={isCancelledSubscription ? "gradient" : "primary-purple"}
            onClick={
              isCancelledSubscription
                ? () => {
                    setIsModalOpened(false);
                    setIsUpgradeDialogOpen(true);
                  }
                : () => startCampaign()
            }
            disabled={!isStartingNow && isUpdatingCampaign}
            isLoading={isStartingNow && isUpdatingCampaign}
          >
            {isCancelledSubscription ? "Upgrade to Premium" : "Start now"}
          </Button>
        </Content>
      </Component>

      <UpgradeDialog
        isOpen={isUpgradeDialogOpen}
        onOpenChange={setIsUpgradeDialogOpen}
      />
    </>
  );
}
