import React, { useState } from "react";
import clsx from "clsx";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { getMonthName, isRocketHubUser } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import RestrictedComponent from "common/components/RestrictedComponent";
import RenderIf from "common/components/RenderIf";
import UpgradeDialog from "common/components/UpgradeDialog";
import { Button } from "common/components/ui/Button";
import PaidPlanDetails from "common/components/PaidPlanDetails";
import useSubscriptionSettings from "common/datahooks/useSubscriptionSettings";
import Skeleton from "common/components/ui/Skeleton";

import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import RemoveCancellationDialog from "./RemoveCancellationDialog";

export default function PaidPlan() {
  const [isOpen, setIsOpen] = useState(false);
  const { subscriptionSettings, isLoadingSubscriptionSettings } =
    useSubscriptionSettings();
  const {
    id: subscriptionId,
    status,
    next_billing: nextBilling,
    cancelled_at: cancelledAt,
  } = useSelectedSubscriptionContext();

  const nextBillingTimestamp = nextBilling * 1000;
  const expiryDate = new Date(nextBillingTimestamp);

  // This check is needed because when a subscription in trial is cancelled, the status doesn't change to "non_renewing"
  const isCancelledAtInFuture = cancelledAt * 1000 > Date.now();

  let statusComponent;
  let buttonComponent;
  let expiryDateComponent;

  if (status === "cancelled") {
    buttonComponent = (
      <Button variant="gradient" onClick={() => setIsOpen(true)}>
        Subscribe to premium
      </Button>
    );

    statusComponent = (
      <Tag size="lg" className="w-fit" variant="danger">
        Not active
      </Tag>
    );
  } else if (isRocketHubUser(subscriptionSettings)) {
    statusComponent = (
      <Tag size="lg" className="w-fit" variant="success">
        Active (lifetime)
      </Tag>
    );
  } else if (status === "non_renewing" || isCancelledAtInFuture) {
    buttonComponent = (
      <RemoveCancellationDialog
        billingTimestamp={nextBillingTimestamp}
        subscriptionId={subscriptionId}
      />
    );

    statusComponent = (
      <Tag size="lg" className="w-fit" variant="yellow">
        Scheduled for cancellation
      </Tag>
    );

    expiryDateComponent = (
      <span className="text-black-500">
        Expires on {expiryDate.getDate()} {getMonthName(expiryDate.getMonth())}{" "}
        {expiryDate.getFullYear()}
      </span>
    );
  } else {
    const isAppSumo = isLoadingSubscriptionSettings
      ? false
      : subscriptionSettings.coupons?.some((couponCode) =>
          couponCode.startsWith("APPSUMOTIER"),
        );
    buttonComponent = isLoadingSubscriptionSettings ? (
      <Skeleton className="h-10 w-44 rounded-full" />
    ) : (
      !isAppSumo && (
        <CancelSubscriptionDialog
          expirationTimestamp={nextBillingTimestamp}
          subscriptionId={subscriptionId}
        />
      )
    );

    statusComponent = (
      <div className="flex flex-row gap-2">
        <RenderIf condition={status === "in_trial" && !isAppSumo}>
          <Tag size="lg" className="w-fit" variant="primary">
            14 Days Free Trial
          </Tag>
        </RenderIf>

        <Tag size="lg" className="w-fit" variant="success">
          Active
        </Tag>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "relative flex w-full flex-col items-start justify-between gap-4 rounded-2xl border bg-white p-3 pr-6 md:flex-row md:items-center md:gap-0",
        status === "active" || status === "in_trial"
          ? "border-purple-500"
          : "border-black-200",
      )}
    >
      <div className="flex flex-col gap-4">
        {statusComponent}
        <PaidPlanDetails />
      </div>

      {expiryDateComponent}

      <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["admin"]}>
        <RenderIf
          condition={
            !(isRocketHubUser(subscriptionSettings) && status !== "cancelled")
          }
        >
          {buttonComponent}
          <UpgradeDialog isOpen={isOpen} onOpenChange={setIsOpen} />
        </RenderIf>
      </RestrictedComponent>
    </div>
  );
}
