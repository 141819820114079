import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

interface DelaySelectProps {
  label: string;
  isDisabled: boolean;
  options: { value: number; label: string }[];
  onSelect: (value: number) => void;
}

export default function DelaySelect({
  label,
  isDisabled,
  options,
  onSelect,
}: DelaySelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        disabled={isDisabled}
        className="group flex h-12 items-center gap-1.5 rounded-lg p-2 text-black-400 transition-colors data-[state=open]:text-black-950 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50 max-lg:w-full max-lg:bg-whiteGray max-lg:text-button-16 lg:mx-1.5 lg:h-8"
      >
        {label}
        <CaretDown className="ml-auto size-5 transition-transform group-data-[state=open]:rotate-180 lg:size-4" />
      </PopoverTrigger>
      <PopoverContent className="flex max-h-56 w-[--radix-popper-anchor-width] flex-col overflow-y-auto py-2 scrollbar-thin lg:w-fit">
        <div className="flex flex-col">
          {options.map((option) => (
            <button
              key={option.value}
              className="w-full px-4 py-2 text-button-14 transition-colors hover:bg-black-50"
              type="button"
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
