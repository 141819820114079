import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Label } from "common/types";

import getQueryKeys from "./getQueryKeys";

async function getLabels(accountId: number) {
  return (await get(`accounts/${accountId}/labels`)).labels;
}

export default function useAccountLabels() {
  const { account } = useSelectedSubscriptionContext();

  const { labelsKeys } = getQueryKeys(account);

  const { data: accountLabels } = useQuery<Label[]>({
    queryKey: labelsKeys.list(),
    queryFn: () => getLabels(account?.id),
  });

  return {
    accountLabels,
  };
}
