import React from "react";
import { PencilSimple } from "@phosphor-icons/react";
import clsx from "clsx";

import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";
import useInboxDraftStore from "common/hooks/useInboxDraftStore";

import { Conversation } from "../../types";

interface MessageDetailsProps {
  conversation: Conversation;
  isMine: boolean;
}

export default function MessageDetails({
  conversation,
  isMine,
}: MessageDetailsProps) {
  const {
    last_message: lastMessage,
    unread_count: unreadCount,
    participants,
    conversation_urn: conversationUrn,
  } = conversation;

  const {
    deleted_at: deletedAt,
    attachments,
    type,
    subject,
    sender,
    body,
  } = lastMessage;

  const { drafts } = useInboxDraftStore();
  const draftMessage = drafts[conversationUrn];
  const isGroupConversation = participants.length > 1;

  let message = isMine ? (
    <>
      <span className="text-body-14-bold">You: </span>
      {body}
    </>
  ) : (
    body
  );

  if (deletedAt) {
    message = "This message has been deleted";
  }

  if (attachments) {
    message = attachments[0].name;
  }

  if (draftMessage) {
    return (
      <div className="flex flex-row items-center gap-1 text-black-400">
        <PencilSimple />

        <span className="line-clamp-1 break-all text-start">
          Draft: {draftMessage}
        </span>
      </div>
    );
  }

  if (type === "INMAIL") {
    return (
      <div className="flex flex-row items-center gap-2">
        <Tag variant="secondary">InMail</Tag>

        <span className="line-clamp-1 text-start text-body-14-bold">
          {subject}
        </span>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "text-start",
        unreadCount && "text-caption-14-bold",
        deletedAt && "pr-1 italic text-black-400",
      )}
    >
      <RenderIf condition={isGroupConversation && !isMine}>
        <span className="text-black-400">{sender.full_name}: </span>
      </RenderIf>

      <span className="line-clamp-1">{message}</span>
    </div>
  );
}
