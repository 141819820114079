import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { del, patch, post } from "common/helpers/HTTP";
import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { CampaignFlow, FlowMessageTemplate } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";
import { useCampaignFlowContext } from "../context/CampaignFlowContext";

async function addFlowMessageRequest(
  accountId: number,
  campaignId: string,
  flowId: string,
  flowMessage: FlowMessageTemplate,
): Promise<Omit<CampaignFlow, "metrics">> {
  return (
    await post(
      `accounts/${accountId}/campaigns/${campaignId}/flows/${flowId}/messages`,
      flowMessage,
    )
  ).flow;
}
async function deleteLastFlowMessageRequest(
  accountId: number,
  campaignId: string,
  flowId: string,
) {
  await del(
    `accounts/${accountId}/campaigns/${campaignId}/flows/${flowId}/messages`,
  );
  return { flowId };
}

async function updateFlowMessageRequest(
  accountId: number,
  campaignId: string,
  flowId: string,
  index: number,
  updates: Partial<FlowMessageTemplate>,
): Promise<CampaignFlow> {
  return (
    await patch(
      `accounts/${accountId}/campaigns/${campaignId}/flows/${flowId}/messages/${index}`,
      updates,
    )
  ).flow;
}

export default function useFlowMessages() {
  const campaignId = useCampaignIdContext();
  const queryClient = useQueryClient();
  const { account } = useSelectedSubscriptionContext();
  const { campaignsKeys } = getQueryKeys(account);
  const { flow: campaignFlow } = useCampaignFlowContext();
  const { updateCachedCampaign } = useUpdateCampaign(account);

  const { mutateAsync: addFlowMessage, isPending: isAddingFlowMessage } =
    useMutation({
      mutationFn: ({ flowMessage }: { flowMessage: FlowMessageTemplate }) =>
        addFlowMessageRequest(
          account.id,
          campaignId,
          campaignFlow.id,
          flowMessage,
        ),
      onSuccess: (flow) =>
        updateCachedCampaign(campaignId, (draftCampaign) => {
          const flowIndex = draftCampaign.flows.findIndex(
            (draftFlow) => draftFlow.id === flow.id,
          );

          draftCampaign.flows[flowIndex] = {
            ...draftCampaign.flows[flowIndex],
            flow_message_templates: flow.flow_message_templates,
          };
        }),
    });

  const {
    mutateAsync: deleteLastFlowMessage,
    isPending: isDeletingLastFlowMessage,
  } = useMutation({
    mutationFn: () =>
      deleteLastFlowMessageRequest(account.id, campaignId, campaignFlow.id),
    onSuccess: ({ flowId }) =>
      updateCachedCampaign(campaignId, (draftCampaign) => {
        const flowIndex = draftCampaign.flows.findIndex(
          (draftFlows) => draftFlows.id === flowId,
        );

        draftCampaign.flows[flowIndex].flow_message_templates.pop();
      }),
  });

  const { mutateAsync: updateFlowMessage, isPending: isUpdatingFlowMessage } =
    useMutation({
      mutationFn: ({
        index,
        updates,
      }: {
        index: number;
        updates: Partial<FlowMessageTemplate>;
      }) =>
        updateFlowMessageRequest(
          account.id,
          campaignId,
          campaignFlow.id,
          index,
          updates,
        ),
      onMutate: async ({ index, updates }) => {
        await queryClient.cancelQueries({
          queryKey: campaignsKeys.details(campaignId),
        });

        const previousCampaign = queryClient.getQueryData(
          campaignsKeys.details(campaignId),
        );

        updateCachedCampaign(campaignId, (draftCampaign) => {
          const flowIndex = draftCampaign.flows.findIndex(
            (flow) => flow.id === campaignFlow.id,
          );

          draftCampaign.flows[flowIndex].flow_message_templates[index] = {
            ...draftCampaign.flows[flowIndex].flow_message_templates[index],
            ...updates,
          };
        });

        return { previousCampaign };
      },
      onError: (_, { index }, { previousCampaign }) => {
        queryClient.setQueryData(
          campaignsKeys.details(campaignId),
          previousCampaign,
        );

        toast.error(`Error updating flow message #${index}`);
      },
    });

  function addPlaceholderMessage() {
    updateCachedCampaign(campaignId, (draftCampaign) => {
      const flowIndex = draftCampaign.flows.findIndex(
        (flow) => flow.id === campaignFlow.id,
      );

      draftCampaign.flows[flowIndex].flow_message_templates.push({
        ai: false,
        delay: 0,
        edited: false,
        message: "",
        original_id: null,
        type: "MESSAGE_TEMPLATE",
      });
    });
  }

  function removePlaceholderMessage() {
    updateCachedCampaign(campaignId, (draftCampaign) => {
      const flowIndex = draftCampaign.flows.findIndex(
        (flow) => flow.id === campaignFlow.id,
      );

      draftCampaign.flows[flowIndex].flow_message_templates.pop();
    });
  }

  return {
    addFlowMessage,
    isAddingFlowMessage,
    deleteLastFlowMessage,
    isDeletingLastFlowMessage,
    updateFlowMessage,
    isUpdatingFlowMessage,
    // Placeholder actions
    addPlaceholderMessage,
    removePlaceholderMessage,
  };
}
