import { CaretDown, SignOut } from "@phosphor-icons/react";
import React from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import Avatar from "common/components/ui/Avatar";
import { logout } from "common/helpers/auth";
import useCustomer from "common/datahooks/useCustomer";

export default function LogoutPopup() {
  const { customerInfo } = useCustomer();
  const { email, first_name: firstName, last_name: lastName } = customerInfo;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="ml-auto flex max-w-56 items-center gap-x-2 rounded-full bg-black-100 p-2 pl-3 data-[state=open]:bg-black-200">
        <CaretDown className="size-5" />
        <span className="truncate text-button-16">{email}</span>
        <Avatar size="sm">
          {firstName[0]}
          {lastName[0]}
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-aboveDialog">
        <DropdownMenuItem onClick={logout}>
          <SignOut size={20} />
          Log Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
