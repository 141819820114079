import React from "react";
import {
  CardsThree,
  Chat,
  type Icon,
  Sparkle,
  Translate,
} from "@phosphor-icons/react";
import clsx from "clsx";

import useTemplates from "common/datahooks/useTemplates";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { getLanguageName } from "../../../utils";
import { CampaignFlow, CampaignFlowType } from "../../../types";
import { flowTypeOptions } from "../../../constants";

interface ReviewItemProps {
  title: string;
  value: string | number;
  icon: Icon;
  isAiEnabled: boolean;
}

function ReviewItem({
  title,
  value,
  icon: Icon,
  isAiEnabled,
}: ReviewItemProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  function getContent() {
    if (isTabletOrDesktop) {
      return (
        <>
          <Button intent="labelIcon" variant="secondary-black">
            <Icon />
          </Button>

          <span className="text-body-14-bold">{title}</span>

          <RenderIf condition={isAiEnabled}>
            <Tag leftIcon={<Sparkle />} variant="gradient" className="-ml-2">
              AI
            </Tag>
          </RenderIf>
          <span className="ml-auto text-body-14-bold">{value}</span>
        </>
      );
    }

    return (
      <>
        <Button intent="labelIcon" size="sm" variant="secondary-black">
          <Icon />
        </Button>

        <div>
          <div className="flex items-center gap-1">
            <span className="line-clamp-1 text-body-14-bold">{title}</span>
            <RenderIf condition={isAiEnabled}>
              <Tag variant="gradient" className="p-0">
                <Sparkle />
              </Tag>
            </RenderIf>
          </div>
          <span className="ml-auto text-body-14-regular md:text-body-14-bold">
            {value}
          </span>
        </div>
      </>
    );
  }

  return (
    <div className="flex items-center gap-3 border-b border-black-200 p-4 last:border-b-0">
      {getContent()}
    </div>
  );
}

interface FlowReviewProps {
  flow: CampaignFlow;
}

export default function FlowReview({ flow }: FlowReviewProps) {
  const { allTemplates } = useTemplates();
  const { buttonVariant, icon: Icon } = flowTypeOptions[flow.type];

  const flowLabelOptions: Record<
    CampaignFlowType,
    {
      title: string;
      description: string;
    }
  > = {
    INMAIL_OPTIMIZATION: {
      title: "InMail Optimization",
      description: "Free InMail",
    },
    CONNECT_OPTIMIZATION: {
      title: "Connect Optimization",
      description: "Connect Optimization",
    },
    PRIMARY_MESSAGE: {
      title: flow.name,
      description: "A/B Split Test",
    },
    PRIMARY_CONNECT: {
      title: flow.name,
      description: "A/B Split Test",
    },
  };

  const { title, description } = flowLabelOptions[flow.type];

  let templateName = "No template added";
  if (flow.template) {
    templateName = allTemplates?.find(
      (template) => template.id === flow.template.original_id,
    ).name;

    if (flow.template.edited) {
      templateName += " (Edited)";
    }
  }

  const isAiEnabledInMessages = flow.flow_message_templates.some(
    (message) => message.ai,
  );

  let translateTitle = "OFF";
  if (flow.translate) {
    if (flow.source_language) {
      translateTitle = getLanguageName(flow.source_language);
      if (!flow.is_manual_language) {
        translateTitle += " (Detected)";
      }
    } else {
      translateTitle = "ON";
    }
  }

  return (
    <section className="mb-4 overflow-hidden rounded-2xl border border-black-200 last:mb-0">
      {/* Header */}
      <div className="flex gap-4 border-b border-black-200 bg-whiteGray p-4 max-md:flex-col md:items-center">
        <div className="flex gap-3">
          <Button intent="labelIcon" size="lg" variant={buttonVariant}>
            <Icon />
          </Button>
          <div>
            <h3 className="text-headline-lg-bold">{title}</h3>
            <p className="text-black-500">{description}</p>
          </div>
        </div>

        <div
          className={clsx(
            "flex items-center gap-1 md:ml-auto",
            !flow.translate && "text-black-400",
          )}
        >
          <Translate weight="fill" size={20} />
          <p className="text-button-14">
            Automatic translation: <span>{translateTitle}</span>
          </p>
        </div>
      </div>

      {/* Template */}
      <RenderIf
        condition={
          flow.type === "PRIMARY_CONNECT" || flow.type === "INMAIL_OPTIMIZATION"
        }
      >
        <ReviewItem
          title={
            flow.type === "PRIMARY_CONNECT"
              ? "Note Template"
              : "InMail Template"
          }
          value={templateName}
          icon={CardsThree}
          isAiEnabled={flow.template?.ai}
        />
      </RenderIf>

      {/* Flow Template Messages */}
      <RenderIf condition={flow.type !== "INMAIL_OPTIMIZATION"}>
        <ReviewItem
          value={
            flow.flow_message_templates
              ? flow.flow_message_templates.length
              : "No messaged added"
          }
          title="Messages in sequence"
          icon={Chat}
          isAiEnabled={isAiEnabledInMessages}
        />
      </RenderIf>
    </section>
  );
}
