import React from "react";
import { LinkedinLogo } from "@phosphor-icons/react";

import { isRocketHubUser } from "common/helpers/utils";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import useSubscriptionSettings from "common/datahooks/useSubscriptionSettings";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";

export default function AccountDetails() {
  const { account, plan, role } = useSelectedSubscriptionContext();

  const { subscriptionSettings, isLoadingSubscriptionSettings } =
    useSubscriptionSettings();

  function getCouponContent() {
    if (role !== "member" && isLoadingSubscriptionSettings) {
      return <Skeleton className="h-8 w-36" />;
    }

    if (subscriptionSettings?.coupons?.length) {
      return (
        <div className="flex items-center justify-center">
          <Tag variant="success" size="lg">
            {isRocketHubUser(subscriptionSettings)
              ? "ROCKETHUB (lifetime)"
              : subscriptionSettings.coupons[0]}
          </Tag>
        </div>
      );
    }

    // Hide for now, we may need this soon in the future
    // return (
    //   <RestrictedComponent hiddenForRoles={["member"]} disabledForRoles={[]}>
    //     <CouponDialog />
    //   </RestrictedComponent>
    // );
  }

  return (
    <div className="mt-4 flex w-full flex-row justify-between rounded-20 border border-black-200 bg-whiteGray p-3">
      <div className="flex flex-row gap-2">
        <ProfileImage src={account.picture_url} size="lg" />

        <div className="flex flex-col justify-center">
          <div className="flex flex-row items-center gap-1">
            <span className="text-body-16-bold">{account.full_name}</span>
            <LinkedinLogo size={20} weight="fill" />
          </div>

          <span className="capitalize text-black-500">
            {plan.name.split("-").join(" ")}
          </span>
        </div>
      </div>

      {getCouponContent()}
    </div>
  );
}
