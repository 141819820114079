import React, { useState } from "react";
import clsx from "clsx";

import { formatDate } from "common/helpers/utils";
import { DrawerClose } from "common/components/ui/Drawer";
import Avatar from "common/components/ui/Avatar";
import { Member } from "common/types";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import { userRoles } from "common/constants";

import { INVITATION_STATUSES } from "../../constants";
import useSubscriptionMembers from "../../datahooks/useSubscriptionMembers";

export default function MobileDrawerContent({ member }: { member: Member }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { removeMember } = useSubscriptionMembers();

  const {
    token,
    first_name: firstName,
    last_name: lastName,
    role,
    customer_id: customerId,
    email,
    created_at: createdAt,
  } = member;

  const renderStatusTag = () => {
    if (role === "owner") {
      return "-";
    }
    const { label, variant } = INVITATION_STATUSES[member?.status];
    return <Tag variant={variant}>{label}</Tag>;
  };

  const roleData = userRoles[role];
  const { label: roleLabel, variant: roleVariant } = roleData || {};

  let content = (
    <>
      <div className="mt-2 flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2">
          <Avatar size="lg" variant={customerId ? "primary" : "quaternary"}>
            {customerId ? `${firstName[0]}${lastName[0]}` : "N/A"}
          </Avatar>

          <div className="flex flex-col gap-0.5">
            <div className="flex flex-row items-center gap-2 text-body-14-bold">
              <span
                className={clsx(
                  "text-body-14-bold",
                  !customerId && "text-black-300",
                )}
              >
                {customerId ? `${firstName} ${lastName}` : "New member"}
              </span>

              <Tag variant={roleVariant} className="capitalize">
                {roleLabel}
              </Tag>
            </div>

            <span className="line-clamp-1 max-w-[40ch] text-start text-caption-12-regular text-black-500">
              {email}
            </span>
          </div>
        </div>
      </div>

      <div className="my-4 h-px bg-black-200" />

      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <span className="w-1/2 capitalize text-black-700">Date added</span>

          <span className="flex w-1/2 flex-row items-center text-body-14-bold capitalize">
            {formatDate(createdAt)}
          </span>
        </div>

        <div className="h-px bg-black-200" />

        <div className="flex flex-row">
          <span className="w-1/2 capitalize text-black-700">Status</span>

          <span className="w-1/2">{renderStatusTag()}</span>
        </div>
      </div>

      <div className="mt-10 flex flex-col gap-2">
        <RenderIf condition={role !== "owner"}>
          <Button
            variant="quaternary-danger"
            size="lg"
            onClick={() => setIsDeleting(true)}
          >
            Remove account
          </Button>
        </RenderIf>
      </div>
    </>
  );

  if (isDeleting) {
    content = (
      <>
        <div className="mb-12 mt-6 flex flex-col items-center justify-center gap-2">
          <h3 className="text-headline-2xl-bold text-black-950">
            Delete member?
          </h3>

          <span className="text-center text-black-500">
            Are you sure you want to delete a member{" "}
            <span className="text-body-14-bold text-black-900">
              {customerId ? `${firstName} ${lastName}` : "New member"}
            </span>
            ?
          </span>
        </div>

        <div className="flex flex-col gap-2">
          <DrawerClose asChild>
            <Button
              variant="tertiary-danger"
              onClick={() => removeMember({ memberToken: token })}
            >
              Yes
            </Button>
          </DrawerClose>

          <Button
            variant="quaternary-black"
            onClick={() => setIsDeleting(false)}
          >
            No
          </Button>
        </div>
      </>
    );
  }

  return content;
}
