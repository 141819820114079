import React from "react";
import { CalendarDots } from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";

import useLinkedinEvents from "../../datahooks/useLinkedinEvents";

interface GroupTargetingTagProps {
  eventId: string;
  isLoading: boolean;
}

export default function EventTargetingTag({
  eventId,
  isLoading,
}: GroupTargetingTagProps) {
  const { linkedinEvent, isLoadingLinkedinEvent } = useLinkedinEvents({
    eventId,
  });

  if (isLoading || isLoadingLinkedinEvent)
    return <Skeleton className="h-7 w-60" />;

  return (
    <Tag
      size="lg"
      variant="primary-black"
      leftIcon={<CalendarDots weight="fill" />}
    >
      {linkedinEvent ? linkedinEvent.name : "LinkedIn Event"}
    </Tag>
  );
}
