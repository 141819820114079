import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { ArrowLeft } from "@phosphor-icons/react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";

import useTargeting from "../../../../datahooks/useTargeting";
import Stepper from "../../Stepper";
import ProfilesList from "./ProfilesList";

export default function List() {
  const [linkedInProfiles, setLinkedInProfiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const { setTargeting, isSettingTargeting } = useTargeting();

  function goToNextStep() {
    const identifiers = linkedInProfiles
      .map((url) => {
        const match = url.match(
          /https?:\/\/(?:www\.)?linkedin\.com\/in\/([^/]+)/,
        );
        return match ? match[1] : null;
      })
      .filter(Boolean);

    if (identifiers.length <= 0) {
      toast.error(
        "You can't submit empty list, please check the data you've entered.",
      );
      return;
    }

    setTargeting({ campaignTargeting: { identifiers } });
  }

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "text/csv": [],
      },
      maxFiles: 1,
      onDrop: (acceptedFiles, fileRejections) => {
        if (linkedInProfiles) {
          setLinkedInProfiles([]);
        }

        if (fileRejections.length > 0) {
          toast.error(
            "Upload failed. Please make sure you are uploading a CSV file.",
          );
          setLinkedInProfiles([]);
          return;
        }

        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target.result as string;

          const linkedinUrls = content.match(
            /https?:\/\/(?:www\.)?linkedin\.com\/in\/[^\s,]+/g,
          );

          const linkedinUrlsString = linkedinUrls?.join("\n");

          if (linkedinUrlsString) {
            const profilesArray = linkedinUrlsString
              .split("\n")
              .map((url) => url.trim());
            setLinkedInProfiles(profilesArray);
          } else {
            toast.error("Error: No LinkedIn URLs were found in your file.");
          }
        };

        reader.readAsText(file);
      },
    });

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Custom Campaign</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        List campaigns allow you to create an extremely targeted audience by
        providing a list of LinkedIn account URLs. This can be either a series
        of links, like a column from one of your marketing sheets, or a .CSV
        file
      </p>

      <div
        onDragEnter={handleDragEnter}
        onDrop={handleDragLeave}
        className="mx-auto flex w-full max-w-[750px] flex-col"
      >
        {isDragging ? (
          <div
            className={clsx(
              "flex h-56 flex-col items-center justify-center rounded-xl border border-dashed outline-none",
              isDragAccept && "border-green-500 bg-green-50",
              isDragReject && "border-red-500 bg-red-50",
              !isDragAccept &&
                !isDragAccept &&
                "border-purple-500 bg-purple-50 ",
            )}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <h5 className="text-headline-xl-bold">Drag or upload CSV here</h5>

            <span className="mb-6 mt-2">
              Drag your .CSV file here, or click “Upload” to select a file from
              your device.
            </span>

            <Button
              leftIcon={<ArrowLeft />}
              onClick={(e) => {
                e.stopPropagation();
                setIsDragging(false);
              }}
            >
              Back to editor
            </Button>
          </div>
        ) : (
          <ProfilesList
            linkedInProfiles={linkedInProfiles.join("\n")}
            onUpload={open}
            updateProfiles={(profilesList: string[]) => {
              setLinkedInProfiles(profilesList);
            }}
          />
        )}
      </div>

      <Stepper
        selectedIndex={0}
        goToNextStep={goToNextStep}
        isNextButtonLoading={isSettingTargeting}
        isNextStepDisabled={linkedInProfiles.length <= 0}
      />
    </>
  );
}
