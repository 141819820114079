import React from "react";
import { clsx } from "clsx";
import { CaretLeft } from "@phosphor-icons/react";

import MobileNav from "common/components/Layout/MobileLayout/MobileNav";
import { NavItemType } from "common/components/Layout";
import { Button } from "common/components/ui/Button";
import useSubscriptions from "common/datahooks/useSubscriptions";
import LogoutPopup from "common/components/Layout/LogoutPopup";

import SubscriptionSelection from "./SubscriptionSelection";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";

function MobileHeader({
  navItems,
  goBack,
}: {
  navItems: NavItemType[];
  goBack: () => void;
}) {
  const { subscriptions } = useSubscriptions();

  let content = (
    <>
      <div className="flex items-end">
        <AimfoxLogoOrange className="w-8" />
        <span className="text-headline-xl-bold">Aimfox</span>
      </div>
      <LogoutPopup />
    </>
  );

  if (subscriptions.length) {
    content = goBack ? (
      <>
        <Button
          variant="quaternary-black"
          leftIcon={<CaretLeft />}
          onClick={goBack}
        >
          Back
        </Button>
        <AimfoxLogoOrange className="w-10" />
      </>
    ) : (
      <>
        <SubscriptionSelection />
        <MobileNav navItems={navItems} />
      </>
    );
  }

  return (
    <div
      className={clsx(
        "sticky top-0 z-nav flex h-20 w-full items-center justify-between bg-white p-4 shadow-sm",
      )}
    >
      {content}
    </div>
  );
}

export default MobileHeader;
