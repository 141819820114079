import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Plus, GearSix, SignOut } from "@phosphor-icons/react";

import { logout } from "common/helpers/auth";
import useCustomer from "common/datahooks/useCustomer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import Avatar from "common/components/ui/Avatar";
import AddSubscription from "common/components/Layout/AddSubscription";

export default function AccountPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddSubscriptionOpen, setIsAddSubscriptionOpen] = useState(false);
  const { customerInfo } = useCustomer();
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const { first_name: firstName, last_name: lastName } = customerInfo;
  const menuItemClassname =
    "p-4 hover:bg-whiteGray gap-x-1 text-button-14 text-black-950 flex items-center rounded-2xl";

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="rounded-full">
          <Avatar>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col p-2" align="end">
          <div className="mb-2 flex flex-col gap-y-0.5 border-b border-b-black-200 p-2 pb-4">
            <span className="truncate text-body-14-bold">
              {firstName} {lastName}
            </span>
            <span className="truncate text-button-12 text-black-400">
              {customerInfo.email}
            </span>
          </div>
          <Link className={menuItemClassname} to="account/settings">
            <GearSix size={20} />
            Account settings
          </Link>
          <button
            type="button"
            className={menuItemClassname}
            onClick={() => setIsAddSubscriptionOpen(true)}
          >
            <Plus size={20} />
            Add subscription
          </button>
          <button className={menuItemClassname} type="button" onClick={logout}>
            <SignOut size={20} />
            Log Out
          </button>
        </PopoverContent>
      </Popover>
      <AddSubscription
        isOpen={isAddSubscriptionOpen}
        onClose={() => setIsAddSubscriptionOpen(false)}
      />
    </>
  );
}
