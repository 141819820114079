import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { SubscriptionSettings } from "common/types";

async function getSubscriptionSettingsRequest(
  subscriptionId: string,
): Promise<SubscriptionSettings> {
  return (await get(`subscriptions/${subscriptionId}/settings`)).subscription;
}

export default function useSubscriptionSettings() {
  const { id } = useSelectedSubscriptionContext();

  const {
    data: subscriptionSettings,
    isLoading: isLoadingSubscriptionSettings,
  } = useQuery({
    queryKey: ["subscriptionSettings", id],
    queryFn: () => getSubscriptionSettingsRequest(id),
  });

  return { subscriptionSettings, isLoadingSubscriptionSettings };
}
