import React, { ComponentProps, forwardRef } from "react";
import { CircleNotch } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

interface LoaderProps extends ComponentProps<"div"> {
  iconSize?: number;
}

const Loader = forwardRef<HTMLDivElement, LoaderProps>(
  ({ iconSize, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("flex grow items-center justify-center", className)}
      {...props}
    >
      <CircleNotch size={iconSize} className="animate-spin fill-black-300" />
    </div>
  ),
);
Loader.displayName = "Loader";

Loader.defaultProps = {
  iconSize: 80,
};

export default Loader;
