import React, { useState } from "react";

import AddPaymentMethod from "common/components/AddPaymentMethod";
import useSubscriptionUpdates from "common/datahooks/useSubscriptionUpdates";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { getMonthName } from "common/helpers/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { Drawer, DrawerClose, DrawerContent } from "./ui/Drawer";
import PaymentMethodSelection from "./PaymentMethodSelection";
import PaidPlanDetails from "./PaidPlanDetails";

import SubscriptionActivatedImage from "assets/images/subscription-activated.png";
import UpgradeOutreachImage from "assets/images/upgrade-linkedin-outreach.png";

interface UpgradeDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function UpgradeDialog({
  isOpen,
  onOpenChange,
}: UpgradeDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const selectedSubscription = useSelectedSubscriptionContext();
  const {
    reactivateSubscription,
    isReactivatingSubscription,
    reactivatedSubscription,
  } = useSubscriptionUpdates();
  const { setPrimaryPaymentSource, isSettingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  function onClick() {
    reactivateSubscription({ subscriptionId: selectedSubscription.id });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  let content = (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <img
          src={UpgradeOutreachImage}
          alt="Upgrade LinkedIn Outreach"
          className="mx-auto hidden sm:block"
          width={254}
          height={185}
        />

        <h3 className="text-center text-headline-2xl-bold">
          Upgrade to get LinkedIn Outreach
        </h3>

        <div className="flex items-center justify-center rounded-2xl border border-black-200 bg-black-50 p-4">
          <PaidPlanDetails />

          <div className="flex flex-row flex-nowrap">
            <span className="text-headline-3xl-bold">$39</span>
            <span className="content-end text-black-600">/month</span>
          </div>
        </div>
      </div>

      <PaymentMethodSelection
        isCompactComponent
        onAddPaymentMethod={() => setIsAddingPayment(true)}
        onSelectPaymentSource={(paymentSource) =>
          setPrimaryPaymentSource({ paymentSource })
        }
      />

      <Button
        onClick={onClick}
        size="lg"
        isLoading={isReactivatingSubscription || isSettingPrimaryPaymentSource}
      >
        Continue with Aimfox
      </Button>
    </div>
  );

  if (isAddingPayment) {
    content = <AddPaymentMethod onBack={() => setIsAddingPayment(false)} />;
  } else if (reactivatedSubscription) {
    const { next_billing: nextBilling } = reactivatedSubscription;
    const billingDate = new Date(nextBilling * 1000);

    content = (
      <div className="flex flex-col gap-6">
        <img
          src={SubscriptionActivatedImage}
          width="254"
          height="185"
          className="mx-auto"
          alt="Success illustration"
        />

        <div className="my-auto mb-4 flex flex-col gap-2">
          <h3 className="text-center text-headline-2xl-bold">
            Subscription activated
          </h3>

          <p className="text-center text-black-400">
            Your LinkedIn Outreach subscription is{" "}
            <span className="text-body-14-bold text-black-950">ACTIVE</span> and
            will renew on{" "}
            <span className="text-body-14-bold text-black-950">
              {billingDate.getDate()} {getMonthName(billingDate.getMonth())}{" "}
              {billingDate.getFullYear()}.{" "}
            </span>
            You have full access to all features and full dashboard
            functionality!
          </p>
        </div>

        <Close asChild>
          <Button className="mt-4 w-full md:mt-0">Close</Button>
        </Close>
      </div>
    );
  }

  return (
    <Component open={isOpen} onOpenChange={onOpenChange}>
      <Content className="p-4 md:max-w-[540px] md:pt-12">{content}</Content>
    </Component>
  );
}
