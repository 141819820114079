import React from "react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";

import ExpandCollapseIcon from "common/components/Layout/ExpandCollapseIcon";
import { NavItemType } from "common/components/Layout";
import useSubscriptions from "common/datahooks/useSubscriptions";
import RenderIf from "common/components/RenderIf";

import NavItem from "../../NavItem";
import SubscriptionsPopup from "./SubscriptionsPopup";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";

export default function DesktopNav({
  navItems,
  goBack,
  isExpanded,
  setIsExpanded,
}: {
  navItems: NavItemType[];
  goBack: () => void;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) {
  const { subscriptions } = useSubscriptions();

  const isActuallyExpanded = isExpanded && !goBack;

  return (
    <nav
      className={clsx(
        "fixed z-nav flex h-full flex-col rounded-tr-[32px] bg-black-950 px-4 py-6 transition-[width] duration-500",
        isActuallyExpanded ? "w-[266px]" : "w-[72px]",
      )}
    >
      <Link
        to="home"
        className={clsx(
          "mb-8 flex items-end self-start overflow-hidden",
          !isActuallyExpanded && "w-10",
        )}
      >
        <AimfoxLogoOrange className="w-10" />
        <span
          className={clsx(
            "mb-0.5 text-headline-2xl-bold text-white transition-opacity duration-500",
            isActuallyExpanded ? "opacity-100" : "opacity-0",
          )}
        >
          Aimfox
        </span>
      </Link>
      <div className="flex flex-col gap-y-3">
        {!goBack &&
          navItems.map((navItem) => (
            <NavItem
              key={navItem.path}
              isExpanded={isActuallyExpanded}
              {...navItem}
            />
          ))}
      </div>
      <div
        className={clsx(
          "mt-auto flex h-[122px] flex-col",
          goBack ? "justify-end" : "justify-start",
        )}
      >
        {goBack ? (
          <NavItem
            label="Go Back"
            renderIcon={() => <CaretLeft size={20} className="fill-white" />}
            onClick={goBack}
          />
        ) : (
          <>
            <NavItem
              label={isExpanded ? "Collapse" : "Expand"}
              isExpanded={isActuallyExpanded}
              renderIcon={() => (
                <ExpandCollapseIcon isExpanded={isActuallyExpanded} />
              )}
              onClick={() => setIsExpanded(!isExpanded)}
            />
            <RenderIf condition={!!subscriptions.length}>
              <SubscriptionsPopup isExpanded={isActuallyExpanded} />
            </RenderIf>
          </>
        )}
      </div>
    </nav>
  );
}
