import React from "react";

import AccountDetails from "./AccountDetails";
import SubscriptionPlans from "./SubscriptionPlans/SubscriptionPlans";

export default function Subscription() {
  return (
    <div className="flex flex-col gap-2.5">
      <AccountDetails />
      <SubscriptionPlans />
    </div>
  );
}
