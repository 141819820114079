import { create } from "zustand";
import { JSX } from "react";

import { SubscriptionStatus, UserRole } from "common/types";

interface HeaderContent {
  title?: string;
  component?: JSX.Element;
}
interface DebugOptions {
  shouldDebugLogin: boolean;
  isPaymentMethodHidden: boolean;
  accountState: "LoggedIn" | "LoggedOut" | "NeverLoggedIn";
  subscriptionStatus: SubscriptionStatus;
  subscriptionRole: UserRole;
}
interface AppState {
  // this limits page height to 100vh
  isPageHeightLimited: boolean;
  setIsPageHeightLimited: (isPageHeightLimited: boolean) => void;

  headerContent: HeaderContent;
  setHeaderContent: (headerContent: HeaderContent) => void;

  isShowingLogin: boolean;
  setIsShowingLogin: (isShowingLogin: boolean) => void;

  debugOptions: DebugOptions;
  setDebugOptions: (debugOptions: Partial<DebugOptions>) => void;
}
export default create<AppState>((set, get) => ({
  isPageHeightLimited: false,
  setIsPageHeightLimited: (isPageHeightLimited) => set({ isPageHeightLimited }),

  headerContent: null,
  setHeaderContent: (headerContent) => set({ headerContent }),

  isShowingLogin: false,
  setIsShowingLogin: (isShowingLogin) => set({ isShowingLogin }),

  debugOptions: {
    shouldDebugLogin: false,
    isPaymentMethodHidden: false,
    accountState: null,
    subscriptionStatus: null,
    subscriptionRole: null,
  },
  setDebugOptions: (newDebugOptions) =>
    set({ debugOptions: { ...get().debugOptions, ...newDebugOptions } }),
}));
