import React from "react";

import { CampaignType } from "common/types";

import { Targeting as TargetingType } from "../../../types";
import Search from "./Search";
import Post from "./Post";
import GroupAndEvent from "./GroupAndEvent";
import List from "./List";

interface TargetingProps {
  targeting: TargetingType;
  campaignType: CampaignType;
}

export default function Targeting({ targeting, campaignType }: TargetingProps) {
  const targetingSetup: Record<CampaignType, React.ReactNode> = {
    search: <Search targeting={targeting} />,
    event: <GroupAndEvent type="event" />,
    group: <GroupAndEvent type="group" />,
    post: <Post />,
    list: <List />,
    ai: null,
  };

  return targetingSetup[campaignType];
}
