import {
  DotsThreeVertical,
  ListMagnifyingGlass,
  PlayCircle,
  PauseCircle,
  Trash,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Campaign } from "common/types";
import RenderIf from "common/components/RenderIf";
import RestrictedComponent from "common/components/RestrictedComponent";

import CampaignActionsDialog from "./CampaignActionsDialog";
import { DetailedCampaign } from "../../types";

type ActionType = "start" | "pause" | "delete" | "editName";

interface CampaignActionsDropdownProps {
  campaign: Campaign | DetailedCampaign;
  isDetailedView?: boolean;
}

export default function CampaignActionsDropdown({
  campaign,
  isDetailedView = false,
}: CampaignActionsDropdownProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedActionType, setSelectedActionType] = useState<ActionType>();

  const { state, id } = campaign;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            intent="iconOnly"
            variant="secondary-black"
            onClick={(e) => e.stopPropagation()}
          >
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
          {isDetailedView ? (
            <RestrictedComponent
              disabledForRoles={["member"]}
              hiddenForRoles={[]}
            >
              {(isDisabled) => (
                <DropdownMenuItem
                  onClick={() => {
                    setIsOpen(true);
                    setSelectedActionType("editName");
                  }}
                  disabled={isDisabled}
                >
                  <PencilSimpleLine size={20} />
                  <span>Edit name</span>
                </DropdownMenuItem>
              )}
            </RestrictedComponent>
          ) : (
            <DropdownMenuItem onClick={() => navigate(id)}>
              <ListMagnifyingGlass size={20} />
              <span>Go to details</span>
            </DropdownMenuItem>
          )}

          <RestrictedComponent
            disabledForRoles={[]}
            hiddenForRoles={["member"]}
          >
            <RenderIf condition={state !== "INIT" && state !== "DONE"}>
              <DropdownMenuItem
                onClick={() => {
                  setIsOpen(true);
                  setSelectedActionType(state === "ACTIVE" ? "pause" : "start");
                }}
              >
                {state === "ACTIVE" ? (
                  <PauseCircle size={20} />
                ) : (
                  <PlayCircle size={20} />
                )}
                <span>{state === "ACTIVE" ? "Pause" : "Start"} campaign</span>
              </DropdownMenuItem>
            </RenderIf>
            <DropdownMenuItem
              className="text-red-500"
              onClick={() => {
                setIsOpen(true);
                setSelectedActionType("delete");
              }}
            >
              <Trash size={20} />
              <span>Delete campaign</span>
            </DropdownMenuItem>
          </RestrictedComponent>
        </DropdownMenuContent>
      </DropdownMenu>

      <CampaignActionsDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={selectedActionType}
        campaign={campaign}
      />
    </>
  );
}
