import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { get, post } from "../helpers/HTTP";
import { PaymentSource } from "../types";

async function getPaymentSourcesRequest(shouldIncludeSubscriptionId: boolean) {
  const searchParams = new URLSearchParams();

  if (shouldIncludeSubscriptionId) {
    searchParams.append("with_subscription_id", "true");
  }
  return (await get(`billing/payment-sources?${searchParams.toString()}`))
    .payment_sources;
}
async function addPaymentSourceRequest(
  source: "card" | "paypal" | "apple-pay",
  token: string,
): Promise<PaymentSource> {
  return (await post(`billing/${source}`, { token })).payment_source;
}
export default function usePaymentSources({
  shouldIncludeSubscriptionId,
}: {
  shouldIncludeSubscriptionId: boolean;
}) {
  const queryClient = useQueryClient();
  const selectedSubscription = useSelectedSubscriptionContext();
  const { paymentSourceKeys } = getQueryKeys(selectedSubscription.account);

  const { data: paymentSources, isLoading: isLoadingPaymentSources } = useQuery<
    PaymentSource[]
  >({
    queryKey: paymentSourceKeys.list(),
    queryFn: () => getPaymentSourcesRequest(shouldIncludeSubscriptionId),
  });

  const {
    mutateAsync: addPaymentSource,
    isPending: isAddingPaymentSource,
    error: addPaymentSourceError,
    reset: resetAddPaymentSourceError,
  } = useMutation({
    mutationFn: ({
      token,
      source,
    }: {
      token: string;
      source: "card" | "paypal" | "apple-pay";
    }) => addPaymentSourceRequest(source, token),
    onSuccess: (paymentSource) => {
      queryClient.setQueryData(
        paymentSourceKeys.list(),
        (prevPaymentSources: PaymentSource[]) => [
          paymentSource,
          ...prevPaymentSources,
        ],
      );
    },
  });
  return {
    paymentSources,
    isLoadingPaymentSources,
    addPaymentSource,
    isAddingPaymentSource,
    addPaymentSourceError,
    resetAddPaymentSourceError,
  };
}
