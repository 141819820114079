import React, { useMemo, useState, Suspense } from "react";
import { type EmojiClickData, type EmojiStyle } from "emoji-picker-react";
import { Smiley } from "@phosphor-icons/react";
import EmojiPicker from "emoji-picker-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";

export default function EmojiSelector({
  icon = <Smiley />,
  onEmojiSelection,
}: {
  icon?: JSX.Element;
  onEmojiSelection: (selectedEmoji: string) => void;
}) {
  const [isEmojiMenuOpen, setIsEmojiMenuOpen] = useState(false);

  const picker = useMemo(
    () => (
      <EmojiPicker
        onEmojiClick={({ emoji }: EmojiClickData) => {
          onEmojiSelection(emoji);
          setIsEmojiMenuOpen(false);
        }}
        // this is very important because importing the enum directly doesn't split the bundle
        emojiStyle={"native" as EmojiStyle}
        width="100%"
      />
    ),
    [onEmojiSelection],
  );

  return (
    <Popover open={isEmojiMenuOpen} onOpenChange={setIsEmojiMenuOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={isEmojiMenuOpen ? "tertiary-black" : "quaternary-black"}
          intent="iconOnly"
          size="sm"
        >
          {icon}
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <Suspense
          fallback={
            <div className="flex flex-col gap-2 p-4">
              <Skeleton className="h-[18px] w-64" />
              <Skeleton className="h-[18px] w-52" />
              <Skeleton className="h-[18px] w-56" />
              <Skeleton className="h-[18px] w-64" />
              <Skeleton className="h-[18px] w-36" />
            </div>
          }
        >
          {picker}
        </Suspense>
      </PopoverContent>
    </Popover>
  );
}
