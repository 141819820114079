import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SearchInput from "common/components/SearchInput";

import FiltersPopover from "./FiltersPopover";
import AudienceList from "./AudienceList";
import useTargets from "../../../datahooks/useTargets";
import { TargetState } from "../../../types";

export default function CampaignAudience() {
  const { t } = useTranslation("campaigns");
  const [inputValue, setInputValue] = useState("");
  const [selectedStates, setSelectedStates] = useState<TargetState[]>([]);

  const { targets, isLoadingTargets } = useTargets();

  function resetFilters() {
    setSelectedStates([]);
    setInputValue("");
  }

  const lowercasedInput = inputValue.toLowerCase();

  const filteredTargets = targets
    ? targets.filter(({ full_name, state }) => {
        const isMatchingInput = lowercasedInput
          ? full_name.toLowerCase().includes(lowercasedInput)
          : true;

        const isMatchingState = selectedStates.length
          ? selectedStates.includes(state)
          : true;

        return isMatchingInput && isMatchingState;
      })
    : [];

  const profiles = isLoadingTargets ? new Array(12).fill({}) : filteredTargets;

  return (
    <>
      <section className="mb-4">
        <h2 className="text-headline-xl-bold">
          {t("campaignDetails.audienceTitle")}
        </h2>
        <p className="text-black-500">
          {t("campaignDetails.audienceSubtitle")}
        </p>
      </section>

      <section className="mb-4 flex items-center justify-between gap-2">
        <SearchInput
          value={inputValue}
          placeholder="Search targets"
          onChange={(e) => setInputValue(e.currentTarget.value)}
          className="md:w-[400px]"
          onClear={() => setInputValue("")}
        />
        <FiltersPopover
          selectedStates={selectedStates}
          setSelectedStates={setSelectedStates}
          isLoadingTargets={isLoadingTargets}
          allTargets={targets}
        />
      </section>

      <AudienceList
        targets={profiles}
        searchText={inputValue}
        resetFilters={resetFilters}
        isLoading={isLoadingTargets}
      />
    </>
  );
}
