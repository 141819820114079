import { useMutation } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";

async function applyAppSumoRequest(code: string) {
  await post(`appsumo/license`, { code });
}
export default function useAppSumo() {
  const {
    mutateAsync: applyAppSumo,
    isPending: isApplyingAppSumo,
    error: appSumoError,
  } = useMutation({
    mutationFn: ({ code }: { code: string }) => applyAppSumoRequest(code),
  });

  return { applyAppSumo, isApplyingAppSumo, appSumoError };
}
