import React from "react";
import { CalendarDots, Clock } from "@phosphor-icons/react";
import { format } from "date-fns";

import { formatChatTimestamp } from "../../utils";

export default function TimestampDisplay({ createdAt }: { createdAt: number }) {
  return (
    <div className="flex flex-row gap-8">
      <span className="flex flex-row items-center gap-1 text-caption-12-bold text-black-600">
        <CalendarDots size={16} />
        {formatChatTimestamp(createdAt)}
      </span>

      <span className="flex flex-row items-center gap-1 text-caption-12-bold text-black-600">
        <Clock size={16} />
        {format(createdAt, "h:mm a")}
      </span>
    </div>
  );
}
