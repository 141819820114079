import React, { useMemo } from "react";
import { Trash } from "@phosphor-icons/react";

import { getProfileSubtitle } from "common/helpers/utils";
import { LinkedinProfile } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import RestrictedComponent from "common/components/RestrictedComponent";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import useBlacklist from "../../datahooks/useBlacklist";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function BlacklistList({
  profiles,
  isLoading,
  searchTerm = null,
}: {
  profiles: LinkedinProfile[];
  isLoading: boolean;
  searchTerm?: string;
}) {
  const { removeFromBlacklist } = useBlacklist();

  const blacklistContent = useMemo(
    () => (isLoading ? new Array(4).fill({}) : profiles),
    [profiles, isLoading],
  );

  if (!isLoading && profiles.length === 0) {
    return (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle={`No results found for "${searchTerm}"`}
      />
    );
  }

  return (
    <section className="grid grid-cols-1 grid-rows-1 gap-3 md:grid-cols-2">
      {blacklistContent.map((profile: LinkedinProfile, index: number) => {
        const { urn, full_name: fullName, picture_url: pictureUrl } = profile;

        return (
          <div
            key={urn || index}
            className="flex flex-row items-center justify-between rounded-2xl border border-black-200 px-3 py-3.5"
          >
            <div className="flex flex-row items-center gap-3">
              {isLoading ? (
                <Skeleton className="size-12 rounded-xl" />
              ) : (
                <ProfileImage size="lg" src={pictureUrl} />
              )}

              <div className="flex flex-col gap-0.5">
                {isLoading ? (
                  <>
                    <Skeleton className="h-5 w-44" />
                    <Skeleton className="h-4 w-24" />
                  </>
                ) : (
                  <>
                    <span className="text-headline-lg-bold">{fullName}</span>
                    <span className="line-clamp-1 text-black-500">
                      {getProfileSubtitle(profile)}
                    </span>
                  </>
                )}
              </div>
            </div>

            <RestrictedComponent
              disabledForRoles={[]}
              hiddenForRoles={["member"]}
            >
              {isLoading ? (
                <Skeleton className="size-8 rounded-full" />
              ) : (
                <Button
                  intent="iconOnly"
                  variant="quaternary-danger"
                  onClick={() => removeFromBlacklist({ targetUrn: urn })}
                >
                  <Trash />
                </Button>
              )}
            </RestrictedComponent>
          </div>
        );
      })}
    </section>
  );
}
