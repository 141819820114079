import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { typedObjectKeys } from "common/helpers/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { CreatedTemplate, TemplateType } from "common/types";
import { templateTypes } from "common/constants";

interface FilterItemProps {
  onSelect: () => void;
  label: string;
  templatesCount: number;
  isSelected?: boolean;
}

function FilterItem({
  onSelect,
  label,
  templatesCount,
  isSelected = false,
}: FilterItemProps) {
  return (
    <button
      type="button"
      className={clsx(
        "flex w-full px-4 py-1.5 text-button-14 outline-none transition-colors hover:bg-black-100",
        isSelected && "bg-black-50",
      )}
      onClick={onSelect}
    >
      {label}
      <span className="text-black-500">&nbsp;({templatesCount})</span>
    </button>
  );
}

interface TemplateTypeFilterProps {
  selectedFilter: TemplateType;
  setFilter: (filter: TemplateType) => void;
  templates: CreatedTemplate[];
}

export default function TemplateTypeFilter({
  selectedFilter,
  setFilter,
  templates,
}: TemplateTypeFilterProps) {
  const { t } = useTranslation("enums");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger className="group inline-flex h-10 w-40 items-center justify-between rounded-full border border-black-200 px-6 text-button-14 outline-none transition-colors data-[state=open]:bg-black-50">
        {selectedFilter ? t(selectedFilter) : "Filter by"}
        <CaretDown
          size={16}
          className="-mr-3 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent
        className="flex w-44 flex-col items-start gap-1 py-2"
        align="end"
      >
        <FilterItem
          onSelect={() => {
            setFilter(null);
            setIsOpen(false);
          }}
          label="All"
          templatesCount={templates ? templates.length : 0}
        />

        {typedObjectKeys(templateTypes).map((key) => (
          <FilterItem
            key={key}
            isSelected={key === selectedFilter}
            onSelect={() => {
              setFilter(key);
              setIsOpen(false);
            }}
            label={t(key)}
            templatesCount={
              templates
                ? templates.filter(({ type }) => type === key).length
                : 0
            }
          />
        ))}
      </PopoverContent>
    </Popover>
  );
}
