import React, { useState } from "react";
import { CircleNotch, PencilSimple, Plus } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import { getProfileSubtitle } from "common/helpers/utils";
import useDebounce from "common/hooks/useDebounce";
import useProfileSearch from "common/datahooks/useProfileSearch";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { LinkedinProfile } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import ProfileImage from "common/components/ui/ProfileImage";
import { Input } from "common/components/ui/Input";
import RenderIf from "common/components/RenderIf";

import useBlacklist from "../../datahooks/useBlacklist";

export default function AddAccountDialog() {
  const { addToBlacklist, isAddingToBlacklist, blacklist } = useBlacklist();
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<LinkedinProfile>(null);
  const [keywords, setKeywords] = useState("");
  const debouncedKeywords = useDebounce(keywords, 600);
  const { profiles, isLoadingProfiles } = useProfileSearch({
    searchParams: debouncedKeywords && {
      keywords: [
        { text: debouncedKeywords, type: "general", modifier: "optional" },
      ],
    },
  });
  const selectedSubscription = useSelectedSubscriptionContext();

  function onAddAccount(targetUrn: string): void {
    addToBlacklist({ targetUrn }).then(() => {
      // Reset state
      setKeywords("");
      setSelectedAccount(null);
      setIsOpen(false);
    });
  }

  const filteredResults = profiles?.filter(
    ({ id }) =>
      id !== selectedSubscription.account.id &&
      !blacklist.some((item) => item.id === id),
  );

  const searchComponent = (
    <>
      <Input
        placeholder="Search accounts"
        variant="md"
        value={keywords}
        rightComponent={
          isLoadingProfiles && (
            <CircleNotch className="animate-spin text-black-500" />
          )
        }
        onChange={(e) => {
          setKeywords(e.target.value);
        }}
      />

      <RenderIf condition={debouncedKeywords && !isLoadingProfiles}>
        {filteredResults && filteredResults.length ? (
          <div className="mt-4 max-h-[310px] overflow-y-auto scrollbar-thin">
            {filteredResults?.map((profile) => (
              <button
                key={profile.id}
                type="button"
                className="flex w-full flex-row items-center gap-2 rounded-2xl p-2 hover:bg-black-100"
                onClick={() => {
                  setSelectedAccount(profile);
                  setKeywords("");
                }}
              >
                <ProfileImage src={profile.picture_url} />

                <div className="flex flex-col justify-start gap-0.5 text-start">
                  <span className="text-button-14 text-black-900">
                    {profile.full_name}
                  </span>

                  <span className="line-clamp-1 text-caption-12-regular text-black-900">
                    {getProfileSubtitle(profile)}
                  </span>
                </div>
              </button>
            ))}
          </div>
        ) : (
          <span className="mt-4">No Results Found</span>
        )}
      </RenderIf>
    </>
  );

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <Trigger asChild>
        <Button leftIcon={<Plus />}>Add account</Button>
      </Trigger>

      <Content className="px-4 py-6 max-md:h-dvh md:max-w-[540px] md:p-12">
        <div className="flex flex-col">
          <h3 className="mb-6 text-center text-headline-2xl-bold">
            Add account to Blacklist
          </h3>

          {!selectedAccount ? (
            searchComponent
          ) : (
            <div className="flex flex-row items-center gap-3.5 rounded-2xl border border-black-200 px-3 py-4">
              <ProfileImage size="lg" src={selectedAccount.picture_url} />

              <div className="flex flex-col gap-0.5">
                <span className="text-headline-lg-bold">
                  {selectedAccount.full_name}
                </span>

                <span className="line-clamp-1 text-black-500">
                  {selectedAccount.occupation}
                </span>
              </div>

              <Button
                intent="iconOnly"
                variant="quaternary-black"
                className="ml-auto"
                onClick={() => {
                  setKeywords("");
                  setSelectedAccount(null);
                }}
              >
                <PencilSimple />
              </Button>
            </div>
          )}
        </div>

        <Button
          className="mt-4"
          disabled={!selectedAccount || isAddingToBlacklist}
          isLoading={isAddingToBlacklist}
          onClick={() => onAddAccount(selectedAccount.urn)}
        >
          Add
        </Button>
      </Content>
    </Component>
  );
}
