import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import Hotjar from "@hotjar/browser";
import { initReactI18next } from "react-i18next";
import { IntercomProvider } from "react-use-intercom";
import enLocale from "assets/locales/en.json";

import { HTTPError } from "common/helpers/HTTP";
import reportWebVitals from "common/helpers/reportWebVitals";
import registerServiceWorker from "common/helpers/registerServiceWorker";
import { isProductionEnvironment } from "common/helpers/utils";
import { TooltipProvider } from "common/components/ui/Tooltip";

import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "assets/global.css";

const { hash } = window.location;
if (hash) {
  const token = hash.substring(1);

  if (token !== "") {
    localStorage.setItem("auth", token);
  }
}
if (isProductionEnvironment()) {
  Hotjar.init(import.meta.env.VITE_HOTJAR_ID.replace("_", ""), 6);
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: "https://fc3b760b6e7c49a390de101fd5e9b4b3@o769987.ingest.sentry.io/6299134",
    release: import.meta.env.VITE_SENTRY_RELEASE,
    beforeSend(event, hint) {
      // we don't send fetch errors
      if (hint.originalException instanceof HTTPError) {
        return null;
      }
      return event;
    },
  });
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: enLocale,
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .then(() => {});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});
queryClient.setQueryDefaults([{ accountId: null }], { enabled: false });
queryClient.setQueryDefaults([{ accountState: "NeverLoggedIn" }], {
  enabled: false,
});

const container = document.getElementById("react-root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <TooltipProvider delayDuration={300}>
              <App />
            </TooltipProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </IntercomProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (import.meta.env.MODE === "development") {
  reportWebVitals();
}
registerServiceWorker();
