import React from "react";

import { getMonthName } from "common/helpers/utils";
import useSubscriptionUpdates from "common/datahooks/useSubscriptionUpdates";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

export default function RemoveCancellationDialog({
  billingTimestamp,
  subscriptionId,
}: {
  billingTimestamp: number;
  subscriptionId: string;
}) {
  const {
    revertSubscriptionCancellation,
    revertedSubscription,
    isRevertingSubscriptionCancellation,
  } = useSubscriptionUpdates();

  const billingDate = new Date(billingTimestamp);
  const isTabletOrDesktop = useTwBreakpoint("md");

  function onRevert() {
    revertSubscriptionCancellation({ subscriptionId });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  let content = (
    <>
      <div className="flex flex-col gap-4">
        <h3 className="text-headline-2xl-bold">
          Are you sure you want to remove this scheduled cancellation?
        </h3>

        <p className="m-0 text-black-400">
          Your LinkedIn Outreach subscription become active, will automatically
          renew on{" "}
          <span className="text-body-14-bold text-black-950">
            {billingDate.getDate()} {getMonthName(billingDate.getMonth())}{" "}
            {billingDate.getFullYear()}
          </span>
          . You will keep full access to all subscription features.
        </p>
      </div>

      <div className="mt-4 flex w-full flex-col justify-center gap-3 md:mt-0">
        <Button
          variant="tertiary-danger"
          className="w-full sm:whitespace-nowrap"
          isLoading={isRevertingSubscriptionCancellation}
          onClick={onRevert}
        >
          Yes
        </Button>

        <Close asChild>
          <Button
            variant="quaternary-black"
            className="w-full"
            disabled={isRevertingSubscriptionCancellation}
          >
            No
          </Button>
        </Close>
      </div>
    </>
  );

  if (revertedSubscription) {
    content = (
      <>
        <div className="flex flex-col gap-4">
          <h3 className="text-center text-headline-2xl-bold">
            Removed scheduled cancellation
          </h3>

          <p className="m-0 text-center text-black-400">
            LinkedIn Outreach subscription is{" "}
            <span className="text-body-14-bold text-black-950">ACTIVE</span>{" "}
            again and will renew on{" "}
            <span className="text-body-14-bold text-black-950">
              {billingDate.getDate()} {getMonthName(billingDate.getMonth())}{" "}
              {billingDate.getFullYear()}.
            </span>
          </p>
        </div>

        <Close asChild>
          <Button
            variant="quaternary-black"
            className="mt-4 w-full md:mt-0"
            disabled={isRevertingSubscriptionCancellation}
          >
            Close
          </Button>
        </Close>
      </>
    );
  }

  return (
    <Component>
      <Trigger asChild>
        <Button variant="tertiary-danger" className="whitespace-nowrap">
          Remove cancellation
        </Button>
      </Trigger>

      <Content className="flex flex-col gap-5 p-4 max-md:h-dvh md:max-w-[540px] md:p-12">
        {content}
      </Content>
    </Component>
  );
}
