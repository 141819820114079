import React, { useState } from "react";
import { DotsThreeVertical, TrashSimple } from "@phosphor-icons/react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogClose,
} from "common/components/ui/Dialog";

import useSubscriptionMembers from "../../datahooks/useSubscriptionMembers";

export default function MemberActions({
  fullName,
  memberToken,
  isDisabled,
}: {
  fullName: string;
  memberToken: string;
  isDisabled: boolean;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { removeMember } = useSubscriptionMembers();

  function onRemoveMember(): void {
    if (isDisabled) {
      return;
    }

    removeMember({ memberToken });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={isDisabled} asChild>
          <Button intent="iconOnly" variant="secondary-black">
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem
            className="text-red-400"
            onClick={() => setIsDeleting(true)}
          >
            <TrashSimple size={20} />
            Remove member
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
        <DialogContent className="max-w-[540px] px-6 pb-8 pt-16">
          <div className="mb-4 flex flex-col items-center gap-2">
            <h3 className="text-headline-2xl-bold text-black-950">
              Delete member?
            </h3>

            <span className="text-center text-black-500">
              Are you sure you want to delete a member{" "}
              <span className="text-body-14-bold text-black-900">
                {fullName}
              </span>
              ?
            </span>
          </div>

          <div className="flex flex-col gap-2">
            <DialogClose asChild>
              <Button variant="tertiary-danger" onClick={onRemoveMember}>
                Yes
              </Button>
            </DialogClose>

            <Button
              variant="quaternary-black"
              onClick={() => setIsDeleting(false)}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
