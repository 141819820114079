import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CaretUpDown } from "@phosphor-icons/react";
import clsx from "clsx";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import useInvitations from "common/datahooks/useInvitations";
import SubscriptionSelectionItem from "common/components/Layout/SubscriptionSelectionItem";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Tag } from "common/components/ui/Tag";
import InvitationItem from "common/components/Layout/InvitationItem";
import ProfileImage from "common/components/ui/ProfileImage";
import SearchInput from "common/components/SearchInput";
import WorkspaceInvitation from "common/components/Layout/WorkspaceInvitation";

const scrollThresholdSubscriptions = 3;
const scrollThresholdInvitations = 2;

export default function SubscriptionSelection() {
  const selectedSubscription = useSelectedSubscriptionContext();
  const location = useLocation();
  const { invitations } = useInvitations();
  const { subscriptions } = useSubscriptionSelection();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const invitationRef = useRef(null);

  const lowercasedInput = inputValue.toLowerCase();
  const filteredSubscriptions = subscriptions.filter(
    ({ account: { full_name } }) =>
      full_name.toLowerCase().includes(lowercasedInput),
  );

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="flex w-[246px] items-center gap-x-2 rounded-full bg-black-100 py-2 pl-2 pr-4">
          <ProfileImage
            src={selectedSubscription.account.picture_url}
            size="sm"
          />
          <span className="mr-auto truncate text-button-14">
            {selectedSubscription.account.full_name}
          </span>
          <CaretUpDown size={20} />
        </PopoverTrigger>
        <PopoverContent
          className="flex w-80 flex-col p-2"
          side="bottom"
          align="start"
        >
          <div className="mb-2 ml-4 flex items-center gap-x-2.5 text-black-400">
            Workspaces
            {subscriptions.length > scrollThresholdSubscriptions && (
              <Tag variant="primary-black">{subscriptions.length}</Tag>
            )}
          </div>
          {subscriptions.length > scrollThresholdSubscriptions && (
            <SearchInput
              placeholder="Search Workspaces"
              className="mb-2"
              variant="sm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onClear={() => setInputValue("")}
            />
          )}

          <div
            className={clsx(
              "flex max-h-56 flex-col gap-y-0.5 overflow-y-auto scrollbar-thin",
              subscriptions.length > scrollThresholdSubscriptions && "h-full",
            )}
          >
            {!!inputValue && !filteredSubscriptions.length ? (
              <p className="mx-4 break-words py-8 text-center text-black-500">
                No results for{" "}
                <span className="text-black-950">{inputValue}</span>
              </p>
            ) : (
              filteredSubscriptions.map((subscription) => (
                <SubscriptionSelectionItem
                  subscription={subscription}
                  key={subscription.id}
                />
              ))
            )}
          </div>
          {!!invitations && !!invitations.length && (
            <>
              <div className="mb-2 border-t border-t-black-200 pl-4 pt-2">
                <span className="mr-2.5 text-black-400">Invites</span>
                {invitations.length > scrollThresholdInvitations && (
                  <Tag variant="magenta">{invitations.length}</Tag>
                )}
              </div>
              <div className="flex max-h-40 flex-col gap-y-0.5 overflow-y-auto scrollbar-thin">
                {invitations.map((invitation) => (
                  <InvitationItem
                    invitation={invitation}
                    key={invitation.token}
                    openInvitation={() => {
                      setIsInvitationOpen(true);
                      invitationRef.current = invitation;
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </PopoverContent>
      </Popover>
      <WorkspaceInvitation
        invitation={invitationRef.current}
        isOpen={isInvitationOpen}
        onClose={() => setIsInvitationOpen(false)}
        onResponse={() => setIsInvitationOpen(false)}
      />
    </>
  );
}
