import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import useAppStore from "common/hooks/useAppStore";

import { get, HTTPError, patch } from "../helpers/HTTP";
import { PaymentSource } from "../types";

async function getPrimaryPaymentSource(subscriptionId: string) {
  try {
    return (await get(`subscriptions/${subscriptionId}/payment-source`))
      .payment_source;
  } catch (err) {
    if (err instanceof HTTPError && err.code === 404) {
      return null;
    }
    throw err;
  }
}
async function setPrimaryPaymentSourceRequest(
  subscriptionId: string,
  paymentSourceId: string,
) {
  await patch(`subscriptions/${subscriptionId}/payment-source`, {
    payment_source_id: paymentSourceId,
  });
}
export default function usePrimaryPaymentSource() {
  const queryClient = useQueryClient();
  const selectedSubscription = useSelectedSubscriptionContext();
  const isPaymentMethodHidden = useAppStore(
    (state) => state.debugOptions.isPaymentMethodHidden,
  );
  const { paymentSourceKeys } = getQueryKeys(selectedSubscription.account);

  const { data: primaryPaymentSource, isLoading } = useQuery<PaymentSource>({
    queryKey: paymentSourceKeys.primary(),
    queryFn: () => getPrimaryPaymentSource(selectedSubscription.id),
  });
  const {
    mutateAsync: setPrimaryPaymentSource,
    isPending: isSettingPrimaryPaymentSource,
  } = useMutation({
    onMutate: ({ paymentSource }) => {
      const originalPaymentSource: PaymentSource = queryClient.getQueryData(
        paymentSourceKeys.primary(),
      );
      queryClient.setQueryData(paymentSourceKeys.primary(), paymentSource);
      return originalPaymentSource;
    },
    onError: (_, __, originalPaymentSource) => {
      queryClient.setQueryData(
        paymentSourceKeys.primary(),
        // if original payment source is undefined, the query update is ignored. this ensures that it updates
        originalPaymentSource || null,
      );
      toast.error("Error updating payment source");
    },
    mutationFn: ({ paymentSource }: { paymentSource: PaymentSource }) =>
      setPrimaryPaymentSourceRequest(selectedSubscription.id, paymentSource.id),
  });

  return {
    primaryPaymentSource: isPaymentMethodHidden ? null : primaryPaymentSource,
    isLoading,
    setPrimaryPaymentSource,
    isSettingPrimaryPaymentSource,
  };
}
