import useAppStore from "common/hooks/useAppStore";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

export default function useAccountRestriction() {
  const selectedSubscription = useSelectedSubscriptionContext();

  const isAccountDisconnected =
    selectedSubscription.account.state !== "LoggedIn";

  // extraCondition is used to add an exception when you don't want to show the popups in certain cases
  function restrictedFunction(
    callback: () => void,
    extraCondition: boolean = true,
  ) {
    if (isAccountDisconnected && extraCondition) {
      // show login
      useAppStore.getState().setIsShowingLogin(true);
    } else {
      // call function
      callback();
    }
  }

  return {
    isRestricted: isAccountDisconnected,
    restrictedFunction,
  };
}
