import { useMutation } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import useCampaignFlow from "./useCampaignFlow";
import { useCampaignFlowContext } from "../context/CampaignFlowContext";

async function detectLanguageRequest(
  accountId: number,
  text: string,
): Promise<string> {
  return (
    await post(`accounts/${accountId}/language/detect`, {
      template_text: text,
    })
  ).detected_language.code;
}

export default function useLanguageDetection() {
  const { account } = useSelectedSubscriptionContext();
  const { flow } = useCampaignFlowContext();
  const { updateFlow } = useCampaignFlow();

  const { mutateAsync: detectLanguage } = useMutation({
    mutationFn: ({ text }: { text: string }) =>
      detectLanguageRequest(account.id, text),
    onSuccess: (languageCode) =>
      updateFlow({
        flowId: flow.id,
        updates: {
          source_language: languageCode,
          is_manual_language: false,
        },
      }),
  });

  return { detectLanguage };
}
