import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PencilSimple } from "@phosphor-icons/react";

import { Schedule } from "common/types";
import { Button } from "common/components/ui/Button";

import FlowReview from "./FlowReview";
import ScheduleReview from "./ScheduleReview";
import Stepper from "../Stepper";
import CampaignStart from "./CampaignStart";
import { CampaignFlow } from "../../../types";

interface ReviewProps {
  flows: CampaignFlow[];
  schedule: Schedule;
}

export default function Review({ flows, schedule }: ReviewProps) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <article className="grid gap-4 md:grid-cols-5 md:items-start">
        <div className="rounded-2xl border border-black-200 px-3 py-4 md:col-span-3 md:px-4">
          <div className="mb-3 flex justify-between gap-4 max-md:items-center md:gap-3">
            <div>
              <h2 className="text-button-16">Campaign Flows</h2>
              <p className="text-caption-12-regular text-black-500">
                Set up your campaign by selecting an audience, flow and schedule
              </p>
            </div>
            <div className="ml-auto h-10 w-px bg-black-200 md:hidden" />
            <Button variant="secondary-black" intent="iconOnly" asChild>
              <Link to="../flows">
                <PencilSimple />
              </Link>
            </Button>
          </div>

          {flows.map((flow) => (
            <FlowReview key={flow.id} flow={flow} />
          ))}
        </div>
        <ScheduleReview schedule={schedule} />
      </article>

      {/* Stepper */}
      <Stepper selectedIndex={4} goToNextStep={() => setIsModalOpened(true)} />

      {/* Campaign Start Modal */}
      <CampaignStart
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
      />
    </>
  );
}
