import React from "react";
import { DotsThree, PencilSimpleLine, Trash } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

import { ConversationMessage } from "../../../types";
import { useConversationUrnContext } from "../ConversationUrnContext";
import useConversationMessagesUpdates from "../../../datahooks/useConversationMessagesUpdates";

interface MessageMenuProps {
  message: ConversationMessage;
  setEditMessage: (message: ConversationMessage) => void;
}

export default function MessageMenu({
  message,
  setEditMessage,
}: MessageMenuProps) {
  const conversationUrn = useConversationUrnContext();
  const { deleteMessage } = useConversationMessagesUpdates({
    conversationUrn,
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button intent="iconOnly" size="sm" variant="quaternary-black">
          <DotsThree />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => setEditMessage(message)}>
          <PencilSimpleLine size={20} />
          Edit message
        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={() => deleteMessage({ messageUrn: message.urn })}
        >
          <Trash className="text-red-500" size={20} />
          Delete message
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
