import React, { useState, JSX } from "react";
import { clsx } from "clsx";
import { ArrowLeft, CaretRight, FunnelSimple } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

export default function MobileFilters({
  filtersComponent,
  resetAllParams,
}: {
  filtersComponent: JSX.Element;
  resetAllParams: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className="my-4 flex items-center gap-x-2 rounded-20 border border-black-200 p-4"
        onClick={() => setIsOpen(true)}
      >
        <Button variant="secondary-black" intent="labelIcon">
          <FunnelSimple />
        </Button>
        <span className="text-headline-lg-bold">Filters</span>
        <Button
          variant="quaternary-black"
          intent="labelIcon"
          className="ml-auto"
        >
          <CaretRight />
        </Button>
      </button>
      <div
        className={clsx(
          "fixed inset-0 z-20 flex size-full flex-col overflow-y-auto bg-white p-4 pt-24 transition-opacity",
          isOpen ? "opacity-100" : "pointer-events-none opacity-0",
        )}
      >
        <div className="mb-4 flex items-center justify-between">
          <Button
            variant="quaternary-black"
            size="lg"
            onClick={() => setIsOpen(false)}
            leftIcon={<ArrowLeft />}
          >
            Back
          </Button>
          <span className="text-headline-xl-bold">Filters</span>
          <Button
            variant="quaternary-danger"
            size="lg"
            className={clsx(!resetAllParams && "invisible")}
            onClick={resetAllParams}
          >
            Clear all
          </Button>
        </div>
        {filtersComponent}
      </div>
    </>
  );
}
