import React, { useState } from "react";
import { CaretDown, CircleNotch, Info } from "@phosphor-icons/react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import ProfileImage from "common/components/ui/ProfileImage";
import SearchInput from "common/components/SearchInput";

import useLinkedinGroups from "../../../../datahooks/useLinkedinGroups";
import useLinkedinEvents from "../../../../datahooks/useLinkedinEvents";
import { LinkedInEvent, LinkedInGroup } from "../../../../types";

import EventCampaignImage from "assets/images/event-campaign.png";
import GroupCampaignImage from "assets/images/group-campaign.png";
import GroupDefaultImage from "assets/images/group-fallback.svg";

const selectOptions: Record<
  "group" | "event",
  {
    placeholder: string;
    noOptionsText: string;
    label: string;
    imageSrc: string;
    infoText: string;
  }
> = {
  group: {
    imageSrc: GroupCampaignImage,
    label: "Groups",
    placeholder: "Select a group for your targeting",
    noOptionsText: "You are not a member of any LinkedIn Group.",
    infoText:
      "Select a LinkedIn group you want to target with this campaign. Aimfox will automatically engage with other group members",
  },
  event: {
    imageSrc: EventCampaignImage,
    label: "Events",
    placeholder: "Select an event for your targeting",
    noOptionsText: "You are not a attending any LinkedIn Event.",
    infoText:
      "Select a LinkedIn event you want to target with this campaign. Aimfox will engage with users attending this event",
  },
};

interface GroupAndEventSelectProps {
  type: "group" | "event";
  selectGroupOrEvent: (groupOrEvent: LinkedInGroup | LinkedInEvent) => void;
}

interface FilteredOptionsProps {
  options: LinkedInEvent[] | LinkedInGroup[];
  inputValue: string;
  selectGroupOrEvent: (option: LinkedInEvent | LinkedInGroup) => void;
}

interface OptionItemProps {
  option: LinkedInEvent | LinkedInGroup;
  selectGroupOrEvent: (option: LinkedInEvent | LinkedInGroup) => void;
}

function OptionItem({ option, selectGroupOrEvent }: OptionItemProps) {
  const { name, images, id } = option;
  const imageUrl = images.length ? images[0].url : GroupDefaultImage;

  let description = "";

  if ("public" in option) {
    description = option.public ? "Public group" : "Private group";
  } else {
    description = option.description;
  }

  return (
    <button
      type="button"
      key={id}
      className="flex w-full items-center gap-2 px-4 py-2 transition-colors hover:bg-black-50"
      onClick={() => selectGroupOrEvent(option)}
    >
      <ProfileImage src={imageUrl} size="sm" />
      <div>
        <h5 className="line-clamp-1 text-left text-button-14">{name}</h5>
        <p className="line-clamp-1 text-left text-caption-12-regular text-black-400">
          {description}
        </p>
      </div>
    </button>
  );
}

function FilteredOptions({
  options,
  inputValue,
  selectGroupOrEvent,
}: FilteredOptionsProps) {
  const lowercasedInput = inputValue.toLowerCase();
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(lowercasedInput),
  );

  if (!inputValue || filteredOptions.length) {
    return (
      <div className="-mx-4 flex h-28 flex-col gap-2 overflow-y-auto scrollbar-thin">
        {filteredOptions.map((option) => (
          <OptionItem
            key={option.id}
            option={option}
            selectGroupOrEvent={selectGroupOrEvent}
          />
        ))}
      </div>
    );
  }

  return (
    <p className="mx-4 flex h-28 items-center justify-center break-words text-center text-black-500">
      No results found for&nbsp;
      <span className="text-black-950">{inputValue}</span>
    </p>
  );
}

export default function GroupAndEventSelect({
  type,
  selectGroupOrEvent,
}: GroupAndEventSelectProps) {
  const [inputValue, setInputValue] = useState("");
  const { linkedinGroups, isLoadingLinkedinGroups } = useLinkedinGroups({
    disableQuery: type === "event",
  });
  const { linkedinEvents, isLoadingLinkedinEvents } = useLinkedinEvents({
    disableQuery: type === "group",
  });

  const options = (type === "group" ? linkedinGroups : linkedinEvents) || [];
  const isLoading =
    type === "group" ? isLoadingLinkedinGroups : isLoadingLinkedinEvents;

  const { placeholder, label, imageSrc, infoText, noOptionsText } =
    selectOptions[type];

  return (
    <div className="mb-20 md:rounded-3xl md:border md:border-black-200 md:p-5 md:shadow-md">
      <img
        src={imageSrc}
        width={750}
        height={210}
        alt={`${label} Campaign`}
        className="mb-8 rounded-20"
      />

      <Popover>
        <PopoverTrigger
          className="group flex h-12 w-full items-center justify-between rounded-xl bg-whiteGray px-4 text-button-16 text-black-400 transition-colors data-[state=open]:text-black-950 data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex gap-2">
              <CircleNotch size={20} className="animate-spin" />
              Loading {label}...
            </div>
          ) : (
            placeholder
          )}
          <CaretDown
            size={20}
            className="transition-transform group-data-[state=open]:rotate-180"
          />
        </PopoverTrigger>
        <PopoverContent
          className="w-[--radix-popper-anchor-width] px-4 py-2"
          avoidCollisions={false}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <SearchInput
            variant="lg"
            value={inputValue}
            className="mb-2 w-full"
            placeholder={`Search ${label}`}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            onClear={() => setInputValue("")}
          />
          {!options.length ? (
            <p className="mx-4 py-8 text-center text-black-500">
              {noOptionsText}
            </p>
          ) : (
            <FilteredOptions
              options={options}
              inputValue={inputValue}
              selectGroupOrEvent={selectGroupOrEvent}
            />
          )}
        </PopoverContent>
      </Popover>
      <div className="mt-1 flex gap-0.5 fill-black-700">
        <Info size={20} weight="fill" />
        <p className="text-caption-12-regular">{infoText}</p>
      </div>
    </div>
  );
}
