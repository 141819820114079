import React, { useMemo } from "react";
import clsx from "clsx";

import RenderIf from "common/components/RenderIf";
import { formatDate } from "common/helpers/utils";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { Member } from "common/types";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import { Tag } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import { userRoles } from "common/constants";

import { INVITATION_STATUSES } from "../../constants";
import MemberActions from "./MemberActions";

export default function DesktopTable({
  members,
  isLoading,
}: {
  members: Member[];
  isLoading: boolean;
}) {
  const { role: accountRole } = useSelectedSubscriptionContext();
  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : members),
    [members, isLoading],
  );

  return (
    <Table variant="simple">
      <TableHeader>
        <TableRow className="border-none">
          <TableHead>Name</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Date Added</TableHead>
          <TableHead>Status</TableHead>
          <RenderIf condition={accountRole !== "member"}>
            <TableHead />
          </RenderIf>
        </TableRow>
      </TableHeader>

      <tbody>
        {tableData.map((member: Member, index) => {
          const {
            token,
            first_name: firstName,
            last_name: lastName,
            role,
            customer_id: customerId,
            email,
            status,
            created_at: createdAt,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, variant: roleVariant } = roleData || {};

          const renderStatusTag = () => {
            if (role === "owner") {
              return "-";
            }
            const { label, variant } = INVITATION_STATUSES[status];
            return <Tag variant={variant}>{label}</Tag>;
          };

          return (
            <TableRow key={token || index}>
              <TableCell>
                {isLoading ? (
                  <Skeleton className="h-[18px] w-44 rounded-xl" />
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    <span
                      className={clsx(
                        "text-caption-12-bold",
                        customerId ? "text-black-700" : "text-black-300",
                      )}
                    >
                      {customerId ? `${firstName} ${lastName}` : "New Member"}
                    </span>

                    <Tag variant={roleVariant} className="capitalize" size="md">
                      {roleLabel}
                    </Tag>
                  </div>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-32 rounded-xl" />
                ) : (
                  <span
                    className={clsx(
                      "inline-block max-w-[40ch] overflow-hidden text-ellipsis text-caption-12-regular",
                      customerId ? "text-black-700" : "text-black-300",
                    )}
                  >
                    {email}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-24 rounded-xl" />
                ) : (
                  <span
                    className={clsx(
                      "text-caption-12-regular",
                      customerId ? "text-black-700" : "text-black-300",
                    )}
                  >
                    {role === "owner" ? "-" : formatDate(createdAt)}
                  </span>
                )}
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-[18px] w-16 rounded-xl" />
                ) : (
                  renderStatusTag()
                )}
              </TableCell>

              <RenderIf condition={accountRole !== "member"}>
                <TableCell>
                  <MemberActions
                    fullName={
                      customerId ? `${firstName} ${lastName}` : "New Member"
                    }
                    memberToken={token}
                    isDisabled={role === "owner"}
                  />
                </TableCell>
              </RenderIf>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}
