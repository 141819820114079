import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { LinkedinPost } from "../types";

async function getPostDetails(
  accountId: number,
  postId: string,
): Promise<LinkedinPost> {
  return (await get(`accounts/${accountId}/posts/${postId}`)).post;
}

export default function useLinkedinPost({ postId }: { postId: string }) {
  const { account } = useSelectedSubscriptionContext();

  const {
    data: linkedinPost,
    isLoading: isLoadingLinkedinPost,
    isError: isLinkedinPostError,
  } = useQuery({
    queryKey: ["posts", postId],
    queryFn: () => getPostDetails(account.id, postId),
  });

  return {
    linkedinPost,
    isLinkedinPostError,
    isLoadingLinkedinPost,
  };
}
