import { useMutation } from "@tanstack/react-query";

import { patch } from "common/helpers/HTTP";
import { useCampaignIdContext } from "common/helpers/CampaignIdContext";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import useUpdateCampaign from "./useUpdateCampaign";
import { CampaignSchedule, DetailedCampaign } from "../types";

async function setScheduleRequest(
  accountId: number,
  campaignId: string,
  schedule: CampaignSchedule,
): Promise<DetailedCampaign> {
  return (
    await patch(`accounts/${accountId}/campaigns/${campaignId}/schedule`, {
      ...schedule,
    })
  ).campaign;
}

export default function useCampaignSchedule() {
  const campaignId = useCampaignIdContext();
  const { account } = useSelectedSubscriptionContext();
  const { updateCachedCampaign } = useUpdateCampaign(account);

  const {
    mutateAsync: setCampaignSchedule,
    isPending: isSettingCampaignSchedule,
  } = useMutation({
    mutationFn: ({ schedule }: { schedule: CampaignSchedule }) =>
      setScheduleRequest(account.id, campaignId, schedule),
    onSuccess: ({ schedule }) => {
      updateCachedCampaign(campaignId, (draftCampaign) => {
        draftCampaign.schedule = schedule;
      });
    },
  });

  return { setCampaignSchedule, isSettingCampaignSchedule };
}
