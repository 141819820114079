import {
  LinkedinProfile,
  ProfileSearchParams,
  GenericSearchParam,
  SearchParamWithImage,
} from "common/types";

import { profileSearchOptions } from "../../../../../constants";
import { useSearchParamsContext } from "../SearchParamsContext";

export default function useSearchParams(
  optionsKey?: keyof typeof profileSearchOptions,
) {
  const { searchParams, setSearchParams } = useSearchParamsContext();

  let appliedSearchParamValues: Array<
    GenericSearchParam | SearchParamWithImage | LinkedinProfile
  >;
  if (optionsKey) {
    if (optionsKey === "companies") {
      appliedSearchParamValues = [
        ...searchParams.current_companies,
        ...searchParams.past_companies,
      ];
    } else if (optionsKey === "followers") {
      appliedSearchParamValues = searchParams.followers_of
        ? [searchParams.followers_of]
        : [];
    } else {
      appliedSearchParamValues = searchParams[
        profileSearchOptions[optionsKey].searchParamKey
      ] as Array<GenericSearchParam | SearchParamWithImage>;
    }
  }

  function addSearchParamValue(
    searchParamKey: keyof Omit<ProfileSearchParams, "keywords">,
    value: GenericSearchParam | SearchParamWithImage | LinkedinProfile,
  ) {
    if (searchParamKey === "followers_of") {
      setSearchParams({ [searchParamKey]: value as LinkedinProfile });
    } else {
      setSearchParams({
        [searchParamKey]: [...searchParams[searchParamKey], value],
      });
    }
  }
  function removeSearchParamValue(
    searchParamKey: keyof Omit<ProfileSearchParams, "keywords">,
    value: GenericSearchParam | SearchParamWithImage | LinkedinProfile,
  ) {
    if (searchParamKey === "followers_of") {
      setSearchParams({
        [searchParamKey]: null,
      });
    } else {
      setSearchParams({
        [searchParamKey]: searchParams[searchParamKey].filter(
          ({ urn }) => urn !== value.urn,
        ),
      });
    }
  }

  return {
    removeSearchParamValue,
    addSearchParamValue,
    appliedSearchParamValues,
  };
}
