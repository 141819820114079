import React, { useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  Check,
  PaperPlaneRight,
  Tag as TagIcon,
  X,
} from "@phosphor-icons/react";
import clsx from "clsx";

import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import TextArea from "common/components/ui/TextArea";
import RenderIf from "common/components/RenderIf";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useInsertText from "../../../hooks/useInsertText";
import SettingsPopover from "../../SettingsPopover";
import EmojiSelector from "../../EmojiSelector";
import useConversationMessagesUpdates from "../../../datahooks/useConversationMessagesUpdates";
import { Conversation, ConversationMessage } from "../../../types";
import { inboxVariables } from "../../../constants";

function loadCtrlToSend() {
  const savedCtrlToSend = localStorage.getItem("ctrlToSend");
  if (savedCtrlToSend) {
    return JSON.parse(savedCtrlToSend);
  }
  return true;
}

interface MessageFieldProps {
  conversation: Conversation;
  editMessage: ConversationMessage;
  cancelEdit: () => void;
  onSend: () => void;
  isSendingMessage: boolean;
  formReturn: UseFormReturn<{ message: string }>;
}

export default function MessageField({
  conversation,
  editMessage,
  cancelEdit,
  onSend,
  isSendingMessage,
  formReturn,
}: MessageFieldProps) {
  const { register, setValue, getValues, reset, handleSubmit, watch } =
    formReturn;
  const { conversation_urn: conversationUrn, participants } =
    conversation ?? {};
  const { updateMessage, isUpdatingMessage } = useConversationMessagesUpdates({
    conversationUrn,
  });

  const [ctrlToSend, setCtrlToSend] = useState(loadCtrlToSend());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const messageFieldRef = useRef<HTMLTextAreaElement>(null);
  const insertText = useInsertText(messageFieldRef, (string) =>
    setValue("message", string),
  );

  const prevEditMessageRef = useRef<ConversationMessage | null>(null);

  useEffect(() => {
    if (editMessage !== prevEditMessageRef.current) {
      reset({ message: editMessage?.body });
      prevEditMessageRef.current = editMessage;
    }
  }, [editMessage, reset]);

  async function onSave({ message }: { message: string }): Promise<void> {
    if (message.trim() !== editMessage.body) {
      await updateMessage({ message, messageUrn: editMessage.urn });
    }
    cancelEdit();
  }

  function onConfirm(): void {
    if (editMessage) {
      handleSubmit(onSave)();
      return;
    }
    onSend();
  }

  function onKeyDown(e: React.KeyboardEvent): void {
    if (e.key === "Enter") {
      if (e.ctrlKey || e.metaKey) {
        if (ctrlToSend) {
          onConfirm();
        } else {
          setValue("message", `${getValues("message")}\n`);
        }
      } else if (!ctrlToSend) {
        onConfirm();
        e.preventDefault();
      }
    }
  }
  const isEmptyMessage = !watch("message")?.trim();
  const isMessagingDisabled =
    !participants[0].is_lead || conversation.last_message?.declined;

  const mobileVariables = (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>
        <Button variant="quaternary-black" size="sm" intent="iconOnly">
          <TagIcon weight="fill" />
        </Button>
      </DrawerTrigger>

      <DrawerContent className="pb-6">
        <h3 className="text-headline-xl-bold">Variables</h3>

        <div className="my-4 h-px bg-black-200" />

        <span className="mb-4 text-black-500">
          Personalize your message with custom Variables
        </span>

        <div className="flex flex-row flex-wrap gap-2">
          {inboxVariables.map(({ label, getValue }) => (
            <Tag
              asChild
              key={label}
              variant="quaternary"
              size="lg"
              className="transition-colors hover:bg-black-200"
              onClick={() => {
                insertText(`${getValue(participants[0])} `);
                setIsDrawerOpen(false);
              }}
            >
              <button type="button">{label}</button>
            </Tag>
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );

  const desktopVariables = (
    <div className="flex flex-row gap-1">
      {inboxVariables.map(({ label, getValue }) => (
        <Tag
          asChild
          key={label}
          variant="quaternary"
          className="transition-colors hover:bg-black-200"
          onClick={() => insertText(`${getValue(participants[0])} `)}
        >
          <button type="button">{label}</button>
        </Tag>
      ))}
    </div>
  );

  const variablesComponent = (
    <div
      className={clsx([
        participants?.length <= 1 ? "justify-between" : "justify-end",
        "mt-2 flex flex-row items-end border-t border-black-200 pt-0.5 md:mt-4",
      ])}
    >
      {/* Clickable Tag Variables */}
      <RenderIf condition={participants?.length <= 1}>
        {isTabletOrDesktop ? desktopVariables : mobileVariables}
      </RenderIf>

      {/* Emoji Selector & Settings Popover */}
      <div className="flex flex-row items-center gap-0.5 md:pr-6">
        <EmojiSelector onEmojiSelection={(emoji) => insertText(emoji)} />

        <SettingsPopover
          ctrlToSend={ctrlToSend}
          setCtrlToSend={setCtrlToSend}
        />
      </div>
    </div>
  );

  const actionComponents = editMessage ? (
    <div className="flex flex-row items-center gap-2">
      {/* Cancel edit changes */}
      <Button
        variant="secondary-black"
        size="sm"
        intent="iconOnly"
        aria-label="Cancel edit"
        disabled={isUpdatingMessage}
        onClick={cancelEdit}
      >
        <X />
      </Button>

      {/* Confirm edit changes */}
      <Button
        variant="secondary-purple"
        size="sm"
        intent="iconOnly"
        aria-label="Confirm edit"
        disabled={isUpdatingMessage}
        isLoading={isUpdatingMessage}
        onClick={onConfirm}
      >
        <Check />
      </Button>
    </div>
  ) : (
    <Button
      size="sm"
      variant="secondary-purple"
      isLoading={isSendingMessage}
      disabled={isEmptyMessage || isMessagingDisabled}
      rightIcon={<PaperPlaneRight />}
      onClick={onSend}
    >
      Send
    </Button>
  );

  const { ref, ...registerRest } = register("message");

  return (
    <TextArea
      disabled={isMessagingDisabled}
      className="w-full md:mx-4 md:my-2 md:w-[calc(100%_-_32px)]"
      placeholder="Write a message here..."
      maxRows={6}
      onKeyDown={onKeyDown}
      rightComponent={actionComponents}
      bottomComponent={variablesComponent}
      ref={(el) => {
        ref(el);
        messageFieldRef.current = el;
      }}
      {...registerRest}
    />
  );
}
