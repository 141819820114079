import React, { Dispatch, SetStateAction, useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";

import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Campaign } from "common/types";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";
import useRelativeNavigate from "common/hooks/useRelativeNavigate";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import UpgradeDialog from "common/components/UpgradeDialog";

import useCampaignMutations from "../../datahooks/useCampaignMutations";
import { DetailedCampaign } from "../../types";
import { CampaignNameSchema } from "../../schemas";

interface CampaignActionsDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: "start" | "pause" | "delete" | "editName";
  campaign: DetailedCampaign | Campaign;
}

export default function CampaignActionsDialog({
  isOpen,
  setIsOpen,
  type,
  campaign,
}: CampaignActionsDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const navigate = useRelativeNavigate();
  const { id, name, state } = campaign;

  const selectedSubscription = useSelectedSubscriptionContext();
  const isCancelledSubscription = selectedSubscription.status === "cancelled";

  function openUpgradeDialog() {
    setIsOpen(false);
    setIsUpgradeDialogOpen(true);
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  const {
    deleteCampaign,
    isDeletingCampaign,
    updateCampaign,
    isUpdatingCampaign,
  } = useCampaignMutations();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { name },
    resolver: valibotResolver(CampaignNameSchema),
  });

  function removeCampaign() {
    deleteCampaign({ campaignId: id }).then(() => {
      navigate("campaigns");
      setIsOpen(false);
    });
  }

  function updateCampaignName({ name: updatedName }: { name: string }) {
    updateCampaign({ updates: { name: updatedName }, campaignId: id }).then(
      () => {
        setIsOpen(false);
        reset({ name: updatedName });
      },
    );
  }

  function updateCampaignState() {
    updateCampaign({
      campaignId: id,
      updates: { state: state === "ACTIVE" ? "PAUSED" : "ACTIVE" },
    }).then(() => setIsOpen(false));
  }

  let mainContent;
  if (type === "editName") {
    mainContent = (
      <>
        <h5 className="mb-4 text-headline-xl-regular md:text-center">
          Change campaign name
        </h5>
        <MaxLengthInput
          variant="lg"
          error={errors?.name?.message}
          characterLength={watch("name").length}
          maxLength={getSchemaMaxLength(CampaignNameSchema.entries.name)}
          {...register("name")}
        />
        <Button
          isLoading={isUpdatingCampaign}
          onClick={handleSubmit(updateCampaignName)}
          className="mb-3 mt-6"
        >
          Save
        </Button>
        <Button variant="quaternary-black" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </>
    );
  } else if (type === "start" && isCancelledSubscription) {
    mainContent = (
      <>
        <h5 className="mb-2 text-center text-headline-2xl-bold capitalize">
          Upgrade to LinkedIn Outreach
        </h5>
        <p className="mb-4 text-center text-black-500">
          Starting a campaign requires a LinkedIn Outreach subscription. Upgrade
          to start getting leads immediately
        </p>
        <Button variant="gradient" onClick={openUpgradeDialog}>
          Upgrade to Premium
        </Button>
      </>
    );
  } else {
    mainContent = (
      <>
        <h5 className="mb-2 text-center text-headline-2xl-bold capitalize">
          {type} Campaign
        </h5>
        <p className="mb-4 break-words text-center text-black-500">
          Are you sure you want to {type} campaign &quot;
          {name}
          &quot;?
        </p>
        <Button
          variant={type === "delete" ? "tertiary-danger" : "primary-black"}
          className="mb-3"
          onClick={type === "delete" ? removeCampaign : updateCampaignState}
          isLoading={isDeletingCampaign || isUpdatingCampaign}
        >
          Yes
        </Button>
        <Button
          variant={type === "delete" ? "quaternary-black" : "secondary-black"}
          onClick={() => setIsOpen(false)}
        >
          No
        </Button>
      </>
    );
  }

  return (
    <>
      <Component
        open={isOpen}
        onOpenChange={setIsOpen}
        onAfterClose={() => reset()}
      >
        <Content className="md:max-w-[400px]">{mainContent}</Content>
      </Component>
      <UpgradeDialog
        isOpen={isUpgradeDialogOpen}
        onOpenChange={setIsUpgradeDialogOpen}
      />
    </>
  );
}
