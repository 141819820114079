import React from "react";
import { useTranslation } from "react-i18next";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import useCampaigns from "common/datahooks/useCampaigns";
import useAppStore from "common/hooks/useAppStore";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import CreateNewCampaign from "common/components/CreateNewCampaign";
import { Button } from "common/components/ui/Button";
import TimeframeSelector from "common/components/TimeframeSelector";
import { formatDate } from "common/helpers/utils";
import MetricSelector from "common/components/MetricSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import RestrictedComponent from "common/components/RestrictedComponent";
import RecentLeads from "common/components/RecentLeads";

import { statsTimeframeOptions } from "../../campaigns/constants";

import NoActiveCampaignsImage from "assets/images/empty-placeholders/graph-no-active-campaigns.png";
import ConnectAccountImage from "assets/images/empty-placeholders/graph-connect-account.png";

function Home() {
  const { campaigns, isLoadingCampaigns } = useCampaigns();
  const { account } = useSelectedSubscriptionContext();
  const { t } = useTranslation("home");

  let emptyPlaceholderComponent;
  if (account.state === "LoggedOut" || account.state === "NeverLoggedIn") {
    emptyPlaceholderComponent = (
      <EmptyPlaceholder
        title={t("linkedinLoginTitle")}
        subtitle={t("linkedinLoginDescription")}
        imageSrc={ConnectAccountImage}
        actionComponent={
          <Button
            onClick={() => useAppStore.getState().setIsShowingLogin(true)}
          >
            {t("linkedinLoginCtaText")}
          </Button>
        }
      />
    );
  } else if (!isLoadingCampaigns && !campaigns?.length) {
    emptyPlaceholderComponent = (
      <EmptyPlaceholder
        title={t("emptyCampaignsTitle")}
        subtitle={t("emptyCampaignsDescription")}
        imageSrc={NoActiveCampaignsImage}
        actionComponent={
          <RestrictedComponent
            disabledForRoles={[]}
            hiddenForRoles={["member"]}
          >
            <CreateNewCampaign
              triggerButton={<Button>{t("emptyCampaignsCtaText")}</Button>}
            />
          </RestrictedComponent>
        }
      />
    );
  }

  const {
    selectedMetric,
    setSelectedMetric,
    selectedMetricParams,
    setSelectedMetricParams,
  } = useSelectedMetric();

  const {
    metrics,
    metricsSum,
    isLoadingMetrics,
    refetchMetrics,
    metricsError,
  } = useMetrics({
    parameters: selectedMetricParams,
  });

  const selectedTimeframeOption = statsTimeframeOptions.find(
    ({ parameters }) => parameters === selectedMetricParams,
  );
  return (
    <>
      <div className="mb-4 flex flex-col items-start justify-between gap-4 lg:mb-10 lg:flex-row">
        <div className="flex flex-col gap-y-1">
          <h2 className="text-headline-2xl-bold">Overview</h2>
          <p className="text-black-500">
            {selectedTimeframeOption
              ? selectedTimeframeOption.selectionLabel
              : `${formatDate(selectedMetricParams.timestamps.from)} - ${formatDate(selectedMetricParams.timestamps.to)} Statistics`}
          </p>
        </div>
        <TimeframeSelector
          selectedParams={selectedMetricParams}
          setSelectedParams={setSelectedMetricParams}
        />
      </div>
      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        isLoading={isLoadingMetrics}
      />
      <div className="relative flex h-[400px] md:h-[540px]">
        <StatisticsGraph
          selectedMetric={selectedMetric}
          metrics={metrics}
          metricsSum={metricsSum}
          refetchMetrics={refetchMetrics}
          requestError={metricsError}
          isLoading={isLoadingMetrics}
          isHourly={selectedMetricParams.bucketSize === "1 hour"}
          emptyPlaceholderComponent={emptyPlaceholderComponent}
        />
      </div>

      <RecentLeads showCampaignOrigin />
    </>
  );
}

export default Home;
