import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { get, post, del } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";
import { LinkedinProfile } from "common/types";

async function getBlacklist(accountId: number) {
  return (await get(`accounts/${accountId}/blacklist`)).targets;
}
async function addToBlacklistRequest(accountId: number, targetUrn: string) {
  await post(`accounts/${accountId}/blacklist/${targetUrn}`);
}
async function removeFromBlacklistRequest(
  accountId: number,
  targetUrn: string,
) {
  await del(`accounts/${accountId}/blacklist/${targetUrn}`);
}
export default function useBlacklist() {
  const { account } = useSelectedSubscriptionContext();

  const { blacklistKeys } = getQueryKeys(account);

  const {
    isLoading: isLoadingBlacklist,
    data: blacklist,
    error: blacklistError,
    refetch: refetchBlacklist,
  } = useQuery<LinkedinProfile[]>({
    queryKey: blacklistKeys.list(),
    queryFn: () => getBlacklist(account?.id),
  });
  const { mutateAsync: addToBlacklist, isPending: isAddingToBlacklist } =
    useMutation({
      mutationFn: ({ targetUrn }: { targetUrn: string }) =>
        addToBlacklistRequest(account?.id, targetUrn),
      onSuccess: () => {
        toast.success("Added to blacklist");
        refetchBlacklist();
      },
    });
  const { mutate: removeFromBlacklist } = useMutation({
    mutationFn: ({ targetUrn }: { targetUrn: string }) =>
      removeFromBlacklistRequest(account?.id, targetUrn),
    onSuccess: () => {
      toast.success("Removed from blacklist");
      refetchBlacklist();
    },
  });

  return {
    blacklist,
    refetchBlacklist,
    isLoadingBlacklist,
    blacklistError,
    addToBlacklist,
    isAddingToBlacklist,
    removeFromBlacklist,
  };
}
