import * as React from "react";
import { useIntercom } from "react-use-intercom";
import { SignOut, Lifebuoy, GearSix, X, Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { logout } from "common/helpers/auth";
import { NavItemType } from "common/components/Layout";
import { Sheet, SheetContent, SheetTrigger } from "common/components/ui/Sheet";
import { Button } from "common/components/ui/Button";
import { SheetClose } from "common/components/ui/Sheet/Sheet";
import AddSubscription from "common/components/Layout/AddSubscription";

import NavItem from "../NavItem";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";
import HamburgerIcon from "assets/icons/hamburger.svg?react";

export default function MobileNav({ navItems }: { navItems: NavItemType[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddSubscriptionOpen, setIsAddSubscriptionOpen] = useState(false);
  const location = useLocation();
  const { show } = useIntercom();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <Button size="lg" variant="tertiary-black" intent="iconOnly">
            <HamburgerIcon />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="right"
          className="flex w-80 flex-col rounded-tl-3xl bg-black-950 px-4 py-8"
        >
          <div className="mb-6 flex justify-between">
            <div className="flex items-end">
              <AimfoxLogoOrange className="w-8" />
              <span className="mb-0.5 text-headline-lg-bold text-white">
                Aimfox
              </span>
            </div>
            <SheetClose>
              <X className="fill-white" size={24} />
            </SheetClose>
          </div>
          <div className="overflow-y-auto scrollbar-thin">
            <div className="flex flex-col gap-y-1 pb-4">
              {navItems.map((navItem) => (
                <NavItem key={navItem.path} {...navItem} />
              ))}
              <NavItem
                label="Support"
                renderIcon={(props) => <Lifebuoy {...props} />}
                onClick={show}
              />
            </div>
            <div className="mt-auto flex flex-col gap-y-1 border-t border-black-800 pt-4">
              <NavItem
                label="Account Settings"
                renderIcon={(props) => <GearSix {...props} weight="regular" />}
                path="account/settings"
              />
              <NavItem
                label="Add Subscription"
                renderIcon={(props) => <Plus {...props} weight="regular" />}
                onClick={() => {
                  setIsOpen(false);
                  setIsAddSubscriptionOpen(true);
                }}
              />
              <NavItem
                label="Logout"
                renderIcon={(props) => <SignOut {...props} />}
                onClick={logout}
              />
            </div>
          </div>
        </SheetContent>
      </Sheet>
      <AddSubscription
        isOpen={isAddSubscriptionOpen}
        onClose={() => setIsAddSubscriptionOpen(false)}
      />
    </>
  );
}
