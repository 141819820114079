import React, { useRef } from "react";
import { Bell } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import BadgeWrapper from "common/components/Layout/BadgeWrapper";

import useNotifications from "../../../../datahooks/useNotifications";
import NotificationItem from "./NotificationItem";

export default function NotificationsPopup() {
  const buttonRef = useRef<HTMLButtonElement>();
  const { notifications, unseenCount } = useNotifications();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="lg"
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="notification"
          className="group"
          ref={buttonRef}
        >
          <BadgeWrapper
            badgeClassName={clsx(
              "right-0 top-0 size-2 border-white group-hover:border-black-50",
              !unseenCount && "hidden",
            )}
          >
            <Bell weight="fill" />
          </BadgeWrapper>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-[300px] flex-col p-2" align="end">
        {notifications.map((notification) => (
          <>
            <NotificationItem key={notification.id} {...notification} />
            <div className="my-0.5 h-px bg-black-100" />
          </>
        ))}
        <Link
          to="account/notifications"
          className="px-4 py-1.5 text-center text-button-12 text-purple-600"
        >
          Show all
        </Link>
      </PopoverContent>
    </Popover>
  );
}
