import { produce } from "immer";
import { useState } from "react";

import { CampaignSubtype } from "common/types";

export default function useCampaignSettingsState() {
  const [settings, setSettings] = useState({
    subtype: "connect" as CampaignSubtype,
    connect: true,
    message: false,
    optimizations: {
      connect: false, // Hide Connect Optimization https://linear.app/constel/issue/ENG-785/hide-connect-optimization
      inmail: true,
    },
  });

  let primaryMethod: "connect" | "message" | "off";

  if (settings.connect) {
    primaryMethod = "connect";
  } else if (settings.message) {
    primaryMethod = "message";
  } else {
    primaryMethod = "off";
  }

  function setPrimaryMethod(newMethod: "connect" | "message" | "off") {
    setSettings(
      produce((draft) => {
        if (newMethod === "off") {
          draft.connect = false;
          draft.message = false;
          draft.subtype = "connect"; // Default subtype
        } else {
          draft.connect = newMethod === "connect";
          draft.message = newMethod === "message";
          draft.subtype = newMethod;
          draft.optimizations.inmail = true;
          // draft.optimizations.connect = true; // Hide Connect Optimization https://linear.app/constel/issue/ENG-785/hide-connect-optimization
        }
      }),
    );
  }

  function onInmailToggle() {
    setSettings(
      produce((draft) => {
        const {
          optimizations: { connect: isConnectEnabled, inmail: isInmailEnabled },
        } = draft;

        if (!isConnectEnabled && isInmailEnabled) {
          draft.optimizations.inmail = false;
          draft.optimizations.connect = true;
        } else {
          draft.optimizations.inmail = !isInmailEnabled;
        }
      }),
    );
  }

  function onConnectToggle() {
    setSettings(
      produce((draft) => {
        const {
          optimizations: { connect: isConnectEnabled, inmail: isInmailEnabled },
        } = draft;

        if (!isInmailEnabled && isConnectEnabled) {
          draft.optimizations.connect = false;
          draft.optimizations.inmail = true;
        } else {
          draft.optimizations.connect = !isConnectEnabled;
        }
      }),
    );
  }

  return {
    settings,
    primaryMethod,
    setPrimaryMethod,
    onInmailToggle,
    onConnectToggle,
  };
}
