import React, { ReactNode, JSX } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

import useCurrentBaseRoute from "common/hooks/useCurrentBaseRoute";
import CrashPage from "common/components/CrashPage";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { NavItemType } from "common/components/Layout";

import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";

export default function Layout({
  children,
  navItems,
  headerTitle,
  bannerComponent = null,
}: {
  children: ReactNode;
  navItems: NavItemType[];
  headerTitle: string;
  bannerComponent?: JSX.Element;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const baseRoute = useCurrentBaseRoute();
  const navigate = useNavigate();
  const isAccountRoute = baseRoute.path.includes("account");

  function goBack() {
    navigate("home");
  }

  const Component = isDesktop ? DesktopLayout : MobileLayout;

  return (
    <Component
      navItems={navItems}
      goBack={isAccountRoute && goBack}
      headerTitle={headerTitle}
      bannerComponent={bannerComponent}
    >
      <ErrorBoundary
        /* eslint-disable-next-line react/no-unstable-nested-components */
        fallback={({ error, resetError, eventId, componentStack }) => (
          <CrashPage
            error={error}
            resetError={resetError}
            eventId={eventId}
            componentStack={componentStack}
          />
        )}
      >
        {children}
      </ErrorBoundary>
    </Component>
  );
}
