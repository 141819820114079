import React from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import useCustomer from "common/datahooks/useCustomer";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";

import Subscriptions from "./Subscriptions/Subscriptions";
import Account from "./Account/Account";

export default function AccountSettings() {
  const { isLoadingCustomer } = useCustomer();

  const tabs = [
    {
      id: "account",
      label: "Account",
      component: <Account />,
    },
    {
      id: "workspaces",
      label: "Workspaces",
      component: <Subscriptions />,
    },
  ];

  // Initialize active tab based on queryParams
  const [activeTab, setActiveTab] = useQueryParam(
    "activeTab",
    withDefault(StringParam, tabs[0].id),
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );

  if (isLoadingCustomer) {
    return <span className="text-black-600">Loading...</span>;
  }

  return (
    <Tabs
      className="mt-5 lg:mt-0"
      defaultValue={activeTab}
      onValueChange={(selectedTab) => setActiveTab(selectedTab)}
    >
      <TabsList
        className="justify-start gap-2 pb-4"
        size="md"
        variant="default"
      >
        {tabs.map(({ label, id }) => (
          <TabsTrigger key={id} value={id}>
            {label}
          </TabsTrigger>
        ))}
      </TabsList>

      {tabs.map(({ id, component }) => (
        <TabsContent key={id} value={id}>
          {component}
        </TabsContent>
      ))}
    </Tabs>
  );
}
