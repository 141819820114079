import React, { useState } from "react";
import { CaretRight } from "@phosphor-icons/react";

import { getMonthName } from "common/helpers/utils";
import { PaymentSource, Subscription } from "common/types";
import useSubscriptionSelection from "common/hooks/useSubscriptionSelection";
import { Button } from "common/components/ui/Button";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import AccountPreview from "./AccountPreview";
import PaymentMethodLabel from "./PaymentMethodLabel";

type SubscriptionDetails = Subscription & {
  paymentSource: PaymentSource;
};

interface MobileSubscriptionsProps {
  subscriptions: SubscriptionDetails[];
  isLoading: boolean;
}

export default function MobileSubscriptions({
  subscriptions,
  isLoading,
}: MobileSubscriptionsProps) {
  const { selectSubscription } = useSubscriptionSelection();
  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionDetails>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleRowClick = (subscription: SubscriptionDetails) => {
    setSelectedSubscription(subscription);
    setIsDrawerOpen(true);
  };

  const handleDrawerState = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const handleSelectSubscription = () => {
    selectSubscription(selectedSubscription.id);
  };

  const getNextBillingDate = (date: number) => new Date(date);

  const accountInfo = selectedSubscription && [
    {
      label: "Members",
      value: selectedSubscription.member_count,
    },
    {
      label: "Plan",
      value: selectedSubscription.plan?.name.split("-").join(" "),
    },
    {
      label: "Billing method",
      value: (
        <PaymentMethodLabel
          isCompact
          paymentSource={selectedSubscription.paymentSource}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: "Next billing",
      value: `${getNextBillingDate(selectedSubscription.next_billing).getDate()} - ${getMonthName(
        getNextBillingDate(selectedSubscription.next_billing).getMonth(),
      )} ($${selectedSubscription.plan.pricing.price / 100})`,
    },
  ];

  return (
    <>
      <div className="rounded-xl bg-black-100 px-4 py-2">
        <span className="text-caption-12-regular">Account</span>
      </div>

      <div className="mt-2 flex flex-col gap-2">
        {subscriptions.map((subscription) => (
          <button
            type="button"
            onClick={() => handleRowClick(subscription)}
            className="border-none"
            key={subscription.id}
            aria-label={`${subscription.account} button`}
          >
            <div className="flex items-center justify-between gap-4 rounded-2xl border border-black-200 px-4 py-5">
              <AccountPreview
                account={subscription.account}
                role={subscription.role}
              />

              <Button asChild intent="iconOnly" variant="quaternary-black">
                <span>
                  <CaretRight />
                </span>
              </Button>
            </div>
          </button>
        ))}
      </div>

      <Drawer
        open={isDrawerOpen}
        onOpenChange={(isOpen) => {
          handleDrawerState(isOpen);
        }}
      >
        <DrawerContent>
          <AccountPreview
            account={selectedSubscription?.account}
            role={selectedSubscription?.role}
          />

          <div className="my-4 h-px bg-black-200" />

          <div className="flex flex-col">
            {accountInfo?.map(({ label, value }) => (
              <div key={label}>
                <div className="flex flex-row">
                  <span className="w-1/2 capitalize text-black-700">
                    {label}
                  </span>
                  <div className="flex w-1/2 flex-row items-center text-body-14-bold capitalize">
                    {value}
                  </div>
                </div>
                <div className="my-4 h-px bg-black-200" />
              </div>
            ))}
          </div>

          <div className="mt-10 flex flex-col gap-2">
            <Button size="lg" onClick={handleSelectSubscription}>
              Go to workspace
            </Button>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}
