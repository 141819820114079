import React from "react";
import { Envelope, LinkedinLogo } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import { RecentLead } from "common/types";
import useRelativeNavigate from "common/hooks/useRelativeNavigate";

interface RecentLeadsItemProps {
  lead: RecentLead;
  showFlowOrigin: boolean;
  showCampaignOrigin: boolean;
}

export default function RecentLeadsItem({
  lead,
  showFlowOrigin,
  showCampaignOrigin,
}: RecentLeadsItemProps) {
  const navigate = useRelativeNavigate();

  const { campaign_name: campaignName, target, flow_name: flowName } = lead;

  const {
    picture_url: pictureUrl,
    full_name: fullName,
    public_identifier: publicId,
    occupation,
  } = target;

  function navigateToInbox(): void {
    navigate("inbox", {
      state: {
        lead: {
          ...target,
          labels: [],
          notes: [],
        },
      },
    });
  }

  return (
    <div className="flex flex-row justify-between gap-4 rounded-2xl border border-black-200 px-3 py-4">
      <div className="flex flex-row items-center gap-2">
        <ProfileImage src={pictureUrl} size="lg" />

        <div className="flex flex-col items-start">
          <span className="line-clamp-1 text-button-16">{fullName}</span>

          <p className="line-clamp-1 break-all text-start text-caption-12-regular text-black-500 md:max-w-[30ch]">
            {showFlowOrigin || showCampaignOrigin ? (
              <>
                Origin:{" "}
                <span className="font-semibold">
                  {showFlowOrigin ? flowName : campaignName}
                </span>
              </>
            ) : (
              occupation
            )}
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center gap-1">
        <div className="flex flex-row">
          <Button
            variant="quaternary-black"
            intent="iconOnly"
            aria-label="Go to Inbox"
            onClick={navigateToInbox}
          >
            <Envelope weight="fill" className="cursor-pointer text-black-500" />
          </Button>

          <Button
            variant="quaternary-black"
            intent="iconOnly"
            aria-label="Go to LinkedIn profile"
            asChild
          >
            <a
              target="_blank"
              href={`https://linkedin.com/in/${publicId}`}
              rel="noreferrer"
              aria-label="LinkedIn profile URL"
            >
              <LinkedinLogo className="cursor-pointer" weight="fill" />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
