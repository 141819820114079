import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import Hotjar from "@hotjar/browser";

import useGTM from "common/hooks/useGTM";
import SubscriptionValidator from "common/helpers/SubscriptionValidator";
import { isProductionEnvironment } from "common/helpers/utils";
import checkAuth from "common/helpers/checkAuth";
import { OnboardingContainer } from "common/components/Layout";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useSubscriptions from "./common/datahooks/useSubscriptions";
import useCustomer from "./common/datahooks/useCustomer";
import useCustomerIO from "./common/hooks/useCustomerIO";
import CrashPage from "./common/components/CrashPage";
import routes from "./pages/routes";

function App() {
  const isDesktop = useTwBreakpoint("lg");
  const signupRef = useRef(false);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const { customerInfo } = useCustomer();
  const { subscriptions } = useSubscriptions();
  const { initializeCustomerIO } = useCustomerIO();
  const { sendEvent } = useGTM();
  const { boot } = useIntercom();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (customerInfo && isAuthLoaded) {
      boot({
        email: customerInfo.email,
        name: `${customerInfo.first_name} ${customerInfo.last_name}`,
        phone: customerInfo.phone,
        hideDefaultLauncher:
          !isDesktop || import.meta.env.MODE === "development",
      });
      initializeCustomerIO(customerInfo.chargebee_id, customerInfo.email);
      if (isProductionEnvironment()) {
        Sentry.setTag("keycloak id", customerInfo.id);
        Sentry.setUser({
          id: customerInfo.id,
          email: customerInfo.email,
          first_name: customerInfo.first_name,
          last_name: customerInfo.last_name,
        });
        Hotjar.identify(customerInfo.id, {
          email: customerInfo.email,
          name: `${customerInfo.first_name} ${customerInfo.last_name}`,
        });
      }
    }
  }, [isAuthLoaded, customerInfo]);

  useEffect(() => {
    if (searchParams.get("signup")) {
      signupRef.current = true;
    }
    checkAuth().then(() => {
      setIsAuthLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (customerInfo && signupRef.current) {
      sendEvent("signed_up", ({ email }) => ({ email }));
    }
  }, [customerInfo]);

  useEffect(() => {
    if (subscriptions && customerInfo) {
      document.getElementById("loader-container")?.remove();
    }
  }, [subscriptions, customerInfo]);

  if (!subscriptions || !customerInfo) {
    return null;
  }

  return (
    <ErrorBoundary
      /* eslint-disable-next-line react/no-unstable-nested-components */
      fallback={({ error, resetError, eventId, componentStack }) => (
        <CrashPage
          error={error}
          resetError={resetError}
          eventId={eventId}
          componentStack={componentStack}
        />
      )}
    >
      <Routes>
        {subscriptions.length ? (
          <>
            <Route path="/:subscriptionId" element={<SubscriptionValidator />}>
              {routes.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}
            </Route>
            <Route
              path="/"
              element={
                <Navigate
                  to={`/${subscriptions[0].id}?${searchParams.toString()}`}
                  replace
                />
              }
            />
          </>
        ) : (
          <>
            <Route path="/home" element={<OnboardingContainer />} />
            <Route
              path="*"
              element={
                <Navigate to={`home?${searchParams.toString()}`} replace />
              }
            />
          </>
        )}
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
