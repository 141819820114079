import React from "react";
import { PlusCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import useCampaigns from "common/datahooks/useCampaigns";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import CreateNewCampaign from "common/components/CreateNewCampaign";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";

import useCampaignsTable from "./useCampaignsTable";
import StateFilter from "./StateFilter";
import CampaignsTable from "./CampaignsTable";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";
import EmptyPlaceholderImage from "assets/images/empty-placeholders/alert.png";

export default function CampaignsList() {
  const { t } = useTranslation("common");

  const { campaigns, isLoadingCampaigns, campaignsError, refetchCampaigns } =
    useCampaigns();

  const {
    filteredCampaigns,
    hasFilters,
    searchText,
    setSearchText,
    campaignState,
    setCampaignState,
    clearFilters,
  } = useCampaignsTable(campaigns);

  const currentPageCampaigns = filteredCampaigns.length
    ? filteredCampaigns
    : new Array(7).fill({});

  let content = (
    <CampaignsTable
      isLoading={isLoadingCampaigns}
      campaigns={currentPageCampaigns}
    />
  );

  if (campaignsError) {
    content = (
      <ErrorPlaceholder
        errorMessage="Could not display Campaigns"
        onRetry={refetchCampaigns}
      />
    );
  } else if (!isLoadingCampaigns && !campaigns.length) {
    content = (
      <EmptyPlaceholder
        title={t("emptyCampaignsTitle")}
        subtitle={t("emptyCampaignsSubtitle")}
        imageSrc={EmptyPlaceholderImage}
        actionComponent={
          <RestrictedComponent
            disabledForRoles={["member"]}
            hiddenForRoles={[]}
          >
            {(isDisabled) => (
              <CreateNewCampaign
                triggerButton={
                  <Button disabled={isDisabled}>Create a new campaign</Button>
                }
              />
            )}
          </RestrictedComponent>
        }
      />
    );
  } else if (hasFilters && !filteredCampaigns.length) {
    content = (
      <EmptyPlaceholder
        title="No results found"
        subtitle="There were no results that match your criteria"
        imageSrc={NoResultsImage}
        actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
      />
    );
  }

  return (
    <>
      {/* Top Section */}
      <div className="mb-6 flex flex-col-reverse items-center justify-between md:flex-row">
        <div className="flex w-full items-center gap-4">
          <SearchInput
            className="max-md:flex-1 md:w-80"
            placeholder="Search campaign"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onClear={() => setSearchText("")}
          />
          <StateFilter
            selectedFilter={campaignState}
            setFilter={setCampaignState}
            campaigns={campaigns}
          />
        </div>
        <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
          {(isDisabled) => (
            <CreateNewCampaign
              triggerButton={
                <Button
                  leftIcon={<PlusCircle weight="fill" />}
                  className="max-md:mb-4 max-md:w-full"
                  disabled={isDisabled}
                >
                  Create Campaign
                </Button>
              }
            />
          )}
        </RestrictedComponent>
      </div>

      {content}
    </>
  );
}
