import React from "react";
import { Routes, Route } from "react-router-dom";

import Campaign from "./Campaign";
import CampaignsList from "./CampaignsList";

export default function Campaigns() {
  return (
    <Routes>
      <Route path="/" element={<CampaignsList />} />
      <Route path="/:campaignId/*" element={<Campaign />} />
    </Routes>
  );
}
