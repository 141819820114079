import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import debounce from "debounce";
import { StringParam, useQueryParam } from "use-query-params";

import { Lead } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";

import useConversations from "../datahooks/useConversations";
import MobileInbox from "./MobileInbox";
import DesktopInbox from "./DesktopInbox";
import useUnreadMessagesCount from "../../../common/datahooks/useUnreadMessagesCount";
import useLeadConversationUrn from "../datahooks/useLeadConversationUrn";
import useLead from "../../leads/datahooks/useLead";
import { Conversation } from "../types";

export default function Inbox() {
  const isDesktop = useTwBreakpoint("lg");
  const { state }: { state: { lead?: Lead } } = useLocation();
  const [activeConversationUrn, setActiveConversationUrn] = useQueryParam(
    "activeConversation",
    StringParam,
  );
  const [keywordsInputValue, setKeywordsInputValue] = useState("");
  const [keywords, setKeywords] = useState("");
  const { unreadMessagesCount, versionTag, resetUnreadMessages } =
    useUnreadMessagesCount();
  const {
    conversations,
    isLoadingConversations,
    loadNextConversations,
    hasMoreConversations,
    conversationsError,
    refetchConversations,
  } = useConversations({
    keywords,
    versionTag,
  });

  // Temporary fix, TODO: refactor state logic later.
  const [isStateLeadEnabled, setIsStateLeadEnabled] = useState(true);

  const leadConversationUrn = useLeadConversationUrn({
    leadId: state?.lead.id,
  });

  const { lead } = useLead({ profileId: state?.lead.id });

  function clearKeywords(): void {
    setKeywords("");
    setKeywordsInputValue("");
  }

  const onInputValueChange = useCallback(debounce(setKeywords, 800), []);

  useEffect(() => {
    onInputValueChange(keywordsInputValue);
  }, [keywordsInputValue]);

  useEffect(() => {
    if (unreadMessagesCount) {
      resetUnreadMessages({ versionTag });
    }
  }, [unreadMessagesCount]);

  const activeConversation: Conversation = useMemo(() => {
    if (conversations && activeConversationUrn) {
      return conversations.find(
        ({ conversation_urn }) => conversation_urn === activeConversationUrn,
      );
    }
    if (isStateLeadEnabled && state?.lead) {
      return {
        conversation_urn: leadConversationUrn,
        participants: [lead || { ...state.lead, is_lead: true }],
        unread_count: 0,
        last_activity_at: null,
        last_message: null,
      };
    }
  }, [conversations, lead, leadConversationUrn, activeConversationUrn]);

  if (conversationsError)
    return (
      <ErrorPlaceholder
        onRetry={refetchConversations}
        errorMessage="Could not display Conversations"
      />
    );

  const Component = isDesktop ? DesktopInbox : MobileInbox;
  return (
    <Component
      activeConversation={activeConversation}
      activeConversationUrn={activeConversationUrn}
      setActiveConversationUrn={(urn) => {
        setActiveConversationUrn(urn);
        setIsStateLeadEnabled(false);
      }}
      conversations={conversations}
      loadMoreConversations={loadNextConversations}
      keywordsInputValue={keywordsInputValue}
      setKeywordsInputValue={setKeywordsInputValue}
      keywords={keywords}
      clearKeywords={clearKeywords}
      isLoadingConversations={isLoadingConversations}
      hasMoreConversations={hasMoreConversations}
    />
  );
}
