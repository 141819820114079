import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { SearchSuggestion } from "common/types";

async function getSearchSuggestionsRequest(): Promise<SearchSuggestion[]> {
  return (await get("search/suggestions")).suggestions;
}

export default function useSearchSuggestions() {
  const { data: searchSuggestions, isLoading: isLoadingSearchSuggestions } =
    useQuery({
      queryKey: ["searchSuggestions"],
      queryFn: getSearchSuggestionsRequest,
    });

  return { searchSuggestions, isLoadingSearchSuggestions };
}
