import React from "react";

import { LinkedinProfile } from "common/types";
import { Button } from "common/components/ui/Button";
import ProfileImage from "common/components/ui/ProfileImage";

export default function LinkedInAccountCard({
  account,
  changeAccount = null,
}: {
  account: LinkedinProfile;
  changeAccount?: () => void;
}) {
  const { picture_url: pictureUrl, full_name: fullName, occupation } = account;
  return (
    <div className="relative flex flex-col rounded-2xl border border-black-200 p-4">
      <div className="absolute inset-x-0 top-0 -z-10 h-20 rounded-t-2xl bg-black-300" />
      <ProfileImage src={pictureUrl} className="mb-3 mt-10" size="lg" />
      <div className="flex items-end justify-between">
        <div className="flex flex-col gap-y-0.5">
          <span className="text-button-16">{fullName}</span>
          <span className="line-clamp-2 text-caption-12-regular text-black-500">
            {occupation}
          </span>
        </div>
        {changeAccount && (
          <Button variant="tertiary-purple" size="sm" onClick={changeAccount}>
            Change profile
          </Button>
        )}
      </div>
    </div>
  );
}
