import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Copy } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

import AimfoxLogo from "assets/aimfoxLogos/aimfox-logo-text-orange-black.svg?react";

export default function CrashPage({
  error,
  resetError,
  componentStack,
  eventId,
}: {
  error: Error;
  resetError: () => void;
  componentStack: string;
  eventId: string;
}) {
  const [isErrorCopied, setIsErrorCopied] = useState(false);
  const location = useLocation();
  const errorLocationRef = useRef(location.pathname);

  const displayedError = `${error.stack} ${componentStack}`;

  function onCopyError() {
    navigator.clipboard.writeText(displayedError).then(() => {
      setIsErrorCopied(true);
      setTimeout(() => {
        setIsErrorCopied(false);
      }, 6000);
    });
  }

  useEffect(() => {
    if (location.pathname !== errorLocationRef.current) {
      resetError();
    }
  }, [location]);

  return (
    <div className="flex grow flex-col items-center justify-center px-4 lg:px-32">
      <AimfoxLogo className="mb-8 h-8" />

      <p className="mb-4 text-center text-headline-2xl-bold">
        Something went wrong, please try again later
      </p>

      <div className="mb-6 max-h-60 max-w-full overflow-y-auto rounded-lg bg-red-50 p-4 text-headline-lg-regular text-red-500">
        {displayedError}
      </div>

      <Button
        leftIcon={!isErrorCopied && <Copy />}
        onClick={onCopyError}
        variant="secondary-black"
        size="lg"
      >
        {isErrorCopied ? "Error text copied!" : "Copy error text"}
      </Button>

      <span className="my-4 text-center text-headline-lg-regular">
        Event ID: {eventId}
      </span>

      <Button size="lg" onClick={resetError}>
        Reload Page
      </Button>
    </div>
  );
}
