import React, { useState } from "react";
import { CaretDown, DotOutline } from "@phosphor-icons/react";
import clsx from "clsx";

import { typedObjectEntries } from "common/helpers/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import RenderIf from "common/components/RenderIf";
import { Campaign } from "common/types";

import { campaignStates } from "../../constants";

interface FilterItemProps {
  onSelect: () => void;
  label: string;
  campaignsCount: number;
  iconColor?: string;
  isSelected?: boolean;
}

function FilterItem({
  onSelect,
  label,
  campaignsCount,
  iconColor = null,
  isSelected = false,
}: FilterItemProps) {
  return (
    <button
      type="button"
      className={clsx(
        "flex w-full px-4 py-1.5 text-button-14 outline-none transition-colors hover:bg-black-100",
        isSelected && "bg-black-50",
      )}
      onClick={onSelect}
    >
      <RenderIf condition={!!iconColor}>
        <DotOutline
          size={20}
          weight="fill"
          className={clsx("-ml-3 mr-2", iconColor)}
        />
      </RenderIf>
      {label}
      <span className="text-black-500">&nbsp;({campaignsCount})</span>
    </button>
  );
}

interface StateFilterProps {
  selectedFilter: keyof typeof campaignStates;
  setFilter: (filter: keyof typeof campaignStates) => void;
  campaigns: Campaign[];
}

export default function StateFilter({
  selectedFilter,
  setFilter,
  campaigns,
}: StateFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { label: stateLabel, iconColor: stateIconColor } =
    campaignStates[selectedFilter] ?? {};

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger className="group inline-flex h-10 w-40 items-center justify-between rounded-full border border-black-200 px-6 text-button-14 outline-none transition-colors data-[state=open]:bg-black-50">
        {stateLabel ? (
          <span className="inline-flex items-center gap-1">
            <DotOutline
              size={20}
              weight="fill"
              className={clsx("-ml-3", stateIconColor)}
            />
            {stateLabel}
          </span>
        ) : (
          "Filter by"
        )}
        <CaretDown
          size={16}
          className="-mr-3 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent
        className="flex w-44 flex-col items-start gap-1 py-2"
        align="end"
      >
        <FilterItem
          onSelect={() => {
            setFilter(null);
            setIsOpen(false);
          }}
          label="All"
          campaignsCount={campaigns ? campaigns.length : 0}
        />
        {typedObjectEntries(campaignStates).map(
          ([key, { label, iconColor }]) => (
            <FilterItem
              key={key}
              isSelected={key === selectedFilter}
              onSelect={() => {
                setFilter(key);
                setIsOpen(false);
              }}
              label={label}
              iconColor={iconColor}
              campaignsCount={
                campaigns
                  ? campaigns.filter(({ state }) => state === key).length
                  : 0
              }
            />
          ),
        )}
      </PopoverContent>
    </Popover>
  );
}
