import React from "react";
import { Info, type Icon } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface ItemContentProps {
  icon: Icon;
  title: string;
  description: string;
  infoText: string;
  shouldDisplayTag?: boolean;
}

export default function ItemContent({
  icon: Icon,
  title,
  description,
  infoText,
  shouldDisplayTag = false,
}: ItemContentProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  return (
    <div className="flex gap-4">
      <Button variant="secondary-black" size="lg" intent="labelIcon">
        <Icon />
      </Button>
      <div className="flex flex-col gap-0.5">
        <div className="flex gap-1">
          <h3 className="text-button-16">{title}</h3>
          <RenderIf condition={shouldDisplayTag}>
            <Tag variant="success">Active</Tag>
          </RenderIf>
          <RenderIf condition={isTabletOrDesktop}>
            <Tooltip>
              <TooltipTrigger>
                <Info size={18} weight="fill" className="fill-black-300" />
              </TooltipTrigger>
              <TooltipContent>{infoText}</TooltipContent>
            </Tooltip>
          </RenderIf>
        </div>
        <p className="text-caption-12-regular text-black-500">{description}</p>
      </div>
    </div>
  );
}
